import {
  Alert,
  AlertTitle,
  Button,
  Card,
  FormControl,
  Input,
  InputLabel,
  Paper,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import React from "react";
import { emailCarrier } from "../services/emailService";

export default function EmailCarrier({
  dispatcherEmail,
  row,
  reason,
  setNoteDisplay,
  setContactDisplay,
}: any) {
  const [emailAddress, setEmailAddress] = React.useState(dispatcherEmail);
  const [emailSubject, setEmailSubject] = React.useState(
    `Order # ${row.orderNumber}`
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [anchorAlert, setAlert] = React.useState(false);
  const [successAlert, setSuccessAlert] = React.useState(false);
  const [addImageButton, setAddImageButton] = React.useState(true);
  const [imageUrl, setImageUrl] = React.useState(row.image);
  const [emailMessage, setEmailMessage] = React.useState(
    `Dear Carrier, payment for this shipment has been placed on hold for the following reason: ${reason}`
  );
  //console.log(row);
  const handleChangeEmailAddress = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailAddress(event.target.value);
  };

  const handleChangeEmailMessage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailMessage(event.target.value);
  };

  const handleChangeEmailSubject = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailSubject(event.target.value);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddImageUrl = () => {
    setAddImageButton(!addImageButton);
    addImageButton
      ? doesImageExist()
      : setEmailMessage(
          `Dear Carrier, payment for this shipment has been placed on hold for the following reason: ${reason}`
        );
  };

  // React.useEffect(() => {
  //   row.orders[0].files.length > 0
  //     ? setImageUrl(row.orders[0].files[0].file)
  //     : setImageUrl("No Image On File");
  // }, []);

  const doesImageExist = () => {
    if (imageUrl > 0) {
      setEmailMessage(`Dear Carrier, payment for this shipment has been placed on hold for the following reason: ${reason}.
           Please see Image Link: ${imageUrl}`);
    } else {
      setEmailMessage(`Dear Carrier, payment for this shipment has been placed on hold for the following reason: ${reason}.
            ${imageUrl}`);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const openAlert = Boolean(anchorAlert);
  const alertId = openAlert ? "simple-popover" : undefined;

  function handleSendEmail() {

    emailCarrier(
      emailAddress,
      emailSubject,
      emailMessage,
      row.id,
      (data: any) => {
        setAlert(true);
        setSuccessAlert(true);
        setTimeout(() => setAlert(false), 3000);
        console.log(data);
      },
      (err: any) => {
        setAlert(true);
        setSuccessAlert(false);
        setTimeout(() => setAlert(false), 3000);
        console.log(err);
      }
    );

    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        anchorReference={"none"}
      >
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          Email successfully sent
        </Alert>
      </Popover>
    );
  }

  return (
    <>
      <Button aria-describedby={id} onClick={handleClick}>
        Email Carrier
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <FormControl variant="standard" sx={{ margin: 1, width: "300px" }}>
          <InputLabel>To Email Address</InputLabel>
          <Input
            id="component-simple"
            value={emailAddress}
            onChange={handleChangeEmailAddress}
          />
        </FormControl>
        <FormControl variant="standard" sx={{ margin: 1, width: "300px" }}>
          <InputLabel htmlFor="component-simple">Email Subject</InputLabel>
          <Input
            id="component-simple"
            value={emailSubject}
            onChange={handleChangeEmailSubject}
          />
        </FormControl>
        <Card sx={{ py: 1, height: 1, boxSizing: "border-box", width: "100%" }}>
          <TextField
            id="outlined-multiline-static"
            label="Email Content"
            multiline
            rows={10}
            value={emailMessage}
            onChange={handleChangeEmailMessage}
            sx={{ margin: 1, width: "97.5%" }}
          />
          <Typography>
            <Button
              aria-describedby={id}
              variant="contained"
              sx={{ margin: 1 }}
              onClick={() => {
                handleSendEmail();
                handleClose();
              }}
            >
              Send Email
            </Button>
            {addImageButton ? (
              <Button
                aria-describedby={id}
                variant="contained"
                sx={{ margin: 1 }}
                onClick={handleAddImageUrl}
              >
                Add Image URL
              </Button>
            ) : (
              <Button
                aria-describedby={id}
                variant="contained"
                sx={{ margin: 1 }}
                onClick={handleAddImageUrl}
              >
                Remove Image URL
              </Button>
            )}
            <Button
              aria-describedby={id}
              variant="contained"
              sx={{ margin: 1 }}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
          </Typography>
        </Card>
      </Popover>
      {successAlert ? (
        <Popover
          id={alertId}
          open={openAlert}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          anchorReference={"none"}
        >
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            Email successfully sent
          </Alert>
        </Popover>
      ) : (
        <Popover
          id={alertId}
          open={openAlert}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          anchorReference={"none"}
        >
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <strong>Email Failed to Send!</strong>
          </Alert>
        </Popover>
      )}
    </>
  );
}
