import React, { useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  Input,
  InputLabel,
  Paper,
  Popover,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import { UserContext } from "../../contexts/UserContext";
import {
  adminAddUser,
  getAvailableUserSettings,
} from "../../services/adminService";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { PermissionsContext } from "../../contexts/PermissionsContext";
import PagesSelector from "./selectPages";
import ReportsSelector from "./selectReports";

interface Props {
  email: any;
  setEmail: any;
  role: any;
  setRole: any;
  manager: any;
  setManager: any;
  firstName: any;
  lastName: any;
  setFirstName: any;
  setLastName: any;
}
interface Role {
  id: string;
  name: string;
}
interface Pages {
  id: string;
  value: string;
}
interface Reports {
  id: string;
  value: string;
}
export default function AddUserForm(props: Props) {
  const {
    email,
    setEmail,
    role,
    setRole,
    manager,
    setManager,
    firstName,
    lastName,
    setFirstName,
    setLastName,
  } = props;
  const { user } = React.useContext(UserContext);
  const { permissions } = React.useContext(PermissionsContext);
  const [successAlert, setSuccessAlert] = React.useState(false);
  const [anchorAlert, setAlert] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [roles, setRoles] = React.useState<Array<Role>>([]);
  const [selectRoles, setSelectRoles] = React.useState("");
  const [managers, setManagers] = React.useState([]);
  const [selectManagers, setSelectManagers] = React.useState("");
  const [pages, setPages] = React.useState<Array<Pages>>([]);
  const [selectPages, setSelectPages] = React.useState([]);
  const [reports, setReports] = React.useState<Array<Reports>>([]);
  const [selectReports, setSelectReports] = React.useState([]);
  //console.log(permissions?.primaryAccount);
  useEffect(() => {
    if (!user) return;
    const id = user?.userAccounts[0].accountId;
    getAvailableUserSettings(
      id,
      (data: any) => {
        // console.log(data);
        setRoles(data.roles);
        setPages(data.pages);
        setReports(data.reports);
        setManagers(data.managers);
      },
      (err: any) => {
        console.log(err);
      }
    );
  }, []);
  //console.log(selectReports);
  function handleAddUser() {
    if (!user) return;
    let accountId = `${permissions?.primaryAccount[0]}`;
    adminAddUser(
      email,
      selectRoles,
      selectManagers,
      firstName,
      lastName,
      accountId,
      selectPages,
      selectReports,
      (data: any) => {
        console.log(data);
        setAlert(true);
        setSuccessAlert(true);
        setTimeout(() => setAlert(false), 3000);
        setEmail("");
        setRole("");
        setManager("");
        setFirstName("");
        setLastName("");
      },
      (err: any) => {
        console.log(err);
        setAlert(true);
        setSuccessAlert(false);
        setTimeout(() => setAlert(false), 3000);
      }
    );
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeRole = (event: SelectChangeEvent) => {
    setSelectRoles(event.target.value as string);
    setRole(event.target.value as string);
  };
  const handleChangeManager = (event: SelectChangeEvent) => {
    //console.log(event.target.value);
    setSelectManagers(event.target.value as string);
    setManager(event.target.value as string);
  };

  const openAlert = Boolean(anchorAlert);
  const alertId = openAlert ? "simple-popover" : undefined;

  return (
    <Paper variant="outlined" sx={{ minWidth: 400 }}>
      <Typography
        component={"span"}
        sx={{ display: "flex", flexDirection: "column", m: 2 }}
      >
        <FormControl sx={{ m: 1 }}>
          <InputLabel htmlFor="my-input">First Name</InputLabel>
          <Input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <InputLabel htmlFor="my-input">Last Name</InputLabel>
          <Input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <InputLabel htmlFor="my-input">Email address</InputLabel>
          <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectRoles}
              label="Role"
              onChange={handleChangeRole}
            >
              {roles.map((role, index) => (
                <MenuItem value={role.id} key={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <InputLabel htmlFor="my-input">User Role</InputLabel>
                <Input value={role} onChange={(e) => setRole(e.target.value)} /> */}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Manager</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectManagers}
            label="Manager"
            onChange={handleChangeManager}
          >
            {managers.map((man: any, index) => {
              return (
                <MenuItem value={man.userId} key={man.userId}>
                  {`${man.user.firstName} ${man.user.lastName}`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <PagesSelector
          pages={pages}
          selectPages={selectPages}
          setSelectPages={setSelectPages}
        />
        <ReportsSelector
          reports={reports}
          setSelectReports={setSelectReports}
          selectReports={selectReports}
        />
        <Paper>
          <Button onClick={() => handleAddUser()}>Submit</Button>
          <Button
            onClick={() => {
              setEmail("");
              setRole("");
              setManager("");
              setFirstName("");
              setLastName("");
            }}
          >
            Clear
          </Button>
        </Paper>
        {successAlert ? (
          <Popover
            id={alertId}
            open={openAlert}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            anchorReference={"none"}
          >
            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
              Email successfully sent
            </Alert>
          </Popover>
        ) : (
          <Popover
            id={alertId}
            open={openAlert}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            anchorReference={"none"}
          >
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              <strong>Email Failed to Send!</strong>
            </Alert>
          </Popover>
        )}
      </Typography>
    </Paper>
  );
}
