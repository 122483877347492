import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Typography,
  Box,
  CardActionArea,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import React from "react";
import { Order } from "../interfaces/Order";
import LoadingBlock from "../components/LoadingBlock";
import { useNavigate } from "react-router";
import { ScreenDict } from "../utils/screens.module";
import { PermissionsContext } from "../contexts/PermissionsContext";

interface Props {
  orders: Array<Order> | null;
}

export const TotalUnbilled = ({ orders }: Props) => {
  //console.log(ScreenDict.filter(screen => screen.name === "Invoices"))
  const navigate = useNavigate();
  const { permissions } = React.useContext(PermissionsContext);

  // console.log(orders);
  const handleClick = () => {
    // const invoice = ScreenDict.filter((screen) => screen.name === "Invoices");
    // if (!invoice) return;
    if (!permissions?.pages?.includes("Invoices")) return;
    navigate("/Invoices");
  };

  if (!orders) {
    return <LoadingBlock />;
  }

  //const totalsArray = orders[0].invoices.map(inv => inv.totalAmt)
  //const totalAmount = totalsArray.reduce((a, b) => a + b, 0)
  let totals = orders.map((order) => order.invoices);
  totals = totals.filter((t: any) => t.length > 0);
  let totalsArray: any = [];
  let sumTotal: any = [];
  for (let i = 0; i < totals.length; i++) {
    totals[i].forEach((inv) => sumTotal.push(inv.totalAmt));
    totals[i].forEach(
      (inv) =>
        (inv.totalAmt > 0 && inv.statusId === 3) ||
        (inv.statusId === 4 && totalsArray.push(inv.totalAmt))
    );
  }
  const totalAmount = totalsArray.reduce((a: any, b: any) => a + b, 0);
  const sumTotalReduced = sumTotal.reduce((a: any, b: any) => a + b, 0);
  const percentComplete = (1 - totalAmount / sumTotalReduced) * 100;

  return (
    <Card
      sx={{
        height: "300px",
        margin: "10px",
        width: "25%",
      }}
    >
      <CardActionArea onClick={handleClick}>
        <CardHeader
          avatar={
            <Avatar
              sx={{
                backgroundColor: "primary.main",
                height: 56,
                width: 56,
              }}
            >
              <AttachMoneyIcon />
            </Avatar>
          }
          title="TOTAL UNBILLED AMOUNT"
          titleTypographyProps={{ variant: "overline" }}
        />
      </CardActionArea>
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{
            justifyContent: "center",
            position: "relative",
            justifyItems: "center",
          }}
        >
          <Grid item>
            <Typography color="textPrimary" variant="h2">
              ${totalAmount.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>

        <LinearProgress value={percentComplete} variant="determinate" />
        <Typography
          variant="overline"
          fontStyle={"italic"}
        >{`${percentComplete}% Billed`}</Typography>
      </CardContent>
    </Card>
  );
};
