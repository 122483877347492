import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import MoneyIcon from "@mui/icons-material/Money";
import React from "react";
import { Order } from "../interfaces/Order";
import LoadingBlock from "../components/LoadingBlock";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { getUnbilledCard } from "../services/orderService";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { number } from "yup";

interface Props {
  orders: Array<Order> | null;
}

export const AverageUnbilledAge = ({ orders }: Props) => {
  const { permissions } = React.useContext(PermissionsContext);
  const [goal, setGoal] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  //console.log(permissions?.primaryAccount[0]);

  React.useEffect(() => {
    if (!permissions?.primaryAccount) return;

    getUnbilledCard(
      permissions?.primaryAccount[0],
      (data: any) => {
        setGoal(data.value);
        setIsLoading(false);
      },
      (err: any) => {
        console.log(err);
        setIsLoading(false);
      }
    );
  }, []);

  if (isLoading || !orders) {
    return <LoadingBlock />;
  }

  const dateTime = new Date();

  let ordersList = orders.filter(
    (order) => order.statusId == 3 || order.statusId == 4
  );

  let dateDiff = ordersList.map(
    (order) => new Date(order.createdAt).getTime() - dateTime.getTime()
  );
  let dateAvg = Math.round(
    dateDiff?.reduce((a, b) => a + b, 0) /
      dateDiff?.length /
      (1000 * 60 * 60 * 24)
  );

  //console.log(dateDiff)

  return (
    <Card
      sx={{
        height: "300px",
        margin: "10px",
        width: "25%",
      }}
      {...orders}
    >
      <CardHeader
        avatar={
          <Avatar
            sx={{
              backgroundColor: "error.main",
              height: 56,
              width: 56,
            }}
          >
            <MoneyIcon />
          </Avatar>
        }
        title="Average Age of Unbilled Orders"
        titleTypographyProps={{ variant: "overline" }}
      />
      <CardContent className="dashboardCard">
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Typography color="textPrimary" variant="h2">
              {`${Math.abs(dateAvg)} Days`}
            </Typography>
          </Grid>
        </Grid>
        <Box
          sx={{
            pt: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          {goal &&
            (Math.abs(dateAvg) > goal ? (
              <>
                <ArrowDownwardIcon color="error" />
                <Typography
                  color="error"
                  sx={{
                    mr: 1,
                  }}
                  variant="body1"
                >
                  {`Currently averaging ${
                    Math.abs(dateAvg) - goal
                  } days behind goal`}
                </Typography>
              </>
            ) : (
              <>
                <ArrowUpwardIcon color="success" />
                <Typography
                  color="success"
                  sx={{
                    mr: 1,
                  }}
                  variant="body1"
                >
                  {`Currently averaging ${Math.abs(
                    Math.abs(dateAvg) - goal
                  )} days ahead of goal`}
                </Typography>
              </>
            ))}

          <Typography color="textSecondary" variant="caption">
            Goal Comparison
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
