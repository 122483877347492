import React from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { updateOrder } from "../services/orderService";
import { ToastContext } from "../contexts/ToastContext";
import ShipmentImagePopover from "./ShipmentImagePopover";
import { Box } from "@mui/system";
import { SelectChangeEvent } from "@mui/material/Select";
import EmailCarrier from "./EmailCarrierV2";
import { textDriver } from "../services/textService";
import EmailReportImage from "./EmailReportImage";
//import ReassignImage from "./ReassignImage";
import TextDriverCustom from "./TextDriverCustom";
import ReassignImage2 from "./ReassignImage2";
import Swal from "sweetalert2";
import DeleteImage from "./DeleteImage";

export default function ApprovalCardActions({
  cardInfo,
  loadOrders,
  filter,
}: any) {
  const [expanded, setExpanded] = React.useState(false);
  const { setAlertMessage, setExceptionMessage, setSuccessMessage } =
    React.useContext(ToastContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [reasonCode, setReasonCode] = React.useState("");
  const [contactDisplay, setContactDisplay] = React.useState(false);
  const [noteDisplay, setNoteDisplay] = React.useState(false);
  const [reportContactDisplay, setReportContactDisplay] = React.useState(false);
  const [reassignImage, setReassignImage] = React.useState(false);
  const [reportImage, setReportImage] = React.useState(false);
  const [note, setNote] = React.useState("");
  const [imageInfo, setImageInfo] = React.useState([{}]);
  const [matchText, setMatchText] = React.useState("");
  const now = new Date();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const reasonCodes = [
    { code: 1, value: "N/A" },
    { code: 2, value: "Missing BOL" },
    { code: 3, value: "Illegible BOL" },
    { code: 4, value: "Claim" },
    { code: 5, value: "Wrong Image" },
  ];
  React.useEffect(() => {
    let IF = [];
    for (let i = 0; i < cardInfo.image.length; i++) {
      const newObj = {
        id: cardInfo.fileIds[i],
        url: cardInfo.image[i].file,
        matchScore: cardInfo.image[i].matchScore,
      };
      IF.push(newObj);
    }
    //console.log(IF);
    setImageInfo(IF);
  }, [cardInfo]);
  React.useEffect(() => {
    if (imageInfo.length < 1) return;
    const total = imageInfo.length;
    const matched = imageInfo.filter((i: any) => i.matchScore >= 0.75).length;
    const text = `${matched} of ${total} images match`;
    setMatchText(text);
  }, [imageInfo]);
  //console.log(imageInfo);

  const handleReject = (event: React.MouseEvent<HTMLButtonElement>) => {
    //What all should I do here? save the record (update status)
    //get id from card info, send to update order with whatever values are needed
    //mark bad images as "deleted=true"
    //Allow to re-text or email
    setAnchorEl(event.currentTarget);
    setExpanded(!expanded);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setExpanded(false);
    setReasonCode("");
    setContactDisplay(false);
    setNoteDisplay(false);
    setReportContactDisplay(false);
    setReportImage(false);
    setReassignImage(false);
  };

  const selectReasonCode = (event: SelectChangeEvent) => {
    setReasonCode(event.target.value);
    setContactDisplay(!contactDisplay);
  };
  const handleText = () => {
    let destination = `${cardInfo.shipment[0].destinationLocation.address}, 
    ${cardInfo.shipment[0].destinationLocation.city},
    ${cardInfo.shipment[0].destinationLocation.stateShort},
    ${cardInfo.shipment[0].destinationLocation.zip5}`;
    //console.log(destination);
    textDriver(
      cardInfo.shipment[0].driverName,
      cardInfo.shipment[0].phoneNumbers[0].phoneNumber,
      cardInfo.orderNumber,
      destination,
      cardInfo.customer,
      cardInfo.shipment[0].phoneNumbers[0].phoneNumber,
      reasonCode,
      (data: any) => {
        setSuccessMessage(data);
        setNoteDisplay(!noteDisplay);
      },
      (ex: any) => {
        setExceptionMessage(ex);
      }
    );
  };

  const handleRejectContact = () => {
    setNoteDisplay(!noteDisplay);
  };
  const handleRejectReport = () => {
    setReportContactDisplay(!reportContactDisplay);
  };
  const handleReportImage = () => {
    setReportImage(!reportImage);
  };
  const handleReassignImage = () => {
    setReassignImage(!reassignImage);
    setNoteDisplay(!noteDisplay);
  };
  const handleChangeNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };
  const handleSubmit = () => {
    let reasonCodeId = reasonCodes
      .filter((r) => r.value == reasonCode)
      .map((rc) => rc.code);
    //console.log(reasonCodeId[0]);
    updateOrder(
      cardInfo.id,
      (data: any) => {
        setSuccessMessage(
          `Order ${cardInfo.orderNumber} has been updated and moved to the rejected bucket.`
        );
        loadOrders();
      },
      undefined,
      undefined,
      undefined,
      3,
      reasonCodeId[0],
      note,
      undefined,
      (ex: any) => {
        setExceptionMessage(ex);
      }
    );
  };
  const handleApprove = () => {
    const id = cardInfo.id;
    Swal.fire({
      title: "Are You Sure You Want To Aprove?",
      confirmButtonText: "Yes",
      showDenyButton: true,
      // showCancelButton: true,
      // cancelButtonText: "No",
      denyButtonColor: "#D32F2f",
      confirmButtonColor: "#2E7D32",
    }).then((result) => {
      if (result.isConfirmed) {
        updateOrder(
          id,
          (data: any) => {
            setSuccessMessage(
              `Order ${cardInfo.orderNumber} is now ready to bill.`
            );
            loadOrders();
          },
          undefined,
          undefined,
          undefined,
          5,
          undefined,
          undefined,
          undefined,
          (ex: any) => {
            setExceptionMessage(ex);
          }
        );
      }
    });
  };

  return (
    <Typography sx={{ pt: 1 }}>
      <Stack
        direction={"row"}
        padding={0}
        alignItems={"center"}
        justifyContent={"center"}
        alignContent={"center"}
      >
        <Stack
          padding={0}
          alignItems={"center"}
          justifyContent={"center"}
          alignContent={"center"}
        >
          <Button sx={{ padding: 0 }}>
            <ShipmentImagePopover file={cardInfo.image} />
          </Button>
          {/* <Typography variant="overline">{matchText}</Typography> */}
        </Stack>
        <Stack direction={"row"}>
          {filter === "rejected" ? null : (
            <>
              <Button color="success" onClick={handleApprove}>
                Approve
              </Button>
              <Button color="error" onClick={handleReject}>
                Reject
              </Button>
            </>
          )}

          <DeleteImage imageInfo={imageInfo} />
        </Stack>
      </Stack>
      {expanded && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          //anchorReference={"center"}
        >
          <Card>
            <CardHeader
              title="Rejection Details"
              subheader="Please fill out and submit the following information:"
              titleTypographyProps={{ textAlign: "center" }}
              sx={{ backgroundColor: "whitesmoke" }}
            />
            <Divider />
            <CardContent>
              <Typography component={"div"} variant="h6">
                Select Reason Code
                <Divider />
              </Typography>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel variant="outlined" id="reason-select">
                  Reason Codes
                </InputLabel>
                <Select
                  labelId="reason-select"
                  id="account-select-helper"
                  value={reasonCode}
                  label="Reason Codes"
                  onChange={selectReasonCode}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {reasonCodes.map((r: any) => (
                    <MenuItem value={r.value} key={r.value}>
                      {r.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {reasonCode == "Wrong Image" ? (
                <>
                  <Typography component={"div"} variant="h6">
                    {/* Would you like to reassign the image to another order or */}
                    Would you like to report the image to the carrier?
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Button onClick={handleReportImage}>Report Image</Button>
                    <ReassignImage2
                      imageInfo={imageInfo}
                      handleNextStep={handleReassignImage}
                    />
                    <Button onClick={handleRejectReport}>No</Button>
                  </Stack>

                  {reportImage && (
                    <EmailReportImage
                      dispatcherEmail={cardInfo.dispatcherEmail}
                      row={cardInfo}
                      setNoteDisplay={setNoteDisplay}
                      setContactDisplay={setContactDisplay}
                      driver={cardInfo.shipment[0].driverName}
                      anchor={anchorEl}
                    />
                  )}
                  {reportContactDisplay && (
                    <>
                      <Typography component={"div"} variant="h6">
                        Would you like to contact the carrier?
                      </Typography>
                      <Divider />
                      <Stack direction={"row"} spacing={2}>
                        <EmailCarrier
                          dispatcherEmail={cardInfo.dispatcherEmail}
                          row={cardInfo}
                          reason={reasonCode}
                          setNoteDisplay={setNoteDisplay}
                          setContactDisplay={setContactDisplay}
                        />
                        {new Date(cardInfo.updatedAt) >=
                          new Date(
                            now.getFullYear(),
                            now.getMonth(),
                            now.getDate() - 7
                          ) && ( //<Button onClick={handleText}>Text Driver</Button>
                          <TextDriverCustom
                            row={cardInfo}
                            reason={reasonCode}
                          />
                        )}

                        <Button onClick={handleRejectContact}>No</Button>
                      </Stack>
                    </>
                  )}
                </>
              ) : (
                contactDisplay && (
                  <>
                    <Typography component={"div"} variant="h6">
                      Would you like to contact the carrier?
                    </Typography>
                    <Divider />
                    <Stack direction={"row"} spacing={2}>
                      <EmailCarrier
                        dispatcherEmail={cardInfo.dispatcherEmail}
                        row={cardInfo}
                        reason={reasonCode}
                        setNoteDisplay={setNoteDisplay}
                        setContactDisplay={setContactDisplay}
                      />
                      {new Date(cardInfo.updatedAt) >=
                        new Date(
                          now.getFullYear(),
                          now.getMonth(),
                          now.getDate() - 7
                        ) && (
                        <TextDriverCustom row={cardInfo} reason={reasonCode} />
                      )}

                      <Button onClick={handleRejectContact}>No</Button>
                    </Stack>
                  </>
                )
              )}
              {noteDisplay && (
                <>
                  <Typography component={"div"} variant="h6">
                    Please add any notes needed for the order:
                  </Typography>
                  <Divider />
                  <TextField
                    id="outlined-multiline-static"
                    label="Notes"
                    multiline
                    rows={10}
                    value={note}
                    onChange={handleChangeNote}
                    sx={{ margin: 1, width: "97.5%" }}
                  />
                </>
              )}
            </CardContent>
            <Box
              textAlign={"center"}
              padding={2}
              sx={{ backgroundColor: "#0B79D0" }}
            >
              <CardActionArea
                onClick={handleSubmit}
                sx={{ typography: "overline", color: "white", fontSize: 15 }}
              >
                Submit
              </CardActionArea>
            </Box>
          </Card>
        </Popover>
      )}
    </Typography>
  );
}
