import { FileDetails } from "../interfaces/FileDetails";
import { baseApi } from "./baseService";

const API = {
  updateOrderFile: "/api/orderFile/update",
  getUnmatchedFiles: "/api/orderFile/unmatched",
  matchOrderFile: "/api/orderFile/match",
  deleteOrderFile: "/api/orderFile/delete",
  deleteImageFile: "/api/orderFile/deleteImage",
  reassignOrderFile: "/api/orderFile/reassign",
  uploadOrderImage: "/api/orderFile/uploadOrderImage",
};

export const deleteOrderFile = (
  fileId: string | Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.deleteOrderFile, { fileId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const deleteImageFile = (
  fileId: string | Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.deleteImageFile, { fileId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const updateOrderFile = (
  fileId: string | Array<any>,
  orderId: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.updateOrderFile, { fileId, orderId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getUnmatchedFiles = (onSuccess: Function, onError?: Function) => {
  baseApi().then((api) =>
    api
      .post(API.getUnmatchedFiles)
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const matchOrderFile = (
  fileId: string,
  orderId: string,
  orderFileTypeId: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.matchOrderFile, { fileId, orderId, orderFileTypeId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const reassignOrderFile = (
  fileId: string | Array<any>,
  orderId: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.reassignOrderFile, { fileId, orderId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const uploadOrderImage = (
  fileDetails: FileDetails,
  orderId: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.uploadOrderImage, { fileDetails, orderId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};