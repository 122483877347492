import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import MoneyIcon from "@mui/icons-material/Money";
import React from "react";
import { Order } from "../interfaces/Order";
import LoadingBlock from "../components/LoadingBlock";
import { useNavigate } from "react-router";

interface Props {
  orders: Array<Order> | null;
}

export const TotalOrdersCard = ({ orders }: Props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/Orders");
  };

  if (!orders) {
    return <LoadingBlock />;
  }

  return (
    <Card
      sx={{
        height: "300px",
        margin: "10px",
        width: "25%",
      }}
    >
      <CardActionArea 
      ///onClick={handleClick}
      >
        <CardHeader
          avatar={
            <Avatar
              sx={{
                backgroundColor: "error.main",
                height: 56,
                width: 56,
              }}
            >
              <MoneyIcon />
            </Avatar>
          }
          title="Total Number of Orders"
          titleTypographyProps={{ variant: "overline" }}
        />
      </CardActionArea>
      <CardContent className="dashboardCard">
        <Grid
          container
          spacing={3}
          sx={{
            justifyContent: "center",
            position: "relative",
            justifyItems: "center",
          }}
        >
          <Grid item>
            <Typography color="textPrimary" variant="h2">
              {orders.length}
            </Typography>
          </Grid>
        </Grid>
        <Box
          sx={{
            pt: 2,
            display: "flex",
            alignItems: "center",
          }}
        ></Box>
      </CardContent>
    </Card>
  );
};
