import React from "react";
import {
  Button,
  Card,
  Grid,
  Paper,
  Popover,
  styled,
  ThemeProvider,
  Typography,
  useTheme,
  CardHeader,
  CardContent,
  Stack,
  Divider,
} from "@mui/material";
import haversineDistanceCalc from "../utils/haversineDistance";
import EmailCarriersFromMap from "./EmailCarriersFromMap";

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(.3),
  margin: theme.spacing(.3),
}));

export default function MapCards({ shipments }: any) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [shipmentEmails, setShipmentEmails] = React.useState<string[]>([]);
  const [invoices, setInvoices] = React.useState<any>([]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  React.useEffect(() => {
    setInvoices([]);
    const emails = shipments.map((ship: any) => ship.dispatcher.emailAddress);
    const uniqueEmails = Array.from(new Set(emails)) as string[];

    shipments.forEach((ship: any) => {
      ship.orderShipments.map((os: any) => {
        os.order.invoices.map((inv: any) => {
          setInvoices((prev: any) => [...prev, inv.totalAmt]);
        });
      });
    });

    setShipmentEmails(uniqueEmails);
  }, [shipments]);


  
  //Sum Stats on Rates

  let avgRate = parseFloat(
    (invoices.reduce((a: any, b: any) => a + b, 0) / invoices.length).toFixed(2)
  );
  let minRate = Math.min(...invoices);
  let maxRate = Math.max(...invoices);

  //Standardd Deviation
  let squaredDiff = invoices.map((inv: any) =>
    Math.pow(invoices.length - avgRate, 2)
  );
  let meanSquaredDiff =
    squaredDiff.reduce((a: any, b: any) => a + b, 0) / invoices.length;
  let stdDeviation = Math.sqrt(meanSquaredDiff);


  let avgMiles =
    Math.round(
      (shipments
        .map((ship: any) =>
          Math.ceil(
            haversineDistanceCalc(
              ship.originLocation.latitude,
              ship.originLocation.longitude,
              ship.destinationLocation.latitude,
              ship.destinationLocation.longitude
            )
          )
        )
        .reduce((a: any, b: any) => a + b, 0) /
        shipments.length) *
        100
    ) / 100;

  let avgRPM = (Math.round((avgRate / avgMiles) * 100) / 100).toFixed(2);

  let carrierModeMap: Array<[number, string]> = Object.values(
    shipments
      .map((ship: any) => ship.account.name)
      .reduce((count: any, e: any) => {
        if (!(e in count)) {
          count[e] = [0, e];
        }

        count[e][0]++;
        return count;
      }, {})
  );
  let carrierMode: [number, string] = carrierModeMap.reduce(
    (a: any, v: any) => (v[0] < a[0] ? a : v),
    [0, ""]
  );

  return (
    <Section sx={{ width: "100%" }}>
      <Grid item display="flex">
        <Card
          sx={{ p: 1, m: 1, width: "25%" }}
          style={{ backgroundColor: "whitesmoke" }}
        >
          <CardHeader
            title="Rate Info"
            titleTypographyProps={{
              //variant: "overline",
              fontSize: 18,
              fontWeight: "bold",
              align: "center",
              style: { textDecoration: "underline" },
            }}
            sx={{
              fontSize: 18, 
              padding: "0px", 
              margin: "0px", 
            }}
          />
          <CardContent sx={{m:0, p:0}}>
            <Stack sx={{m:0, p:0}} direction={"row"} alignItems={"center"}>
              <Stack direction={"column"}>
                <Typography variant="subtitle2">{`Minimum Rate: $${minRate}`}</Typography>
                <Typography variant="subtitle2" >{`Average Rate: $${avgRate}`}</Typography>
                <Typography variant="subtitle2">{`Maximum Rate: $${maxRate}`}</Typography>
              </Stack>
              <Typography
                p={1}
                fontStyle={"italic"}
                variant="subtitle2"
              >{`(+/- $${stdDeviation})`}</Typography>
            </Stack>
          </CardContent>
        </Card>
        <Card
          sx={{ p: 1, m: 1, width: "25%" }}
          style={{ backgroundColor: "whitesmoke" }}
        >
          <CardHeader
            title="Average Miles"
            titleTypographyProps={{
              //variant: "overline",
              fontSize: 18,
              fontWeight: "bold",
              align: "center",
              style: { textDecoration: "underline" },
            }}
            sx={{
              fontSize: 18, 
              padding: "0px", 
              margin: "0px", 
            }}
          />
          <CardContent sx={{ m:0, p:0, textAlign: "center" }}>
            <Typography variant="subtitle2" fontSize={16}>
              {avgMiles}
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{ p: 1, m: 1, width: "25%" }}
          style={{ backgroundColor: "whitesmoke" }}
        >
          <CardHeader
            title="Average Rate Per Mile"
            titleTypographyProps={{
              //variant: "overline",
              fontSize: 18,
              fontWeight: "bold",
              align: "center",
              style: { textDecoration: "underline" },
            }}
            sx={{
              fontSize: 18, 
              padding: "0px", 
              margin: "0px", 
            }}
          />
          <CardContent sx={{ m:0, p:0, textAlign: "center" }}>
            <Typography variant="subtitle2" fontSize={16}>
              {" "}
              {`$${avgRPM}`}{" "}
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{ p: 1, m: 1, width: "25%" }}
          style={{ backgroundColor: "whitesmoke" }}
        >
          <CardHeader
            title="Most Used Carrier"
            titleTypographyProps={{
              //variant: "overline",
              fontSize: 18,
              fontWeight: "bold",
              align: "center",
              style: { textDecoration: "underline" },
            }}
            sx={{
              fontSize: 18, 
              padding: "0px", 
              margin: "0px", 
            }}
          />
          <CardContent sx={{ m:0, p:0, textAlign: "center" }}>
            <Typography variant="subtitle2" fontSize={16}>
              {`${carrierMode[1]}`}{" "}
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            p: 1,
            m: 1,
            width: "25%",
            // display: "flex",
            // justifyContent: "center",
            // flexDirection: "column",
          }}
          
          style={{ backgroundColor: "whitesmoke" }}
        >
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item>
              <CardHeader
                title="Email All Carriers"
                titleTypographyProps={{
                  //variant: "overline",
                  fontSize: 18,
                  fontWeight: "bold",
                  align: "center",
                  style: { textDecoration: "underline" },
                }}
                sx={{
                  fontSize: 18, 
                  padding: "0px", 
                  margin: "8px", 
                }}
              />
            </Grid>
            <Grid item>
              <Button onClick={handleClick} variant="outlined">
                Send Email
              </Button>
            </Grid>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorReference="none"
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <EmailCarriersFromMap
                shipmentEmails={shipmentEmails}
                setAnchorEl={setAnchorEl}
              />
            </Popover>
          </Grid>
        </Card>
      </Grid>
    </Section>
  );
}
