import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router";
import axios from "axios";
import { UserContext } from "../contexts/UserContext";
import { ToastContext } from "../contexts/ToastContext";
import LoadingButton from "@mui/lab/LoadingButton";
import Copyright from "../components/Copyright";
import { useFormik } from "formik";
import * as yup from "yup";
import { userHierarchyAtom } from "../atoms/atoms";
import { useRecoilState } from "recoil";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { login } from "../services/userService";
import LoadingBlock from "../components/LoadingBlock";

const theme = createTheme();

export default function SignInScreen() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const userCtx = React.useContext(UserContext);
  const toastCtx = React.useContext(ToastContext);
  const permissionsCtx = React.useContext(PermissionsContext);
  const [userHierarchy, setUserHierarchy] = useRecoilState(userHierarchyAtom);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      toastCtx.closeAlert();
      setLoading(() => true);
      try {
        await login(
          values.email,
          values.password,
          (data: any) => {
            userCtx.saveUser(data.userInfo);
            permissionsCtx.savePermissions(data.permissions);
            setUserHierarchy(data.userHierarchy);
            setLoading(() => false);
            navigate("/Home");
          },
          (err: any) => {
            setLoading(() => false);
            //console.log(ex);
            toastCtx.setExceptionMessage(err);
          }
        );
      } catch (ex: any) {
        setLoading(() => false);
        //console.log(ex);
        toastCtx.setExceptionMessage(ex);
      }
    },
  });

  if (loading) return <LoadingBlock />;
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="images/Swifttrax-Pixellogic-Finalized.jpg"
            className="logo"
            alt="Logo"
          ></img>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              id="email"
              name="email"
              label="Email Address"
              placeholder="you@newco.com"
              margin="normal"
              fullWidth
              autoFocus
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              id="password"
              name="password"
              type="password"
              label="Password"
              placeholder="Something Strong"
              margin="normal"
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <LoadingButton
              type="submit"
              fullWidth
              loading={loading}
              variant="contained"
              sx={{ mt: 3, mb: 2, background: "#15157E" }}
            >
              Sign In
            </LoadingButton>
            <Grid container>
              <Grid item xs>
                <Link href="/ForgotPassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/SignUp" variant="body2">
                  {"Don't have an account? Request One"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(7, "Password should be of minimum 7 characters length")
    .required("Password is required"),
});
