import React from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
} from "@mui/material";
import { ToastContext } from "../contexts/ToastContext";
import { updateUser, updateUserPassword } from "../services/userService";
import { UserContext } from "../contexts/UserContext";

export const SettingsPassword = (props: any) => {
  const { user } = React.useContext(UserContext);
  const { setExceptionMessage, setAlertMessage } =
    React.useContext(ToastContext);
  const [values, setValues] = useState({
    password: "",
    confirm: "",
  });

  const handleChange = (event: any) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event: any) => {
    if (!user) return;
    if (values.password != values.confirm) {
      return setAlertMessage({
        title: "Password Error",
        message:
          "Passwords do not match. Please make sure the new password and confirmation are the same.",
        severity: "error",
      });
    }
    updateUserPassword(
      user?.id,
      values.password,
      (data: any) => {
        setAlertMessage({
          title: "Success",
          message: "Password successfully updated.",
          severity: "success",
        });
      },
      (ex: any) => {
        setAlertMessage({
          title: "Error",
          message: ex,
          severity: "error",
        });
      }
    );
  };

  return (
    <form {...props}>
      <Card>
        <CardHeader subheader="Update password" title="Password" />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Confirm password"
            margin="normal"
            name="confirm"
            onChange={handleChange}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Update
          </Button>
        </Box>
      </Card>
    </form>
  );
};
