import { Button, Popover, Typography } from "@mui/material";
import React from "react";
import ReassignImage from "./ReassignImage";
import ReassignImage2 from "./ReassignImage2";

interface Props {
    row: any,
    files: any, 
    setFiles: any
}

export default function UnmatchedImagesPopover(props: Props) {
    const { row, files, setFiles } = props
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <Typography sx={{m:1}}>
            <ReassignImage2 imageInfo={[{ url: row.image, id: row.id, unMatched: true }]} files={files} setFiles={setFiles}/>
        </Typography>
    )
}