import { useState } from "react";
import SearchByLocationRadius from "../components/SearchLocationRadius";
import {
  GoogleMap,
  Circle,
  useLoadScript,
  Marker,
} from "@react-google-maps/api";
import React from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Rating,
  styled,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { Shipment } from "../interfaces/Shipment";
import { ToastContext } from "../contexts/ToastContext";

import {
  getShipmentRadius,
  getShipmentsRadii,
  getTrailerTypes,
} from "../services/shipmentService";
import haversineDistanceCalc from "../utils/haversineDistance";
import MapCards from "../components/MapCards";


const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API || "";

const libraries: any = ["places"];

interface LatLng {
  lat: number;
  lng: number;
}

export default function DataVisualizationMap() {
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState(true);
  const [shipments, setShipments] = useState(Array<Shipment>);
  const [mapShipments, setMapShipments] = useState(shipments);
  const { setExceptionMessage } = React.useContext(ToastContext);
  const [trailerType, setTrailerType] = React.useState<any>([]);
  const [selectTrailerType, setSelectTrailerType] = React.useState<any>([]);
  const [dateRange, setDateRange] = React.useState<any>('')


  const [origin, setOrigin] = useState<{
    centerCircle: any;
    city: any;
    state: any;
    miles: any;
  }>({
    centerCircle: { lat: 41.8755616, lng: -87.6244212 },
    city: "",
    state: "",
    miles: 0,
  });

  const [destination, setDestination] = useState<{
    centerCircle: any;
    city: any;
    state: any;
    miles: any;
  }>({
    centerCircle: { lat: 41.8755616, lng: -87.6244212 },
    city: "",
    state: "",
    miles: 0,
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyD4N9AVtvwT47X_Y-8TkVtYVArAsexlOp8",
    libraries,
  });

  const mapRef: any = React.useRef();

  const onMapLoad = React.useCallback((map: any) => {
    mapRef.current = map;
  }, []);

  const panTo = ({ lat, lng }: LatLng) => {
    const bounds = new window.google.maps.LatLngBounds({ lat, lng });
    // console.log(lat, lng);
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(5);
  };

  const handleTrailerCheckboxClick = (e: any, id: any) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectTrailerType((prevSelected: any) => [...prevSelected, id]);
    } else {
      setSelectTrailerType((prevSelected: any) =>
        prevSelected.filter((selectedId: any) => selectedId !== id)
      );
    }
  };

  const handleDateCheckboxClick = (e: any) => {
    const isChecked = e.target.checked;
    const id = e.target.name
    //console.log(id)
    if (isChecked) {
      setDateRange(() => id);
    } else {
      setDateRange('')
    }
  };

  React.useEffect(() => {
    if (!destination.city) {
      getShipmentRadius(
        dateRange,
        selectTrailerType,
        origin.centerCircle.lat,
        origin.centerCircle.lng,
        origin.miles,
        "origin",
        (data: any) => {
          setShipments(data);
        },
        (err: any) => {
          setShipments([]);
        }
      );
    } else if (!origin.city) {
      getShipmentRadius(
        dateRange,
        selectTrailerType,
        destination.centerCircle.lat,
        destination.centerCircle.lng,
        destination.miles,
        "destination",
        (data: any) => {
          //console.log(data);
          setShipments(data);
        },
        (err: any) => {
          console.log(err);
          setShipments([])
        }
      );
    } else {
      getShipmentsRadii(
        dateRange,
        selectTrailerType,
        origin.centerCircle.lat,
        origin.centerCircle.lng,
        origin.miles,
        destination.centerCircle.lat,
        destination.centerCircle.lng,
        destination.miles,
        (data: any) => {
          setShipments(data);
        },
        (err: any) => {
          //console.log(err);
          setShipments([]);
        }
      );
    }
    getTrailerTypes(
      (data: any) => {
        setTrailerType(data);
      },
      (err: any) => {
        //console.log(err);
      }
    );
  }, [origin, destination]);

  interface ShipmentRow {
    id: number;
    dispatcherName: string;
    dispatcherPhone: string;
    dispatcherEmail: string;
    date: string;
    miles?: any;
    total: number;
    originCity?: any;
    originState?: any;
    destinationCity?: any;
    destinationState?: any;
    carrier: any;
    rating: any;
  }
  const columns: GridColDef[] = [
    { field: "id", headerName: "Shipment Number", flex: 1 },
    { field: "originCity", headerName: "Origin City", flex: 1 },
    { field: "originState", headerName: "Origin State", flex: 1 },
    { field: "carrier", headerName: "Carrier", flex: 1 },
    {
      field: "rating", headerName: "Carrier Rating", flex: 1, renderCell: ({ row }) => { 
        if (row.rating === 0) { return (<div>NA</div>) }
        return ( <Rating name="read-only" value={row.rating} readOnly /> )
      },
    },
    { field: "destinationCity", headerName: "Dest. City", flex: 1 },
    { field: "destinationState", headerName: "Dest. State", flex: 1 },
    { field: "miles", headerName: "Miles", flex: 1 },
    //{ field: "total", headerName: "Rate", flex: 1 },
    { field: "dispatcherName", headerName: "Dispatcher Name", flex: 1 },
    { field: "dispatcherPhone", headerName: "Dispatcher Phone #", flex: 1 },
    { field: "dispatcherEmail", headerName: "Dispatcher Email", flex: 1 },
  ];

  const generateRows = (mapShipments: Array<Shipment>) => {
    var rows: Array<ShipmentRow> = [];
    var id = 1;
    mapShipments.map((ship: any, index: number) => {
      let rating= ship.childAccounts.map((n:any)=> n.notes.reduce((a:any, r:any)=>a+r.rating,0)/n.notes.length)
      //console.log(rating)
      const row: ShipmentRow = {
        id: ship.shipmentNumber,
        dispatcherName: `${ship.dispatcher.lastName}, ${ship.dispatcher.firstName}`,
        dispatcherPhone: ship.dispatcher.userPhoneNumber,
        date: new Date(ship.createdAt).toLocaleDateString("en-us"),
        total: Math.floor(Math.random() * 3000),
        originCity: ship.originLocation?.city,
        originState: ship.originLocation?.stateShort,
        destinationCity: ship.destinationLocation?.city,
        destinationState: ship.destinationLocation?.stateShort,
        carrier: ship.account.name,
        dispatcherEmail: ship.dispatcher.emailAddress,
        rating: rating[0],
        miles: Math.ceil(
          haversineDistanceCalc(
            ship.originLocation?.latitude,
            ship.originLocation?.longitude,
            ship.destinationLocation?.latitude,
            ship.destinationLocation?.longitude
          )
        ),
      };
      //console.log(ship.childAccounts[0].notes[0].rating)
      rows.push(row);
      id++;
      return ship;
    });
    return rows;
  };
  //console.log(dateRange);

  if (!isLoaded) return <Paper>Loading...</Paper>;

  return (
    <Grid container>
      <Grid container direction={"row"}>
        <Grid item sx={{ width: "50%" }}>
          <Section>
            {/* rendering the google map */}
            <GoogleMap
              zoom={4}
              center={origin.centerCircle}
              mapContainerClassName="map-container"
              onLoad={onMapLoad}
            >
              <Circle
                center={origin.centerCircle}
                radius={origin.miles * 1609.34}
              />
              <Circle
                center={destination.centerCircle}
                radius={destination.miles * 1609.34}
              />
              {/* rendering markers below */}
              {shipments.length <= 0 ? (
                null
              ) : origin.miles && destination.miles ? (
                <>
                  {shipments.map((shipment: any, index) => {
                    return (
                      <>
                        <Marker
                          key={index}
                          position={{
                            lat: shipment.originLocation.latitude,
                            lng: shipment.originLocation.longitude,
                          }}
                          icon={{
                            path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                            scale: 2,
                            fillColor: "red",
                            fillOpacity: 0.5,
                            strokeColor: "red",
                            strokeWeight: 2,
                            strokeOpacity: 0.5,
                          }}
                        />
                        <Marker
                          key={index}
                          position={{
                            lat: shipment.destinationLocation.latitude,
                            lng: shipment.destinationLocation.longitude,
                          }}
                          icon={{
                            path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                            scale: 2,
                            fillColor: "blue",
                            fillOpacity: 0.5,
                            strokeColor: "blue",
                            strokeWeight: 2,
                            strokeOpacity: 0.5,
                          }}
                        />
                      </>
                    );
                  })}
                </>
              ) : origin.miles ? (
                <>
                  {shipments.map((shipment: any, index) => {
                    return (
                      <Marker
                        key={index}
                        position={{
                          lat: shipment.originLocation.latitude,
                          lng: shipment.originLocation.longitude,
                        }}
                        icon={{
                          path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                          scale: 2,
                          fillColor: "red",
                          fillOpacity: 0.5,
                          strokeColor: "red",
                          strokeWeight: 2,
                          strokeOpacity: 0.5,
                        }}
                      />
                    );
                  })}
                </>
              ) : destination.miles ? (
                <>
                  {shipments.map((shipment: any, index) => {
                    return (
                      <Marker
                        key={index}
                        position={{
                          lat: shipment.destinationLocation.latitude,
                          lng: shipment.destinationLocation.longitude,
                        }}
                        icon={{
                          path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                          scale: 2,
                          fillColor: "blue",
                          fillOpacity: 0.5,
                          strokeColor: "blue",
                          strokeWeight: 2,
                          strokeOpacity: 0.5,
                        }}
                      />
                    );
                  })}
                </>
              ) : (
                null
              )}
            </GoogleMap>
          </Section>
        </Grid>
        <ThemeProvider theme={theme}>
          <Grid item sx={{ width: "50%" }}>
            <Section>
              <Section>
                <Typography variant="h6">
                  Search within a radius of the Origin
                </Typography>
                <SearchByLocationRadius
                  panTo={panTo}
                  setMapShipments={setMapShipments}
                  location={origin}
                  setLocation={setOrigin}
                />
              </Section>
              <Section>
                <Typography variant="h6">
                  Search within a radius of the Destination
                </Typography>
                {/* create destination state variables -replace origin- and pass in */}
                <SearchByLocationRadius
                  panTo={panTo}
                  setMapShipments={setMapShipments}
                  location={destination}
                  setLocation={setDestination}
                />
              </Section>
            </Section>
          </Grid>
        </ThemeProvider>
      </Grid>
      <Grid sx={{ display: "flex" }}>
        <Card sx={{ m: 0.2, display: "flex" }}>
          <Typography
            sx={{ m: 1, fontWeight: "bold", textDecoration: "underline" }}
          >
            Select trailer type:
          </Typography>
          {trailerType &&
            trailerType.map((t: any) => (
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label={t.name}
                id={t.id}
                onClick={(e: any) => handleTrailerCheckboxClick(e, t.id)}
              />
            ))}
        </Card>
        <Card sx={{ m: 0.2, display: "flex" }}>
          <Typography
            sx={{ m: 1, fontWeight: "bold", textDecoration: "underline" }}
          >
            Select Date Range:
          </Typography>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="30 Days"
            id={'30'}
            disabled={dateRange && dateRange != '30'}
            name="30"
            onClick={(e: any) => handleDateCheckboxClick(e)}
          />
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="60 Days"
            id="60"
            name="60"
            disabled={dateRange && dateRange != '60'}
            onClick={(e: any) => handleDateCheckboxClick(e)}
          />
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="90 Days"
            id="90"
            name="90"
            disabled={dateRange && dateRange != '90'}
            onClick={(e: any) => handleDateCheckboxClick(e)}
          />
        </Card>
      </Grid>

      {origin.miles && destination.miles ? <MapCards shipments={shipments} /> : null}

      <Grid item xs={12}>
        <DataGridPro
          sx={{ background: "white" }}
          density="compact"
          autoHeight
          columns={columns}
          rows={generateRows(shipments)}
        />
      </Grid>
    </Grid>
  );
}

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(1),
}));
