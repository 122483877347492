import {
  Alert,
  AlertTitle,
  Button,
  Card,
  FormControl,
  Input,
  InputLabel,
  Paper,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import React from "react";
import { textDriverCustom } from "../services/textService";
import Box from "@mui/material/Box";

interface Props {
  row: any;
}

export default function TextDriverCustom({ row, reason }: any) {
  //const { row } = props;
  console.log(row);
  const [toNumber, setToNumber] = React.useState(row.driverPhone);
  // const [emailSubject, setEmailSubject] = React.useState(
  //   `Shipment # ${row.id}`
  // );
  const fromNumber = row.shipment[0].phoneNumbers[0].phoneNumber;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [anchorAlert, setAlert] = React.useState(false);
  const [successAlert, setSuccessAlert] = React.useState(false);
  //const [addImageButton, setAddImageButton] = React.useState(true);
  //const [imageUrl, setImageUrl] = React.useState("");
  const [textMessage, setTextMessage] = React.useState(
    `${row.shipment[0].driverName}, payment for this shipment has been placed on hold for the following reason: ${reason}`
  );

  // const handleChangeEmailAddress = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setToNumber(event.target.value);
  // };

  const handleChangeTextMessage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTextMessage(event.target.value);
  };

  // const handleChangeEmailSubject = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setEmailSubject(event.target.value);
  // };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleAddImageUrl = () => {
  //   setAddImageButton(!addImageButton);
  //   addImageButton
  //     ? doesImageExist()
  //     : setEmailMessage(
  //         `Dear Carrier, payment for this shipment has been placed on hold for the following reason: ${row.reason}`
  //       );
  // };

  // React.useEffect(() => {
  //   row.orders[0].files.length > 0
  //     ? setImageUrl(row.orders[0].files[0].file)
  //     : setImageUrl("No Image On File");
  // }, []);

  // const doesImageExist = () => {
  //   if (row.orders[0].files.length > 0) {
  //     setEmailMessage(`Dear Carrier, payment for this shipment has been placed on hold for the following reason: ${row.reason}.
  //          Please see Image Link: ${imageUrl}`);
  //   } else {
  //     setEmailMessage(`Dear Carrier, payment for this shipment has been placed on hold for the following reason: ${row.reason}.
  //           ${imageUrl}`);
  //   }
  // };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const openAlert = Boolean(anchorAlert);
  const alertId = openAlert ? "simple-popover" : undefined;

  function handleSendText() {

    textDriverCustom(
      toNumber,
      fromNumber,
      textMessage,
      (data: any) => {
        setAlert(true);
        setSuccessAlert(true);
        setTimeout(() => setAlert(false), 3000);
        console.log(data);
      },
      (err: any) => {
        setAlert(true);
        setSuccessAlert(false);
        setTimeout(() => setAlert(false), 3000);
        console.log(err);
      }
    );
    // emailCarrier(
    //   emailAddress,
    //   emailSubject,
    //   emailMessage,
    //   (data: any) => {
    //     setAlert(true);
    //     setSuccessAlert(true);
    //     setTimeout(() => setAlert(false), 3000);
    //     console.log(data);
    //   },
    //   (err: any) => {
    //     setAlert(true);
    //     setSuccessAlert(false);
    //     setTimeout(() => setAlert(false), 3000);
    //     console.log(err);
    //   }
    //);

    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        anchorReference={"none"}
      >
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          Text successfully sent
        </Alert>
      </Popover>
    );
  }

  return (
    <Box>
      <Button aria-describedby={id} onClick={handleClick}>
        Text Driver
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <FormControl variant="standard" sx={{ margin: 1, width: "300px" }}>
          <InputLabel>To Number</InputLabel>
          <Input
            id="component-simple"
            value={toNumber}
            //onChange={handle}
          />
        </FormControl>
        {/* <FormControl variant="standard" sx={{ margin: 1, width: "300px" }}>
          <InputLabel htmlFor="component-simple">Email Subject</InputLabel>
          <Input
            id="component-simple"
            value={emailSubject}
            onChange={handleChangeEmailSubject}
          />
        </FormControl> */}
        <Card sx={{ py: 1, height: 1, boxSizing: "border-box", width: "100%" }}>
          <TextField
            id="outlined-multiline-static"
            label="Text Message Content"
            multiline
            rows={5}
            value={textMessage}
            onChange={handleChangeTextMessage}
            sx={{ margin: 1, width: "97.5%" }}
          />
          <Typography>
            <Button
              aria-describedby={id}
              variant="contained"
              sx={{ margin: 1 }}
              onClick={() => {
                handleSendText();
                handleClose();
              }}
            >
              Send Text
            </Button>
            {/* {addImageButton ? (
              <Button
                aria-describedby={id}
                variant="contained"
                sx={{ margin: 1 }}
                onClick={handleAddImageUrl}
              >
                Add Image URL
              </Button>
            ) : (
              <Button
                aria-describedby={id}
                variant="contained"
                sx={{ margin: 1 }}
                onClick={handleAddImageUrl}
              >
                Remove Image URL
              </Button>
            )} */}
            <Button
              aria-describedby={id}
              variant="contained"
              sx={{ margin: 1 }}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
          </Typography>
        </Card>
      </Popover>
      {successAlert ? (
        <Popover
          id={alertId}
          open={openAlert}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          anchorReference={"none"}
        >
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            Text successfully sent
          </Alert>
        </Popover>
      ) : (
        <Popover
          id={alertId}
          open={openAlert}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          anchorReference={"none"}
        >
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <strong>Text Failed to Send!</strong>
          </Alert>
        </Popover>
      )}
    </Box>
  );
}
