import React from "react";
import LoadingBlock from "../components/LoadingBlock";
import { Doughnut } from "react-chartjs-2";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  Button,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export default function ShipmentChart({ ship, onClick }) {
  const theme = useTheme();

  //Dynamically set colors in graph
  const colorSet = (bar) => {
    switch (bar.statusId) {
      case "1":
        return "#4996AD";
      case "2":
        return "#44AD4E";
      case "3":
        return "#FFAF42";
      case "4":
        return "#8b0000";
      case "5":
        return "darkgreen";
      case "6":
        return "#023020";
      case "7":
        return "grey";
      case "8":
        return "purple";
      case "9":
        return "#023020";
    }
  };

  //--Data wrangling--//
  let shipmentCount = [];
  let dataSchema = {
    //schema that charts.js wants data to be in with initial set values
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderWidth: 2,
        borderColor: "#FFFFFF",
        hoverBorderColor: "#FFFFFF",
      },
    ],
  };

  let legend = [
    {
      //schema that charts.js wants the legend to be in
    },
  ];

  //New variable where we can get counts and relevant fields cleanly
  ship.forEach((shipment) => {
    shipmentCount.push({
      id: shipment.id,
      statusId: shipment.statusId,
      statusDesc: shipment.status.description,
      count: 1,
    });
  });

  //Aggregate data into arrays grouped by status
  let shipments = shipmentCount.reduce((acc, curr) => {
    const index = acc.findIndex((item) => item.statusId === curr.statusId);
    index > -1
      ? (acc[index].count += curr.count)
      : acc.push({
          ids: [],
          statusId: curr.statusId,
          statusDesc: curr.statusDesc,
          count: curr.count,
        });
    return acc;
  }, []);

  //Add the IDs for each grouping to the ID array
  shipments.forEach((element) => {
    let b = 0;
    for (let i = 0; i < shipmentCount.length; i++) {
      if (shipmentCount[i].statusId === element.statusId) {
        element.ids[b] = shipmentCount[i].id;
        b++;
      }
    }
  });

  //get total number so that we can create a percentage
  let sumTotal = [];
  shipmentCount.forEach((entry) => {
    sumTotal.push(entry.count);
  });
  sumTotal = sumTotal.reduce((total, count) => total + count, 0);

  //set in format that chart.js likes
  for (let i = 0; i < shipments.length; i++) {
    dataSchema.datasets[0].data[i] = `${Math.round(
      (shipments[i].count / sumTotal) * 100
    )}`;
    dataSchema.datasets[0].backgroundColor[i] = colorSet(shipments[i]);
  }

  //Add percentage sign to values
  dataSchema.datasets[0].data.forEach((data, i) => `${data[i]}%`);
  //console.log(dataSchema.datasets[0].data);

  //create labels array for the donut
  shipments.forEach((entry, index) => {
    dataSchema.labels.push(entry.statusDesc);
  });

  //create separate dataset for the legend
  shipments.forEach((entry) => {
    legend.push({
      title: entry.statusDesc,
      value: Math.round((parseInt(entry.count) / sumTotal) * 100),
      color: colorSet(entry),
    });
  });

  //Chart options
  const options = {
    animation: true,
    cutoutPercentage: 80,
    layout: { padding: 1 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 5,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  if (!ship) {
    return <LoadingBlock />;
  }

  return (
    <Card sx={{ width: "50%" }}>
      <CardHeader
        action={
          <Button
            endIcon={<ArrowRightIcon fontSize="small" />}
            size="small"
            onClick={onClick}
          >
            All Shipments
          </Button>
        }
        title="Shipment Statuses"
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: "relative",
          }}
        >
          <Doughnut data={dataSchema} options={options} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 2,
          }}
        >
          {legend.map(({ color, title, value }, index) => {
            return (
              <Box
                key={index}
                sx={{
                  p: 1,
                  textAlign: "center",
                }}
              >
                <Typography color="textPrimary" variant="body1">
                  {title}
                </Typography>
                <Typography style={{ color }} variant="h4">
                  {value}%
                </Typography>
              </Box>
            );
          })}
        </Box>
      </CardContent>
    </Card>
  );
}
