import React from "react";
import { Reports } from "../interfaces/Report";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import TaskIcon from "@mui/icons-material/Task";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
export const ReportsList: Array<Reports> = [
  {
    id: 6,
    name: "Active Invoice Aging",
    description:
      "Report for all active invoices by time. Broken into 30-60-90 aging tabs.",
    icon: (
      <HourglassBottomIcon
        sx={{
          height: "100%",
          width: "100%",
          color: "white",
          bgcolor: "darkred",
        }}
      />
    ),
  },
  {
    id: 1,
    name: "Invoice Issues",
    description: "Report for all currently on hold invoices.",
    icon: (
      <MoneyOffIcon
        sx={{
          height: "100%",
          width: "100%",
          color: "white",
          bgcolor: "darkred",
        }}
      />
    ),
  },
  {
    id: 2,
    name: "Blocked Shipments",
    description: 'Shipments that are currently in a "blocked" status.',
    icon: (
      <RemoveCircleOutlineIcon
        sx={{
          height: "100%",
          width: "100%",
          color: "white",
          bgcolor: "darkred",
        }}
      />
    ),
  },
  {
    id: 3,
    name: "Orders Missing Documents",
    description:
      "Orders that are no longer in progress and are missing documents. ",
    icon: (
      <AssignmentLateIcon
        sx={{
          height: "100%",
          width: "100%",
          color: "white",
          bgcolor: "darkred",
        }}
      />
    ),
  },
  {
    id: 4,
    name: "Users Assigned to Problem Items",
    description:
      "List of all problem items currently in the system with the user assigned to them. Includes Orders, Shipments, and Invoices on different tabs. ",
    icon: (
      <PersonOffIcon
        sx={{
          height: "100%",
          width: "100%",
          color: "white",
          bgcolor: "darkred",
        }}
      />
    ),
  },
  {
    id: 5,
    name: "Completed Invoices/Orders/Shipments",
    description:
      "List of completed Orders, Shipments, or Invoices. Defaults to Invoices.",
    icon: (
      <TaskIcon
        sx={{
          height: "100%",
          width: "100%",
          color: "white",
          bgcolor: "darkgreen",
        }}
      />
    ),
  },
];
