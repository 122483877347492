import React from "react";
import { Line } from "react-chartjs-2";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
} from "@mui/material";
import { ToastContext } from "../contexts/ToastContext";
import LoadingBlock from "../components/LoadingBlock";
import * as Utils from "../utils/utils";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Order } from "../interfaces/Order";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";
import { getLineChartOrders } from "../services/orderService";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { UserContext } from "../contexts/UserContext";
import { QueueContext } from "../contexts/QueueContext";
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement
);

interface Props {
  //userId: string;
  onClick?: any;
}

export default function DashboardLineChart({
  //userId,
  onClick,
}: Props) {
  const [orders, setOrders] = React.useState(Array<Order>);
  const [isLoading, setIsLoading] = React.useState(false);
  const { setExceptionMessage } = React.useContext(ToastContext);
  const { permissions, getPermissions } = React.useContext(PermissionsContext);
  const { user } = React.useContext(UserContext);
  const theme = useTheme();
  const { queueMessages } = React.useContext(QueueContext);
  //console.log([user?.id]);
  React.useEffect(() => {
    if (!user?.id) return;

    getLineChartOrders(
      !permissions?.hierarchy ? user.id : [...permissions?.hierarchy, user.id],
      (data: any) => {
        setOrders(data);
        setIsLoading(false);
      },
      (ex: any) => {
        setExceptionMessage(ex);
        setIsLoading(false);
      }
    );
  }, [queueMessages.OrderMessage]);
  //console.log(orders);
  if (isLoading || !user?.id) return <LoadingBlock />;

  const status: any = {
    1: "New",
    2: "Active",
    3: "Needs Attention",
    4: "On Hold",
    5: "Ready To Bill",
    6: "Completed",
    7: "Canceled",
  };

  const labels = Utils.months({ count: 12 });
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Orders Created",
        data: Array(), //{date}
        fill: false,
        borderColor: Utils.CHART_COLORS.blue,
        tension: 0.1,
      },
      {
        label: "Orders Completed",
        data: Array(),
        fill: false,
        borderColor: Utils.CHART_COLORS.green,
        tension: 0.1,
      },
    ],
  };

  let createdDates = orders
    .filter(
      (ord) =>
        new Date(ord.createdAt).getFullYear() === new Date().getFullYear()
    )
    .map((ord) => new Date(ord.createdAt).getMonth());

  let updatedDates = orders
    .filter(
      (ord) =>
        new Date(ord.createdAt).getFullYear() === new Date().getFullYear()
    )
    .map((ord) => new Date(ord.updatedAt).getMonth());

  for (let i = 0; i < labels.length; i++) {
    data.datasets[0].data.push(
      createdDates
        .filter((ord) => ord === i + 1)
        .reduce((acc, curr) => acc + 1, 0)
    );
    data.datasets[1].data.push(
      updatedDates
        .filter((ord) => ord === i + 1)
        .reduce((acc, curr) => acc + 1, 0)
    );
  }
  //console.log(data)
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      // legend: {
      // position: 'bottom',
      // },
      title: {
        display: false,
        text: "Orders Per Month",
      },
    },
    xAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0,
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider,
        },
      },
    ],
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 5,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <Card sx={{ width: "50%" }}>
      <CardHeader
        // action={
        //   <Button
        //     endIcon={<ArrowRightIcon fontSize="small" />}
        //     size="small"
        //     onClick={onClick}
        //   >
        //     All Orders
        //   </Button>
        // }
        title="Orders Per Month"
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            minHeight: 400,
            position: "relative",
          }}
        >
          <Line data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
}
