import React from "react";
import { Box, CardHeader, Container, Grid } from "@mui/material";
import { ReportListToolbar } from "../components/ReportListToolbar";
import { ReportCard } from "../components/ReportCard";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { ReportsList } from "../utils/reports.module";

const ReportsScreen = () => {
  const { permissions } = React.useContext(PermissionsContext);

  // let reports = ReportsList.filter((rl) =>
  //   permissions?.reportsList?.includes(rl.id)
  // );
  let reports = ReportsList.filter((rl) => {
    if (!permissions?.reportsList) return false;
    for (let i = 0; i < permissions?.reportsList.length; i++) {
      if (rl.id == permissions?.reportsList[i]) {
        return true;
      }
    }
    return false;
  });
  //console.log(reports);
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <CardHeader
          title="Reports"
          titleTypographyProps={{
            fontSize: 40,
            variant: "overline",
            textAlign: "center",
            fontStyle: "underline",
          }}
        />
        <Container maxWidth={false}>
          {/* <ReportListToolbar /> */}
          <Box sx={{ pt: 3 }}>
            <Grid container spacing={3}>
              {reports.map((reports, id) => (
                <Grid item key={reports.id} lg={4} md={6} xs={12}>
                  <ReportCard
                    id={reports.id}
                    name={reports.name}
                    description={reports.description}
                    icon={reports.icon}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          {/* <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 3
            }}
          >
            <Pagination
              color="primary"
              count={3}
              size="small"
            />
          </Box> */}
        </Container>
      </Box>
    </>
  );
};
export default ReportsScreen;
