import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { UserContext } from "../contexts/UserContext";

interface Props {
  formik?: any;
  panTo?: any;
  setOrigin?: any;
  origin?: any;
  setSignupAddress?: any;
  title?: string;
}

export default function AutocompleteAddresses({
  formik,
  panTo,
  setOrigin,
  origin,
  setSignupAddress,
  title = "Address",
}: Props) {
  const { user } = React.useContext(UserContext);
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const getSuggestions = () => {
    return data.map((suggestion) => suggestion.description);
  };

  const handleAddressChange = async (address: any) => {
    if (!address) return;

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);

      const { address_components } = results[0];
      let street, city, state, zip5;

      address_components.forEach((ad) => {
        switch (ad.types[0]) {
          case "route":
            street = ad.long_name;
            break;
          case "locality":
            city = ad.long_name;
            break;
          case "administrative_area_level_1":
            state = ad.short_name;
            break;
          case "postal_code":
            zip5 = ad.short_name;
            break;
          default:
            break;
        }
      });

      if (user) {
        const newState = { ...origin };
        newState.city = city;
        newState.state = state;
        newState.centerCircle = { lat, lng };
        setOrigin(newState);
        panTo({ lat, lng });
      } else {
        setSignupAddress({
          address: `${street}`,
          city: `${city}`,
          stateShort: `${state}`,
          zip: parseInt(`${zip5}`),
          latitude: lat,
          longitude: lng,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Autocomplete
      disablePortal
      id="address"
      options={getSuggestions()}
      sx={{ width: "100%" }}
      inputValue={value}
      onInputChange={(event, newInputValue) => {
        setValue(newInputValue);
        formik.setFieldValue("address", newInputValue);
      }}
      onChange={(_, address) => {
        clearSuggestions();
        handleAddressChange(address);
      }}
      renderInput={(params) => {
        return <TextField {...params} label={title} />;
      }}
    />
  );
}

