import { Button } from "@mui/material";
import React from "react";
import Swal from "sweetalert2";
import { ToastContext } from "../contexts/ToastContext";
import { approveAllOrders, updateOrder } from "../services/orderService";

export default function ApproveAllOrders({ selectedRowIds, loadOrders }: any) {
    const { setAlertMessage, setExceptionMessage, setSuccessMessage } =
        React.useContext(ToastContext);


    const handleApproveAll = () => {
        // const id = cardInfo.id;
        const ids = selectedRowIds
        Swal.fire({
            title: "Are You Sure You Want To Aprove?",
            confirmButtonText: "Yes",
            showDenyButton: true,
            // showCancelButton: true,
            // cancelButtonText: "No",
            denyButtonColor: "#D32F2f",
            confirmButtonColor: "#2E7D32",
        }).then((result) => {
            if (result.isConfirmed) {
                approveAllOrders(ids, (data: any) => {
                    setSuccessMessage(
                        `Order(s) ${selectedRowIds} now ready to bill.`
                    );
                    //setTimeout(()=>setSuccessMessage(), 3000)
                    loadOrders();
                    console.log(data)
                },
                    (ex: any) => {
                        setExceptionMessage(ex);
                    })
            }
        });
    };



    return (
        <Button sx={{ m: 1 }} variant="contained" onClick={handleApproveAll}>Approve All Selected</Button>
    )
}