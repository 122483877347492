import { Button, Card, CardContent, CardHeader, Checkbox, FormControl, InputLabel, MenuItem, OutlinedInput, Paper, Popover, Select, Typography } from '@mui/material'
import React from 'react'
import Swal from 'sweetalert2';
import { deleteImageFile } from '../services/orderFileService';

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export default function DeleteImage({ imageInfo }: any) {
    const [selectedImage, setSelectedImage] = React.useState<Array<any>>([]);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    //console.log(imageInfo)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClickDelete = () => {
        setAnchorEl(null);

        Swal.fire({
            title: `Are you sure you want to delete ${selectedImage.length} images(s)?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2e7d32',
            cancelButtonColor: '#d32f2f',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteImageFile(selectedImage, (data: any) => {
                    console.log(data)
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                })
            }
        })
    }

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <div>
            <Button aria-describedby={id} onClick={handleClick}>
                Delete an Image
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorReference="none"
                sx={{
                    height: "60%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Card>
                    <CardHeader
                        title="Please select image(s) to DELETE"
                        titleTypographyProps={{ textAlign: "center" }}
                    />
                    <CardContent sx={{ width: 500, m: 1, textAlign: "center" }}>
                        {imageInfo.length >= 1 ? (
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <InputLabel id="demo-multiple-checkbox-label">
                                    Images
                                </InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={selectedImage}
                                    onChange={(event: any) => {
                                        setSelectedImage(event.target.value);
                                    }}
                                    input={<OutlinedInput label="Images" />}
                                    renderValue={(selected) => {
                                        return selected.join(", ");
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {imageInfo.map((image: any, index: any) => {
                                        // console.log(selectedImage)
                                        return (
                                            <MenuItem key={index} value={image.id}>
                                                <Checkbox
                                                    checked={selectedImage.indexOf(image.id) > -1}
                                                />
                                                <Paper
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <img
                                                        className="reassign_image"
                                                        src={image.url}
                                                        alt="image"
                                                    ></img>
                                                </Paper>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        ) : (
                            <>
                                <Typography
                                    variant={"overline"}
                                    paddingRight={5}
                                    visibility={"visible"}
                                    sx={{ fontSize: 12 }}
                                >
                                    <Typography>
                                        <strong>Image ID: </strong>
                                        {selectedImage}
                                    </Typography>
                                </Typography>
                            </>
                        )}
                        {selectedImage.length > 0 ? (<Button onClick={handleClickDelete}>Delete Image</Button>) : (<></>)}
                    </CardContent>
                </Card>
            </Popover>
        </div>

    )

}