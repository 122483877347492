import * as React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  DateRange,
  DateRangePicker,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import { Dayjs } from "dayjs";
import { Typography } from "@mui/material";

export default function DateRangePickerCustom({ setFromDate, setToDate }: any) {
  const [value, setValue] = React.useState<DateRange<Dayjs>>([null, null]);
  //const [disable,setDisable]=React.useState(false)

  const handleSelect = (newValue: any) => {
    setValue(newValue);
    //console.log(new Date(newValue[0].$d));
    let fromDate = new Date(newValue[0].$d);
    let toDate = new Date(newValue[1].$d);
    //console.log(new Date() > fromDate ? true : false);
    setFromDate(fromDate);
    setToDate(toDate);
  };

  return (
    <Typography pt={2}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={{ start: "Start Date", end: "End Date" }}
      >
        <DateRangePicker
          value={value}
          // onChange={(newValue) => {
          //   setValue(newValue);
          // }}

          onChange={handleSelect}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField
                {...startProps}
                sx={{ background: "whitesmoke" }}
                variant="outlined"
              />
              <Box sx={{ mx: 1 }}> to </Box>
              <TextField
                {...endProps}
                sx={{ background: "whitesmoke" }}
                variant="outlined"
              />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    </Typography>
  );
}
