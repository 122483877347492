import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
  Stack,
} from "@mui/material";
import React from "react";

export default function PagesSelector({
  pages,
  selectPages,
  setSelectPages,
}: any) {
  const handleCheckBox = (e: any) => {
    if (selectPages.includes(e.target.id)) {
      setSelectPages((prev: any) =>
        prev.filter((id: any) => id !== e.target.id)
      );
    } else {
      setSelectPages((prev: any) => [...prev, e.target.id]);
    }
  };
  return (
    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
      <FormLabel component={"legend"}>
        Select Available Pages for User
      </FormLabel>
      <FormGroup>
        <Stack flexDirection={"row"}>
          {pages.map((p: any) => (
            <FormControlLabel
              control={<Checkbox id={p.id} />}
              label={p.value}
              id={p.id}
              onChange={handleCheckBox}
            />
          ))}
        </Stack>
      </FormGroup>
    </FormControl>
  );
}
