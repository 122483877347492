import { Button, FormControl, Input, InputLabel, Stack, Typography } from '@mui/material';
import React from 'react';
import { massEmailFromMap } from '../services/emailService';
import Swal from 'sweetalert2';


export default function EmailCarriersFromMap({ shipmentEmails, setAnchorEl }: any) {
    const [emails, setEmails] = React.useState<string[]>([]);
    const [subject, setSubject] = React.useState<string>('');
    const [content, setContent] = React.useState<string>('');

    React.useEffect(() => {
        setEmails(shipmentEmails);
    }, [shipmentEmails]);

    const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const inputEmails = inputValue.split(',').map((email) => email.trim());

        setEmails(inputEmails);
    };

    const handleSubjectInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setSubject(inputValue);
    };

    const handleContentInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = event.target.value;
        setContent(inputValue);
    };

    const handleSendButtonClick = () => {
        console.log('Sending email to:', emails, 'with subject:', subject, 'and content:', content);
        // Implement email sending functionality here
        massEmailFromMap(emails, subject, content, (data: any) => {
            setAnchorEl(null)
            Swal.fire({
                icon: 'success',
                title: 'Email sent successfully',
                showConfirmButton: false,
                timer: 2000 // The alert will be automatically closed after 2 seconds
            });
        },
            (err: any) => {
                console.log(err);
                setAnchorEl(null)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Failed to send email!',
                });
            })
    };

    return (
        <Stack direction="column" spacing={1} sx={{ width: '600px', m: 2 }}>
            <FormControl variant="standard" >
                <InputLabel>To {emails.length} Email Addresses:</InputLabel>
                <Input id="component-simple" value={emails.join(', ')} multiline onChange={handleEmailInputChange} sx={{ width: '100%', wordWrap: 'break-word' }} />
            </FormControl>
            <FormControl variant="standard" sx={{ width: '100%' }}>
                <InputLabel>Subject</InputLabel>
                <Input id="component-simple" value={subject} onChange={handleSubjectInputChange} />
            </FormControl>
            <FormControl variant="standard" sx={{ width: '100%' }}>
                <InputLabel>Email Content</InputLabel>
                <Input id="component-simple" value={content} onChange={handleContentInputChange} multiline rows={5} />
            </FormControl>
            <Button onClick={handleSendButtonClick}>Send</Button>
        </Stack>
    );
}
