import React from "react";
import { User } from "../interfaces/User";
import { UserContextState } from "../interfaces/UserContextState";
import { QueueContext } from "./QueueContext";

export const UserContext = React.createContext<UserContextState>({
  loading: true,
  user: null,
  getUser: () => {
    return null;
  },
  saveUser: (user: User) => {},
  saveUserState: (user: User) => {},
  logout: () => {},
});

interface Props {
  children?: React.ReactNode;
}
export const UserProvider = ({ children }: Props) => {
  const [user, setUser] = React.useState<User | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  //const qCtx = React.useContext(QueueContext);

  React.useEffect(() => {
    const foundUser = getUser();
    setLoading(false);

    if (foundUser) {
      saveUserState(foundUser);
    }
  }, []);

  const getUser = () => {
    let user: User | null = null;

    try {
      let userFromStorage = localStorage.getItem("user");
      user = userFromStorage && JSON.parse(userFromStorage);
    } catch (error) {
      console.log(
        "Something bad happened while trying to get the user from local storage."
      );
      console.log(error);
    }

    return user;
  };

  const saveUser = (user: User) => {
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };

  const saveUserState = (user: User) => {
    setUser(user);
  };

  const logout = () => {
    localStorage.clear();
    //qCtx.closeEverything();
    setUser(null);
  };

  return (
    <UserContext.Provider
      value={{ loading, user, saveUserState, getUser, saveUser, logout }}
    >
      {children}
    </UserContext.Provider>
  );
};
