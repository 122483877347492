import React from "react";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Popover,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { getOrderIds } from "../services/orderService";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { compose } from "@mui/system";
import {
  matchOrderFile,
  reassignOrderFile,
  updateOrderFile,
} from "../services/orderFileService";
import { ToastContext } from "../contexts/ToastContext";

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ReassignImage2({
  imageInfo,
  files,
  setFiles,
  handleNextStep,
}: any) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const { permissions } = React.useContext(PermissionsContext);
  const [orderInfo, setOrderInfo] = React.useState<Array<any> | null>(null);
  const [accountList, setAccountList] = React.useState<string[]>([]);
  const [selectedImage, setSelectedImage] = React.useState<Array<any>>([]);
  const [selectedAccount, setSelectedAccount] = React.useState<string>(
    accountList[0] || ""
  );
  const [selectedOrder, setSelectedOrder] = React.useState<string>("");
  const [orderList, setOrderList] = React.useState(orderInfo);
  const { setAlertMessage, setExceptionMessage, setSuccessMessage } =
    React.useContext(ToastContext);

  React.useEffect(() => {
    if (!permissions?.primaryAccount) return;
    if (imageInfo.length === 1) {
      setSelectedImage(imageInfo[0].id);
    }
    getOrderIds(
      permissions.primaryAccount,
      (data: any) => {
        //console.log(data);
        setOrderInfo(data);
        let accountsFlat2 = Array.from(
          new Set(data.map((item: any) => item.account.name))
        ) as string[];
        setAccountList(accountsFlat2);
      },
      (err: any) => {
        console.log(err);
      }
    );
  }, []);

  //when the selected account changes, changes the selectable orders and clear previous selection
  React.useEffect(() => {
    if (!selectedAccount) return;
    const orders: any = [];
    orderInfo?.map((order) => {
      if (order.account.name === selectedAccount) {
        orders.push(order.id);
      } else {
        return;
      }
    });
    setSelectedOrder("");
    setOrderList(orders);
  }, [selectedAccount]);

  const handleSubmit = () => {
    if (imageInfo[0].unMatched) {
      console.log("unmatched order");
      matchOrderFile(
        imageInfo[0].id,
        selectedOrder,
        "1c164304-a020-4dab-888e-30f58096c023",
        (data: any) => {
          console.log(data);
          setSuccessMessage(
            `File: ${imageInfo[0].id} reassigned to order: ${selectedOrder}. Please refresh page or continue work on current approval.`
          );
          const newFiles = files.filter(
            (f: any) => f.fileId != imageInfo[0].id
          );
          setFiles(newFiles);
          handleClose();
        },
        (err: any) => {
          console.log("nope");
          console.log(err);
          setExceptionMessage(err);
        }
      );
    } else {
      console.log("updating order");
      reassignOrderFile(
        selectedImage,
        selectedOrder,
        (data: any) => {
          setSuccessMessage(
            `File: ${selectedImage} reassigned to order: ${selectedOrder}. Please refresh page or continue work on current approval.`
          );
          handleNextStep();
          handleClose();
        },
        (err: any) => {
          console.log(err);
          setExceptionMessage(err);
        }
      );
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Reassign Image
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="none"
        sx={{
          height: "60%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card>
          <CardHeader
            title="Please select image(s) to reassign"
            titleTypographyProps={{ textAlign: "center" }}
          />
          <CardContent sx={{ width: 500 }}>
            {/* image select, if more than one image */}
            {imageInfo.length > 1 ? (
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Images
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedImage}
                  onChange={(event: any) => {
                    setSelectedImage(event.target.value);
                  }}
                  input={<OutlinedInput label="Images" />}
                  renderValue={(selected) => {
                    return selected.join(", ");
                  }}
                  MenuProps={MenuProps}
                >
                  {imageInfo.map((image: any, index: any) => {
                    // console.log(selectedImage)
                    return (
                      <MenuItem key={index} value={image.id}>
                        <Checkbox
                          checked={selectedImage.indexOf(image.id) > -1}
                        />
                        <Paper
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            className="reassign_image"
                            src={image.url}
                            alt="image"
                          ></img>
                        </Paper>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : (
              <>
                <Typography
                  variant={"overline"}
                  paddingRight={5}
                  visibility={"visible"}
                  sx={{ fontSize: 12 }}
                >
                  <Typography>
                    <strong>Image ID: </strong>
                    {selectedImage}
                  </Typography>
                </Typography>
              </>
            )}

            {/* ONLY RENDER WHEN IMAGE SELECTED */}
            {/* SELECT ACCOUNT */}
            {selectedImage.length > 0 ? (
              <>
                <Typography sx={{ m: 1 }}>
                  Select Account to Reassign Image(s) To:
                </Typography>
                <Autocomplete
                  value={selectedAccount}
                  onChange={(event: any, newValue) => {
                    setSelectedAccount(newValue || "");
                  }}
                  options={accountList}
                  sx={{ width: 400, m: 1 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Account" />
                  )}
                />
              </>
            ) : (
              <></>
            )}
            {/* ONLY RENDER WHEN AN ACCOUNT SELECTED */}
            {/* SELECT ORDER  */}
            {selectedAccount.length > 0 ? (
              <>
                <Typography sx={{ m: 1 }}>
                  Select Order to Associate Image(s) To:
                </Typography>
                <Autocomplete
                  value={selectedOrder}
                  onChange={(event: any, newValue) => {
                    setSelectedOrder(newValue || "");
                  }}
                  options={(orderList || []) as readonly any[]}
                  sx={{ width: 400, m: 1 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Order: " />
                  )}
                />
              </>
            ) : (
              <></>
            )}
            {selectedOrder.length > 0 ? (
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button variant={"contained"} onClick={handleSubmit}>
                  Submit
                </Button>
              </Typography>
            ) : (
              <></>
            )}
          </CardContent>
        </Card>
      </Popover>
    </div>
  );
}
