import { atom } from "recoil";
import { Invoice } from "../interfaces/Invoice";
import { Order } from "../interfaces/Order";
import { QueueMessage } from "../interfaces/QueueMessage";
import { Shipment } from "../interfaces/Shipment";
import { Account } from "../interfaces/Account";
import { UserAccount } from "../interfaces/UserAccount";
import { User } from "../interfaces/User";
import { Notifications } from "../interfaces/Notifications";
import { Bill } from "../interfaces/Bill";

export const shipmentsAtom = atom({
  key: "shipments",
  default: [] as Shipment[],
});

export const ordersAtom = atom({
  key: "orders",
  default: [] as Order[],
});

export const queueMessagesAtom = atom({
  key: "queueMessages",
  default: {
    orderMessages: [] as QueueMessage[],
    shipmentMessages: [] as QueueMessage[],
    invoiceMessages: [] as QueueMessage[],
    orderFileMessages: [] as QueueMessage[],
    billMessages: [] as QueueMessage[],
  },
});

export const invoicesAtom = atom({
  key: "invoices",
  default: [] as Invoice[],
});

export const billsAtom = atom({
  key: "bills",
  default: [] as Bill[],
});

export const userAtom = atom({
  key: "user",
  default: [] as User[],
});

export const accountsAtom = atom({
  key: "accounts",
  default: [] as Account[],
});

export const userAccountsAtom = atom({
  key: "userAccounts",
  default: [] as UserAccount[],
});

export const notificationsAtom = atom({
  key: "notification",
  default: [] as Notifications[],
});

export const userHierarchyAtom = atom({
  key: "userHierarchy",
  default: [],
});

export const approvalNotificationAtom = atom({
  key: "approval",
  default: [],
});

export const reloadShipmentsScreen = atom({
  key: 'reloadShipmentsScreen',
  default: false,
});