import React from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { FormLabel } from '@mui/material';

interface Props {
    setPhoneNumber?: any,
    name: string,
    value: string,
    id?: string,
    disabled?: boolean,
    label?: string,
    country?: string,
    placeholder?:string
  }

  export default function PhoneNumberInput(props: Props) {
    const {setPhoneNumber, name, value, id='phoneInput', disabled=false, label, country, placeholder}= props;
    
    return (
        <>
          {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
          <PhoneInput
            value={value}
            onChange={(event, newPhone) => {
               setPhoneNumber(event)
              }}
            inputProps={{
              id: id,
              name: name,
              autoComplete: "off"
            }}
            country={country ?? "us"}
            preferredCountries={["us", "ca"]}
            placeholder={placeholder}
            disabled={disabled}
            buttonStyle={{
              backgroundColor: "#EEEEEE",
              border: "1px solid #d3d3d3"
            }}
            inputStyle={{
              borderRadius: "3px",
              height: "44px",
              width: "100%"
            }}
            copyNumbersOnly={false}
            enableSearch={true}
            disableSearchIcon={true}
          />
        </>
      );

  }