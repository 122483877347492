import React from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { UserContext } from "../contexts/UserContext";
import { getUser, updateUser } from "../services/userService";
import { useRecoilState } from "recoil";
import { userAtom } from "../atoms/atoms";

export const AccountProfileDetails = (props: any) => {
  const { user } = React.useContext(UserContext);
  const [userInfo, setUserInfo] = useRecoilState(userAtom);

  const [values, setValues] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    emailAddress: user?.emailAddress,
    phoneNumber: user?.userPhoneNumber,
  });
  //console.log(userCont.id)
  const loadUser = React.useCallback(() => {
    if (user == null) return;
    getUser(
      user?.id,
      (data: any) => {
        setUserInfo(data);
      },
      (ex: any) => {
        console.log(ex);
      }
    );
  }, []);

  const handleChange = (event: any) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleUpdateUser = () => {
    if (!user || !values) return;

    try {
      updateUser(user?.id, { userData: values }, (data: any) => {
        console.log(data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form autoComplete="off" noValidate>
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                onChange={handleChange}
                required
                value={values.phoneNumber}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                //required
                contentEditable={false}
                value={values.emailAddress}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleUpdateUser}
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};
