import { baseApi } from "./baseService";

const API = {
  updateInvoiceLine: "api/invoiceLine/update",
};

export const updateInvoiceLine = (
  id: string,
  description: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.updateInvoiceLine, { id, description })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};
