import { baseApi } from "./baseService";

const API = {
  getRating: "/api/note/getRating",
  submitRating: "/api/note/submitRating",
  addShipmentNote: "/api/note/addShipmentNote",
  updateRating: "/api/note/updateRating",
};

export const getRating = (
  childAccountId: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getRating, { childAccountId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const submitRating = (
  childAccountId: string,
  rating: number,
  onSuccess: Function,
  notes?: string,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.submitRating, { childAccountId, rating, notes })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const addShipmentNote = (
  shipmentId: string,
  notes: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.addShipmentNote, { shipmentId, notes })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

// export const updateRating = (
//   childAccountId: string,
//   rating: number,
//   onSuccess: Function,
//   onError?: Function
// ) => {
//   baseApi().then((api) =>
//     api
//       .post(API.updateRating, { childAccountId, rating })
//       .then(({ data }) => {
//         onSuccess(data);
//       })
//       .catch(({ response }) => {
//         console.error(response);
//         onError && onError(response);
//       })
//   );
// };
