import React from "react";
import { createTheme, styled, Theme } from "@mui/material/styles";

interface Props {
  children: React.ReactNode;
  status: string;
}

interface SeverityPillRootProps {
  theme: Theme;
  status: string;
}

const theme = createTheme();

export const SeverityPill = (props: Props) => {
  const { status, children } = props;

  const SeverityPillRoot = styled("span")(
    ({ theme, status }: SeverityPillRootProps) => {
      let color: any = {
        new: theme.palette["primary"].main,
        "needs attention": theme.palette["error"].main,
        active: theme.palette["success"].light,
        "ready to bill": theme.palette["success"].dark,
        "on hold": theme.palette["warning"].main,
        "ready for approval": theme.palette["secondary"].main,
        completed: theme.palette["info"].main,
        canceled: theme.palette.grey[600],
        delivered: theme.palette["info"].main,
      };

      const backgroundColor: any = color[status.toLowerCase()];

      const contrastColor: any = theme.palette.primary.contrastText;
      return {
        alignItems: "center",
        backgroundColor: backgroundColor,
        borderRadius: 12,
        color: contrastColor,
        cursor: "default",
        display: "inline-flex",
        flexGrow: 0,
        flexShrink: 0,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 2,
        fontWeight: 600,
        justifyContent: "center",
        letterSpacing: 0.5,
        minWidth: 20,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        textTransform: "uppercase",
        whiteSpace: "nowrap",
      };
    }
  );

  return (
    <SeverityPillRoot theme={theme} status={status}>
      {children}
    </SeverityPillRoot>
  );
};
