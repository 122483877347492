import React from 'react';
import { Avatar, Box, Button, Card, CardContent, CardHeader, Container, Divider, Grid, TextField, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { UserContext } from '../contexts/UserContext';
import { FileDetails } from '../interfaces/FileDetails';
import { useFormik } from 'formik';
import { updateUser, uploadImage } from '../services/userService';
import LoadingBlock from '../components/LoadingBlock';
import LoadingButton from '@mui/lab/LoadingButton';
import * as yup from "yup";

export interface ProfileFormValues {
  profilePic: FileDetails | null,
  firstName: string,
  lastName: string,
  emailAddress: string,
  phoneNumber: string,
}

export default function ProfileScreen() {
  //contexts
  const { user } = React.useContext(UserContext);
  //states
  const [loading, setLoading] = React.useState(false);
  const [profilePicLoading, setProfilePicLoading] = React.useState(false);
  const [profilePic, setProfilePic] = React.useState('');

  const formInitialValues: ProfileFormValues = {
    profilePic: null,
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    emailAddress: user?.emailAddress || '',
    phoneNumber: user?.userPhoneNumber || '',
  }

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: async (values) => {
      if (user == null) return;
      setLoading(true);

      //update image
      try {
        if (values.profilePic) {
          uploadImage(values.profilePic, user.id, (data: any) => {
            console.log(data);
          });
        }
      } catch (ex: any) {
        //toastCtx.setExceptionMessage(ex);
        console.log(ex);
      }

      //update user info (non-pic)
      try {
        var userValues = {
          firstName: values.firstName,
          lastName: values.lastName,
          emailAddress: values.emailAddress,
          phoneNumber: values.phoneNumber,
        }
        updateUser(user.id, { userData: userValues }, (data: any) => {
          console.log(data);
        });
      } catch (err) {
        console.log(err);
      }


      setLoading(false);
    },
  });

  React.useEffect(() => {
    if (!user) return;
    //console.log(user);
    setProfilePic(user.profilePicture);

  }, [user]);

  if (!user) return <LoadingBlock />;

  const SaveFormButton = () => {
    if (!formik.dirty) return null;

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <Divider /><LoadingButton
          type="submit"
          fullWidth
          loading={loading}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Save Changes
        </LoadingButton>
      </Box>
    );
  }

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      noValidate
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth="lg">
        <Typography
          sx={{ mb: 3 }}
          variant="h4"
        >
          {`${user?.firstName}'s Profile`}
        </Typography>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <Card>
              <CardContent>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Avatar
                    alt={`${user?.firstName} ${user?.lastName}`}
                    src={profilePic}
                    sx={{
                      height: 150,
                      mb: 2,
                      width: 150,
                    }}
                  />
                  <LoadingButton
                    size='small'
                    loading={profilePicLoading}
                    variant='outlined'
                    component='label'
                    startIcon={<EditIcon fontSize="small" />}
                  >
                    Edit
                    <input
                      name='profilePic'
                      accept='image/*'
                      id='profilePic'
                      type='file'
                      hidden
                      onChange={(e) => {
                        if (e?.target?.files == null) {
                          return;
                        }

                        setProfilePicLoading(true);

                        const fileReader = new FileReader();
                        var file = e.target.files[0];
                        var fileDetails: FileDetails = {
                          name: file.name,
                          size: file.size,
                          type: file.type
                        }
                        fileReader.onload = () => {
                          if (fileReader.readyState === 2 && fileReader?.result) {
                            fileDetails.data = fileReader.result;
                            formik.setFieldValue('profilePic', fileDetails);
                            setProfilePic(fileDetails.data.toString());
                            setProfilePicLoading(false);
                          }
                        };
                        fileReader.readAsDataURL(e.target.files[0]);
                      }}
                    />
                  </LoadingButton>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <Card>
              <CardHeader subheader="The information can be edited" title="Profile" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="First name"
                      name="firstName"
                      variant="outlined"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                      helperText={formik.touched.firstName && formik.errors.firstName}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Last name"
                      name="lastName"
                      variant="outlined"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                      helperText={formik.touched.lastName && formik.errors.lastName}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      name="phoneNumber"
                      variant="outlined"
                      value={formik.values.phoneNumber}
                      onChange={formik.handleChange}
                      error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                      helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Email Address"
                      name="email"
                      variant="outlined"
                      value={formik.values.emailAddress}
                      onChange={formik.handleChange}
                      error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                      helperText={formik.touched.emailAddress && formik.errors.emailAddress}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <SaveFormButton />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
};

const formValidationSchema = yup.object({
  emailAddress: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  firstName: yup
    .string()
    .required("First name is required"),
  lastName: yup
    .string()
    .required("Last name is required"),
  phoneNumber: yup
    .string()
    .required("Phone number is required"),
});