import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { UserContext } from "../contexts/UserContext";
import ShipmentChart from "../components/ShipmentChart.js";
import {
  notificationsAtom,
  queueMessagesAtom,
  shipmentsAtom,
  reloadShipmentsScreen,
} from "../atoms/atoms";
import { useRecoilState } from "recoil";
import {
  DataGridPro,
  DataGridProProps,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Shipment } from "../interfaces/Shipment";
import { SeverityPill } from "../components/SeverityPill";
import ShipmentImagePopover from "../components/ShipmentImagePopover";
import { getShipments } from "../services/shipmentService";
import { ToastContext } from "../contexts/ToastContext";
import LoadingBlock from "../components/LoadingBlock";
import UpdateShipmentStatus from "../components/UpdateShipmentStatus";
import EmailCarrier from "../components/EmailCarrier";
//import { ListenForQueueMessages } from "../services/QueueService";
import { QueueMessage } from "../interfaces/QueueMessage";
import { useParams } from "react-router-dom";
import { PermissionsContext } from "../contexts/PermissionsContext";
import ShipmentFilter from "../components/ShipmentFilter";
import { CardHeader, Divider } from "@mui/material";
import ShipmentImageUpload from "../components/ShipmentImageUpload";
import { QueueContext } from "../contexts/QueueContext";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

export default function ShipmentsScreen() {
  const { params } = useParams();
  const [reloadShipments, setReloadShipments] = useRecoilState(
    reloadShipmentsScreen
  );

  const { queueMessages } = React.useContext(QueueContext);
  const { user } = React.useContext(UserContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const { setExceptionMessage } = React.useContext(ToastContext);
  //const [shipments, setShipments] = useRecoilState(shipmentsAtom);
  const [shipments, setShipments] = React.useState<Array<Shipment>>([]);
  const { permissions } = React.useContext(PermissionsContext);
  const getDetailPanelContent: DataGridProProps["getDetailPanelContent"] =
    React.useCallback(({ row }: any) => <DetailPanelContent row={row} />, []);

  const loadShipments = React.useCallback(() => {
    if (!user) return;

    getShipments(
      !permissions?.hierarchy ? user.id : [...permissions?.hierarchy, user.id],
      (data: any) => {
        setShipments(data);
        setIsLoading(false);
      },
      (ex: any) => {
        setExceptionMessage(ex);
        setIsLoading(false);
      }
    );
  }, [user?.id]);

  console.log(shipments[0]);

  React.useEffect(() => {
    loadShipments();
  }, [
    queueMessages.ShipmentMessage,
    queueMessages.OrderFileMessage,
    user?.id,
    reloadShipments,
  ]);

  if (isLoading || !user?.id) return <LoadingBlock />;

  if (!user) {
    return (
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Loading
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: 1, height: 400 }}>
      <ShipmentFilter
        shipments={shipments}
        setShipments={setShipments}
        loadShipments={loadShipments}
        columns={columns}
      />
      <ShipmentChart
        params={params}
        user={user}
        shipments={shipments}
        setShipments={setShipments}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <DataGridPro
        sx={{ background: "white" }}
        density="compact"
        autoHeight
        columns={columns}
        rows={generateRows(shipments)}
        //rows={rowGenerator(shipments)}
        getDetailPanelContent={DetailPanelContent}
        components={{ Toolbar: GridToolbar }}
      />
    </Box>
  );
}

function DetailPanelContent({ row }: any) {
  switch (row.status) {
    case "1":
      row.status = "New";
      break;
    case "2":
      row.status = "Active";
      break;
    case "3":
      row.status = "Needs Attention";
      break;
    case "4":
      row.status = "On Hold";
      break;
    case "5":
      row.status = "Ready To Bill";
      break;
    case "6":
      row.status = "Completed";
      break;
    case "7":
      row.status = "Canceled";
      break;
    case "8":
      row.status = "Ready for Approval";
      break;
    case "9":
      row.status = "Delivered";
      break;
  }

  return (
    <Stack
      sx={{ py: 2, height: 1, boxSizing: "border-box" }}
      direction="column"
    >
      <Paper sx={{ flex: 1, mx: "auto", width: "98%", p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Typography variant="h6">{`Shipment #${row.shipmentNumber}`}</Typography>
          <Grid container justifyContent={"space-between"}>
            {/* <Stack direction={"row"} alignItems={"center"}> */}
            <Grid item md={"auto"}>
              <Typography
                variant="body1"
                align="left"
                sx={{ textDecoration: "underline" }}
                pb={1}
              >
                Origin
              </Typography>
              <Typography variant="body2" align="left" color="textSecondary">
                {row.origin.address}
              </Typography>
              <Typography variant="body2" align="left" color="textSecondary">
                {row.origin.city}, {row.origin.stateShort}
              </Typography>
              <Typography variant="overline" sx={{ fontStyle: "oblique" }}>
                {row.date}
              </Typography>
              <Typography variant="body1">{row.email}</Typography>
            </Grid>
            {/* <Stack
                direction={"row"}
                paddingLeft={4}
                paddingRight={4}
                alignItems={"center"}
              >
                <Divider orientation="vertical" />
                <Stack alignItems={"center"}>
                  <Typography
                    variant="overline"
                    padding={1}
                    sx={{ textDecoration: "underline" }}
                  >
                    To
                  </Typography>
                  <Typography variant="overline" sx={{ fontStyle: "oblique" }}>
                    {row.date}
                  </Typography>
                </Stack>

                <Divider orientation="vertical" />
              </Stack> */}
            <Grid item md={"auto"} alignItems={"center"} pt={2}>
              <DoubleArrowIcon fontSize="large" />
            </Grid>
            <Grid item md={"auto"}>
              <Typography
                variant="body1"
                sx={{ textDecoration: "underline" }}
                pb={1}
              >
                Destination
              </Typography>
              <Typography variant="body2" align="left" color="textSecondary">
                {row.destination.address}
              </Typography>
              <Typography variant="body2" align="left" color="textSecondary">
                {row.destination.city}, {row.destination.stateShort}
              </Typography>
              {/* <Typography variant="body2">{row.date}</Typography> */}
              <Typography variant="body1">{row.email}</Typography>
            </Grid>
            {/* </Stack> */}

            <Grid item md={"auto"}>
              <Typography variant="body2" align="right">
                Shipping Information
              </Typography>
              <Typography variant="body1" align="right"></Typography>
              <Typography component={"span"} variant="body1" align="right">
                <UpdateShipmentStatus row={row} />
              </Typography>
              <Typography variant="body1" align="right">
                {`${row.dispatcher}`}
              </Typography>
            </Grid>
          </Grid>
          <DataGridPro
            density="compact"
            columns={popDownColumns}
            rows={generatePopDown(row)}
            sx={{ flex: 1 }}
            hideFooter
            autoHeight
          />
          {row.image ? (
            <Typography display={"flex"}>
              {/* <EmailCarrier row={row} /> */}
              <ShipmentImageUpload row={row} />
            </Typography>
          ) : (
            <EmailCarrier row={row} />
          )}
        </Stack>
      </Paper>
    </Stack>
  );
}

DetailPanelContent.propTypes = {
  row: PropTypes.object.isRequired,
};

const columns: GridColDef[] = [
  { field: "shipmentNumber", headerName: "Shipment ID", flex: 1 },
  { field: "dispatcher", headerName: "Dispatcher", flex: 1 },
  { field: "carrier", headerName: "Carrier", flex: 1 },
  { field: "date", headerName: "Placed at", flex: 1 },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: ({ row }) => (
      <SeverityPill status={row.status}>{row.status}</SeverityPill>
    ),
  },
  { field: "total", headerName: "Total", flex: 1 },
  { field: "reason", headerName: "Reason Code", flex: 1 },
];

interface renderCell {
  renderCell: any;
}

interface PopDownColumns extends Partial<renderCell> {
  headerName: string;
  field: string;
  flex: number;
}

const popDownColumns: PopDownColumns[] = [
  { field: "id", headerName: "Order Number", flex: 1 },
  { field: "customer", headerName: "Customer", flex: 1 },
  { field: "address", headerName: "Address", flex: 1 },
  { field: "carrier", headerName: "Carrier", flex: 1 },
  { field: "carrierDispatcher", headerName: "Carrier Dispatcher", flex: 1 },
  { field: "reasonCode", headerName: "Reason Code", flex: 1 },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: ({ row }: any) => (
      <SeverityPill status={row.status}>{row.status}</SeverityPill>
    ),
  },
  {
    field: "image",
    headerName: "Image",
    flex: 1.5,
    renderCell: ({ row }: any) => (
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <ShipmentImagePopover file={row.image} row={row} />
        </Grid>
        <Grid item xs={6}>
          <ShipmentImageUpload row={row} />
        </Grid>
      </Grid>
    ),
  },
  { field: "totalAmt", headerName: "Total Amount", flex: 1 },
];

interface ShipmentRow {
  id: any;
  shipmentNumber: string;
  dispatcher: string;
  date: string;
  status: number;
  total: any;
  reason: string;
  //image?: Array<any>;
  origin: any;
  destination: any;
  carrierDispatcher: any;
  user: any;
  orders: Array<any>;
  carrier: any;
}

const generateRows = (shipments: Array<Shipment>) => {
  var rows: Array<ShipmentRow> = [];
  var id = 1;
  //let filteredShipments = shipments.filter((ship) => ship.orders.length > 0);
  //filteredShipments.map((ship: Shipment, index: number) => {
  shipments.map((ship: Shipment, index: number) => {
    const invoices: any = [];
    ship.orders.forEach((ord: any) => {
      ord.invoices.forEach((inv: any) => {
        invoices.push(inv.totalAmt);
      });
    });
    const totalInvoiced = invoices.reduce((a: any, b: any) => a + b, 0);
    //console.log();
    const row: ShipmentRow = {
      id: ship.id,
      shipmentNumber: ship.shipmentNumber,
      dispatcher: `${ship.dispatcher.lastName}, ${ship.dispatcher.firstName}`,
      date: new Date(ship.createdAt).toLocaleDateString("en-us"),
      status: ship.statusId,
      total: `$${totalInvoiced ? totalInvoiced.toFixed(2) : 0}`,
      reason: ship.reasonCode.description,
      origin: ship.originLocation,
      destination: ship.destinationLocation,
      carrierDispatcher: ship.dispatcher,
      user: ship.user,
      orders: ship.orders,
      carrier: ship.account.name,
    };
    rows.push(row);
    return ship;
  });
  //console.log(rows)
  return rows;
};

interface PopDownRows {
  id: any;
  orderId: string;
  customer: string;
  originAddress: string;
  destinationAddress: string;
  carrier: string;
  carrierDispatcher: string;
  reasonCode: string;
  status: string;
  image: Array<any>;
  totalAmt: any;
}

const generatePopDown = (row: ShipmentRow) => {
  var popRows: Array<PopDownRows> = [];
  var orders = row.orders;
  //console.log(row);

  if (orders.length > 0) {
    orders.forEach((ord) => {
      const files = ord.files.map((f: any) => {
        const newObj = {
          file: f.file,
          matchScore: f.orderFile.matchScore,
        };
        return newObj;
      });
      const amount = ord.invoices.reduce((a: any, b: any) => a + b.totalAmt, 0);
      const originAddress = `${row.origin.address}, ${row.origin.city}, ${row.origin.stateShort} ${row.origin.zip5}`;
      const destinationAddress = `${row.destination.address}, ${row.destination.city}, ${row.destination.stateShort} ${row.destination.zip5}`;
      popRows.push({
        id: ord.orderNumber,
        orderId: ord.id,
        customer: ord.account.name,
        originAddress: originAddress,
        destinationAddress: destinationAddress,
        carrier: row.carrier,
        carrierDispatcher: `${row.carrierDispatcher.lastName}, ${row.carrierDispatcher.firstName}`,
        reasonCode: ord.reasonCode.description,
        status: ord.status.description,
        image: files,
        totalAmt: `$${amount}`,
      });
    });
  } else {
    popRows.push({
      id: "Unknown",
      orderId: "Unknown",
      customer: "Unknown",
      originAddress: "Unknown",
      destinationAddress: "Unknown",
      carrier: "Unknown",
      carrierDispatcher: "Unknown",
      reasonCode: "Unknown",
      status: "Unknown",
      image: [],
      totalAmt: "Unknown",
    });
  }
  return popRows;
};
