import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useRecoilState } from "recoil";
import {
  GridColDef,
  DataGridPro,
  GridRowParams,
  GridRowHeightParams,
} from "@mui/x-data-grid-pro";
import LoadingBlock from "./LoadingBlock";
import { UserContext } from "../contexts/UserContext";
import { ToastContext } from "../contexts/ToastContext";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { QueueContext } from "../contexts/QueueContext";
import { getOrdersApprovalsPage } from "../services/orderService";
import { Order } from "../interfaces/Order";
import { notificationsAtom, ordersAtom } from "../atoms/atoms";
import { GridRowId } from "@mui/x-data-grid";
import { Button, Paper } from "@mui/material";
import ApprovalCardActions from "./ApprovalCardActions";
import { GenerateInvoicePDF } from "./CreateInvoicePDF";
import MarkAsReadyToBill from "./MarkAsReadyToBill";
import { SeverityPill } from "./SeverityPill";
import ApproveAllOrders from "./ApproveAllOrders";

const statusDict: any = {
  1: "New",
  2: "Active",
  3: "Needs Attention",
  4: "On Hold",
  5: "Ready To Bill",
  6: "Completed",
  7: "Canceled",
  8: "Ready for Approval",
  9: "Delivered",
};

export default function ApprovalsGrid({ filter }: any) {
  const { params } = useParams();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const { setExceptionMessage } = useContext(ToastContext);
  const { queueMessages } = React.useContext(QueueContext);
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useRecoilState(ordersAtom);
  const { permissions, getPermissions } = useContext(PermissionsContext);
  const [selectedRowIds, setSelectedRowIds] = React.useState<string[]>([]);

  const loadOrders = () => {
    if (!user) return;

    getOrdersApprovalsPage(
      !permissions?.hierarchy ? user.id : [...permissions?.hierarchy, user.id],
      (data: any) => {
        setOrders(data);
        setIsLoading(false);
      },
      (ex: any) => {
        setExceptionMessage(ex);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    loadOrders();
  }, [queueMessages.OrderMessage, queueMessages.OrderFileMessage]);

  const handleSelectionModelChange = React.useCallback(
    (selectionModel: GridRowId[], details: any) => {
      setSelectedRowIds(selectionModel as string[]);
    },
    []
  );

  if (isLoading || !user?.id) return <LoadingBlock />;

  if (!user) {
    return (
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Loading
        </Typography>
      </Box>
    );
  }

  const columns: GridColDef[] = [
    { field: "id", headerName: "Shipment Number", flex: 1 },
    { field: "orderNumber", headerName: "Order Number", flex: 1 },
    { field: "customer", headerName: "Customer", flex: 1 },
    { field: "reasonCode", headerName: "Reason Code", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1.75,
      renderCell: ({ row }: any) => (
        <SeverityPill status={row.status}>{row.status}</SeverityPill>
      ),
    },
    { field: "totalAmt", headerName: "Order Amount", flex: 1 },
    {
      field: "notes",
      headerName: "Actions",
      flex: 3,
      renderCell: ({ row }: any) => {
        if (filter === "approve") {
          return <ApprovalCardActions loadOrders={loadOrders} cardInfo={row} />;
        }
        if (filter === "ready") {
          return <GenerateInvoicePDF row={row} />;
        } else {
          return (
            <ApprovalCardActions
              loadOrders={loadOrders}
              cardInfo={row}
              filter={filter}
            />
          );
        }
      },
    },
    {
      field: "image",
      headerName: "Mark As Ready",
      flex: 2,
      renderCell: ({ row }: any) => (
        <MarkAsReadyToBill row={row} loadOrders={loadOrders} />
      ),
    },
    { field: "updatedAt", headerName: "Delivered Date", flex: 1 },
  ];

  const filteredColumns = columns.filter((column) => {
    //conditionally rendering actions column
    if (column.headerName === "Mark As Ready" && filter !== "rejected") {
      return false;
    }
    return true;
  });

  //passing the filter param to the row generator.
  const filteredRows = generateOrders(orders, filter);

  const getRowHeight = (params: GridRowHeightParams) => {
    // changes the height of each row
    return 75;
  };

  return (
    <Paper style={{ height: 400, width: "100%" }}>
      <DataGridPro
        checkboxSelection
        onSelectionModelChange={handleSelectionModelChange}
        selectionModel={selectedRowIds}
        sx={{ background: "white" }}
        density="compact"
        getRowHeight={getRowHeight}
        columns={filteredColumns}
        rows={filteredRows}
        autoHeight
      />
      {filter === "approve" ? (
        <ApproveAllOrders
          selectedRowIds={selectedRowIds}
          loadOrders={loadOrders}
        />
      ) : null}
    </Paper>
  );
}

interface OrderRow {
  id: any;
  orderNumber: string;
  customer: string;
  reasonCode: string;
  status: string;
  image: Array<any>;
  fileIds: Array<any>;
  totalAmt: any;
  notes: any;
  shipmentNumber: any;
  dispatcherEmail: any;
  driverPhone: any;
  shipment: any;
  createdAt: any;
  updatedAt: any;
}

//filling rows
const generateOrders = (orders: Array<Order>, filter: string) => {
  return orders.reduce((filteredRows: OrderRow[], ord: Order) => {
    const shipmentNumbers = ord.shipments.map((ship) => ship.shipmentNumber);
    const files = ord.files.map((f: any) => ({
      file: f.file,
      matchScore: f.orderFile.matchScore,
    }));
    const fileIds = ord.files.map((f: any) => f.id);
    const amount = ord.invoices.reduce((a: any, b: any) => a + b.totalAmt, 0);

    const row: OrderRow = {
      id: ord.id,
      orderNumber: ord.orderNumber,
      customer: ord.account.name,
      reasonCode: ord.reasonCode.description,
      status: statusDict[ord.statusId],
      image: files,
      fileIds: fileIds,
      totalAmt: `$${amount}`,
      notes: ord.notes,
      shipmentNumber: shipmentNumbers,
      dispatcherEmail: ord.shipments[0].user.emailAddress,
      driverPhone: ord.shipments[0].driverPhone,
      shipment: ord.shipments,
      createdAt: new Date(ord.createdAt).toDateString(),
      updatedAt: new Date(ord.updatedAt).toDateString(),
    };

    switch (filter) {
      case "approve":
        if (row.status === "Ready for Approval") {
          filteredRows.push(row);
        }
        break;
      case "ready":
        if (row.status === "Ready To Bill") {
          filteredRows.push(row);
        }
        break;
      case "rejected":
        if (row.status === "On Hold" || row.status === "Needs Attention") {
          filteredRows.push(row);
        }
        break;
      default:
        filteredRows.push(row); // Include all rows if filter is not recognized
    }

    return filteredRows;
  }, []);
};
