import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Paper, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import AutocompleteAddresses from "./AutocompleteAddresses";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { Autocomplete } from "@mui/material";
import { UserContext } from "../contexts/UserContext";

interface Props {
  panTo: any;
  location: any;
  setLocation: any;
  setMapShipments: any;
}

export default function SearchByLocationRadius(props: Props) {
  const { panTo, setMapShipments, location, setLocation } = props;
  const [loading, setLoading] = React.useState(false);
  const [address, setAddress] = useState("");
  const [miles, setMiles] = useState(location.miles || 0);

  const {
    //value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const getSuggestions = () => {
    return data.map((suggestion) => suggestion.description);
  }

  const handleAddressChange = async (address: any) => {
    if (!address) return;

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);

      const { address_components } = results[0];
      let street, city, state, zip5;

      address_components.forEach((ad) => {
        switch (ad.types[0]) {
          case "route":
            street = ad.long_name;
            break;
          case "locality":
            city = ad.long_name;
            break;
          case "administrative_area_level_1":
            state = ad.short_name;
            break;
          case "postal_code":
            zip5 = ad.short_name;
            break;
          default:
            break;
        }
      });
      const newOrigin = { ...location };
      newOrigin.city = city;
      newOrigin.state = state;
      newOrigin.centerCircle = { lat, lng };
      setLocation(newOrigin);
      panTo({ lat, lng });
    }
    catch (error) {
      console.log(error);
    }
  };

  function handleClear() {
    const newOrigin = { ...location };
    newOrigin.city = "";
    newOrigin.state = "";
    newOrigin.miles = 0;
    setLocation(newOrigin);
    setMiles(0);
    setAddress('');
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const newState = { ...location };
    newState.miles = miles;
    setLocation(newState);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <Paper sx={{ display: "flex" }}>
        <Autocomplete
         freeSolo={true}
          disablePortal
          id="address"
          options={getSuggestions()}
          sx={{ width: "100%" }}
          inputValue={address}
          onInputChange={(event, newInputValue) => {
            setValue(newInputValue);
            setAddress(newInputValue);
          }}
          onChange={(_, selectedAddress) => {
            clearSuggestions();
            handleAddressChange(selectedAddress);
          }}
          renderInput={(params) => {
            return <TextField {...params} label={"Address"} value={address} />;
          }}
        />
        <Button onClick={() => handleClear()} sx={{ m: 1 }}>
          Clear
        </Button>
      </Paper>
      <TextField
        type="number"
        id="miles"
        name="miles"
        label="Miles"
        placeholder="50"
        margin="normal"
        fullWidth
        value={miles}
        onChange={(e) => setMiles(e.target.value)}
      />
      <LoadingButton
        type="submit"
        fullWidth
        loading={loading}
        variant="contained"
        sx={{ mt: 1, mb: 1 }}
        endIcon={<SearchIcon />}
      >
        Search
      </LoadingButton>
    </Box>
  );
}
