import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import axios from "axios";
import Copyright from "../components/Copyright";
import { useFormik } from "formik";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { ToastContext } from "../contexts/ToastContext";
import { resetPassword } from "../services/emailService";

const theme = createTheme();

export default function ResetPasswordScreen() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [loading, setLoading] = React.useState(false);
  const toastCtx = React.useContext(ToastContext);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        resetPassword(
          token,
          values.password,
          (data: any) => {
            setLoading(false);
            toastCtx.setSuccessMessage(
              "Your password has been reset. Please log in."
            );
            navigate("/");
          },
          (err: any) => {
            setLoading(false);
            toastCtx.setExceptionMessage(err);
          }
        );
        // setLoading(false);
        // toastCtx.setSuccessMessage('Your password has been reset. Please log in.');
        // navigate('/');
      } catch (ex: any) {
        setLoading(false);
        toastCtx.setExceptionMessage(ex);
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              type="password"
              id="password"
              name="password"
              label="Password"
              placeholder="Make it super saiyan strong."
              margin="normal"
              fullWidth
              autoFocus
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <TextField
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              placeholder="Make sure it matches."
              margin="normal"
              fullWidth
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            />

            <LoadingButton
              type="submit"
              fullWidth
              loading={loading}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

const validationSchema = yup.object({
  password: yup
    .string()
    .min(7, "Password should be of minimum 7 characters length")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Password confirmation is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
