import React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Popover,
  Typography,
} from "@mui/material";
import { UserContext } from "../contexts/UserContext";
import axios from "axios";
import ImageUpload from "./ImageUpload";
import { getInitials } from "../utils/getInitials";

export const AccountProfile = (props: any) => {
  const { user } = React.useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //console.log(user);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;


  return (
    <Card {...props}>
      <CardContent>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            // src={picture}
            sx={{
              height: 64,
              mb: 2,
              width: 64,
            }}
          >
            {getInitials(`${user?.firstName} ${user?.lastName}`)}
          </Avatar>
          <Typography color="textPrimary" gutterBottom variant="h5">
            {`${user?.firstName} ${user?.lastName}`}
          </Typography>
          <Typography color="textSecondary" variant="body2"></Typography>
          <Typography color="textSecondary" variant="body2"></Typography>
        </Box>
      </CardContent>
      <Divider />
      {/* <CardActions>
        <Button
          aria-describedby={id}
          variant="text"
          fullWidth
          color="primary"
          onClick={handleClick}
        >
          Open Popover
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <ImageUpload />
        </Popover>
      </CardActions> */}
    </Card>
  );
};
