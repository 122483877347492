import { baseApi } from "./baseService";

const API = {
  forgotPassword: "/api/email/forgotPassword",
  resetPassword: "/api/email/resetPassword/",
  requestAccount: "/api/email/requestAccount",
  emailCarrier: "/api/email/emailCarrier",
  emailNewUser: "/api/email/emailNewUser",
  massEmailFromMap: "/api/email/massEmailFromMap",
};

export const forgotPassword = (
  emailAddress: string | undefined,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.forgotPassword, { emailAddress })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        // console.log(response);
        onError && onError(response);
      })
  );
};

export const resetPassword = (
  token: string | undefined,
  password: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.resetPassword, {
        token,
        password,
      })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        //console.log(response);
        onError && onError(response);
      })
  );
};

export const emailCarrier = (
  emailAddress: string,
  subject: string,
  body: string,
  shipment: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.emailCarrier, { emailAddress, subject, body, shipment })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        //console.log(response);
        onError && onError(response);
      })
  );
};

// export const emailNewUser = (
//   settingId: string | undefined,
//   value: string | undefined,
//   onSuccess: Function,
//   onError?: Function
// ) => {
//   baseApi().then((api) =>
//     api
//       .post(API.emailNewUser, { settingId, value })
//       .then(({ data }) => {
//         onSuccess(data);
//       })
//       .catch(({ response }) => {
//         //console.log(response);
//         onError && onError(response);
//       })
//   );
// };

export const requestAccount = (
  emailAddress: string,
  firstName: string,
  lastName: string,
  question: string,
  phone: string,
  promo: string,
  address: any,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.requestAccount, {
        emailAddress,
        firstName,
        lastName,
        question,
        phone,
        promo,
        address,
      })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        //console.log(response);
        onError && onError(response);
      })
  );
};

export const massEmailFromMap = (
  emails: string[],
  subject: string,
  content: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.massEmailFromMap, {
        emails,
        subject,
        content
      })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        //console.log(response);
        onError && onError(response);
      })
  );
}