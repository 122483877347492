import { baseApi } from "./baseService";

const API = {
  getBills: "/api/bill/user",
  getBillPage: "/api/bill/page",
  updateBill: "api/bill/update",
  generateBillPDF: "api/bill/generatePDF",
};

export const getBills = (
  userId: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getBills, { userId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getBillPage = (
  userId: string | Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getBillPage, { userId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const updateBill = (
  billId: string,
  //Add whatever needs to be updated here,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.updateBill, { billId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const generateBillPDF = (
  orderId: string,
  orderNumber: any,
  image: Array<any>,
  shipmentNumber: Array<any>,
  customer: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(
        API.generateBillPDF,
        { orderId, orderNumber, image, shipmentNumber, customer },
        { responseType: "blob" }
      )
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};
