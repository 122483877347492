import { baseApi } from "./baseService";

const API = {
  getTermsOptions: "/api/terms/options",
  getAccountTerms: "/api/terms/account",
  createNewTerm: "/api/terms/create",
  editTerm: "/api/terms/update",
};

export const getTermsOptions = (onSuccess: Function, onError?: Function) => {
  baseApi().then((api) =>
    api
      .get(API.getTermsOptions)
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        // console.log(response);
        onError && onError(response);
      })
  );
};

export const getAccountTerms = (
  accountId: string,
  termTypeId: string | null,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getAccountTerms, { accountId, termTypeId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        // console.log(response);
        onError && onError(response);
      })
  );
};

export const createNewTerm = (
  accountId: string,
  termTypeId: string,
  name: string,
  value: string,
  description: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.createNewTerm, {
        accountId,
        termTypeId,
        name,
        value,
        description,
      })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        // console.log(response);
        onError && onError(response);
      })
  );
};

export const editTerms = (
  accountId: string,
  termTypeId: string,
  name: string,
  value: string,
  description: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.editTerm, {
        accountId,
        termTypeId,
        name,
        value,
        description,
      })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        // console.log(response);
        onError && onError(response);
      })
  );
};
