import React from "react";
import {
  Card,
  Paper,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  CardContent,
  Typography,
  TextField,
  Box,
  Autocomplete,
  Input,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRecoilState } from "recoil";
import { shipmentsAtom } from "../atoms/atoms";
import CloseIcon from "@mui/icons-material/Close";
import { getOrdersFromShipments } from "../services/orderShipmentService";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { getShipmentsFiltered } from "../services/shipmentService";
import { UserContext } from "../contexts/UserContext";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { ToastContext } from "../contexts/ToastContext";

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  fontFamily: "inherit",
  alignContent: "center",
  fontSize: 18,
  fontStyle: "oblique",
  color: "GrayText",
}));

export default function ShipmentFilter({
  shipments,
  setShipments,
  loadShipments,
  columns,
}: any) {
  const { user } = React.useContext(UserContext);
  const { permissions } = React.useContext(PermissionsContext);
  const { setAlertMessage } = React.useContext(ToastContext);
  const [columnList, setColumnList] = React.useState<any>([]);
  const [columnSelect, setColumnSelect] = React.useState<any>();
  const [operator, setOperator] = React.useState<any>([]);
  const [operatorSelect, setOperatorSelect] = React.useState<any>();
  const [valueInput, setValueInput] = React.useState<any>();
  const [dateValue, setDateValue] = React.useState<any>([null, null]);
  const [text, setText] = React.useState<any>(false);

  const operators = ["=", ">", "<", "Not Equals"];
  const reasonCodes = [
    { id: 1, value: "N/A" },
    { id: 2, value: "Missing BOL" },
    { id: 3, value: "Illegible BOL" },
    { id: 4, value: "Claim" },
    { id: 5, value: "Wrong Image" },
  ];
  const statusMap = [
    { id: 1, value: "New" },
    { id: 2, value: "Active" },
    { id: 3, value: "Needs Attention" },
    { id: 4, value: "On Hold" },
    { id: 5, value: "Ready To Bill" },
    { id: 6, value: "Completed" },
    { id: 7, value: "Canceled" },
    { id: 8, value: "Ready for Approval" },
    { id: 9, value: "Delivered" },
  ];
  //If they select reason code, make it a dropdown selector
  //If they select date, make it a date picker popup

  React.useEffect(() => {
    if (!columns) return;
    const cList = columns.map((c: any) => c.headerName);
    setColumnList(cList);
    if (columnSelect) {
      if (
        columnSelect == "Reason Code" ||
        columnSelect == "Shipment ID" ||
        columnSelect == "Status"
      ) {
        setOperator(["=", "Not Equals"]);
      } else if (columnSelect == "Dispatcher" || columnSelect == "Carrier") {
        setOperator(["Contains"]);
      } else if (columnSelect == "Placed at") {
        setOperator(["Between"]);
      } else {
        setOperator(operators);
      }
    }
  }, [columnSelect]);

  const Xout = () => {
    setColumnSelect(undefined);
    setOperatorSelect(undefined);
    setValueInput(undefined);
    setDateValue([null, null]);
    setText(false);
    loadShipments();
  };

  const handleDateSelect = (newValue: any) => {
    console.log(newValue);
    setDateValue(newValue);
    const fromDate = new Date(newValue[0].$d);
    const toDate = new Date(newValue[1].$d);
    setValueInput([fromDate, toDate]);
  };

  const handleSearch = () => {
    if (!user) return;
    if (!columnSelect || !operatorSelect || !valueInput)
      return setAlertMessage({
        severity: "warning",
        message: "Please fill out all filter options.",
      });
    setText(true);
    getShipmentsFiltered(
      !permissions?.hierarchy ? user.id : [...permissions?.hierarchy, user.id],
      columnSelect,
      operatorSelect,
      valueInput,
      (data: any) => {
        //console.log(data);
        setShipments(data);
      },
      (err: any) => {
        console.log(err);
      }
    );
  };

  return (
    <Section sx={{ padding: .5, flex: "1" }}>
      Filters
      <Grid item display="flex">
        <Button onClick={Xout}>
          <CloseIcon />
        </Button>
        <Card sx={{ p: 1, m: 1, width: "25%" }}>
          <FormControl fullWidth>
            <InputLabel id="column">Column</InputLabel>
            <Select
              labelId="column-select-label"
              id="column-select"
              value={columnSelect || ""}
              onChange={(e) => setColumnSelect(e.target.value)}
              //disabled={columnSelect}
              autoWidth
              label="Column"
              sx={{ backgroundColor: "whitesmoke" }}
            >
              <MenuItem value={""}>
                <em>None</em>
              </MenuItem>
              {columnList.map((dis: any, index: number) => (
                <MenuItem key={index} value={dis}>
                  {dis}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Card>
        <Card sx={{ p: 1, m: 1, width: "25%" }}>
          <FormControl fullWidth>
            <InputLabel id="operator">Operator</InputLabel>
            <Select
              labelId="operator-select-label"
              id="operator-select"
              value={operatorSelect || ""}
              onChange={(e) => setOperatorSelect(e.target.value)}
              autoWidth
              //disabled={operatorSelect}
              label="Operator"
              sx={{ backgroundColor: "whitesmoke" }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {operator.map((car: any, index: any) => (
                <MenuItem key={index} value={car}>
                  {car}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Card>

        <Card sx={{ p: 1, m: 1, width: "25%" }}>
          {columnSelect == "Reason Code" ? (
            <FormControl fullWidth>
              <InputLabel id="reason">Selection</InputLabel>
              <Select
                labelId="reason-select-label"
                id="reason-select"
                value={valueInput || ""}
                onChange={(e) => setValueInput(e.target.value)}
                autoWidth
                // disabled={valueInput}
                label="Reason Code"
                sx={{ backgroundColor: "whitesmoke" }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {reasonCodes.map((s: any, index: any) => (
                  <MenuItem key={index} value={s.id}>
                    {s.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : columnSelect == "Status" ? (
            <FormControl fullWidth>
              <InputLabel id="status">Selection</InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                value={valueInput || ""}
                onChange={(e) => setValueInput(e.target.value)}
                autoWidth
                //disabled={valueInput}
                label="Status"
                sx={{ backgroundColor: "whitesmoke" }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {statusMap.map((s: any, index: any) => (
                  <MenuItem key={index} value={s.id}>
                    {s.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : columnSelect == "Placed at" ? (
            <Stack direction={"row"} justifyContent="center" spacing={2}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={{ start: "Start Date", end: "End Date" }}
              >
                <DateRangePicker
                  value={dateValue}
                  // onChange={(newValue) => {
                  //   setValue(newValue);
                  // }}
                  //disabled={disable}
                  onChange={handleDateSelect}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField
                        {...startProps}
                        sx={{ background: "whitesmoke" }}
                        variant="outlined"
                      />
                      <Box sx={{ mx: 1 }}> to </Box>
                      <TextField
                        {...endProps}
                        sx={{ background: "whitesmoke" }}
                        variant="outlined"
                      />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
            </Stack>
          ) : (
            <FormControl fullWidth sx={{ pt: 1.25 }}>
              <Input
                placeholder="Search..."
                value={valueInput || ""}
                onChange={(e) => setValueInput(e.target.value)}
              />
            </FormControl>
          )}
        </Card>
        <Button sx={{ pl: 2 }} onClick={handleSearch}>
          Submit
        </Button>
        <Button onClick={Xout}>Clear</Button>
      </Grid>
      {text && (
        <Box display={"flex"} justifyContent={"center"}>
          <Typography>{`${columnSelect} ${operatorSelect} '${valueInput}'`}</Typography>
        </Box>
      )}
    </Section>
  );
}
