import * as React from "react";
// import Avatar from '@mui/material/Avatar';
// import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router";
// import axios from 'axios';
// import Copyright from '../components/Copyright';
// import LoadingButton from '@mui/lab/LoadingButton';
// import { ToastContext } from '../contexts/ToastContext';
// import { useFormik } from 'formik';
// import * as yup from 'yup';
// import AutocompleteAddresses from '../components/AutocompleteAddresses';
// import { useLoadScript } from '@react-google-maps/api';
// import PhoneNumberInput from '../components/PhoneNumberInput';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { getAccount } from "../services/accountService";
//const theme = createTheme();

export default function AdminScreen() {
  const navigate = useNavigate();
  const { permissions } = React.useContext(PermissionsContext);
  const [primaryAccountName, setPrimaryAccountName] = React.useState("");
  //console.log(permissions?.adminAccounts);

  React.useEffect(() => {
    if (!permissions) return;

    getAccount(
      permissions.primaryAccount,
      (data: any) => {
        //console.log(data.name);
        setPrimaryAccountName(data.name);
      },
      (err: any) => {
        console.log(err);
      }
    );
  }, []);

  return (
    <>
      <Paper>
        <Stack direction={"column"} alignItems={"center"}>
          <Typography variant="h5">Admin Options:</Typography>
          <Divider></Divider>
          <Button onClick={() => navigate("/Admin/Users")}>User Options</Button>
          <Button onClick={() => navigate("/Admin/Account")}>
            {primaryAccountName
              ? `${primaryAccountName} Options`
              : "Primary Account Options"}
          </Button>
          <Button onClick={() => navigate("/Admin/Terms")}>
            Edit Customer Terms
          </Button>
        </Stack>
      </Paper>
    </>
  );
  /*
Functionality:
- Check if user is local admin and block if not
- Ability to add in users by sending an email 
    - Need an email box
    - email link needs to have accountId as a param so we can associate userAccount 
    - need to have a dropdown for permission level thats UUID can be sent as a param as well
- Ability to assign heirarchies
    - Edit user button with user dropdown/search to select from
    - Add manager button with search/dropdown 
- Manual Adds/Edits
    - Manually add/edit account
    - Manually assign owners to shipments/orders/invoices 
- Ability to delete a User
    - Need to be able to search for a User

*/
}
