import * as React from 'react';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FileDetails } from '../interfaces/FileDetails';
import DragAndDropFiles from './DragAndDropFiles';
import { uploadOrderImage } from '../services/orderFileService';
import { Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import { reloadShipmentsScreen } from '../atoms/atoms';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { ToastContext } from '../contexts/ToastContext';


interface Props {
  row: any | null
}
export default function ShipmentImageUpload(props:Props) {
  //states
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState<FileDetails[]>([]);
  const [numFilesUploaded, setNumFilesUploaded] = React.useState(0);
  const [reloadShipments, setReloadShipments] = useRecoilState(reloadShipmentsScreen);

  //context
  const { setSuccessMessage } = React.useContext(ToastContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFiles([]);
    setNumFilesUploaded(0);
    setLoading(false);
    setOpen(false);
  };

  const handleFiles = async function (formFiles: FileDetails[]) {
    //get file data to show a preview
    for(let i = 0; i < formFiles.length; i++) {
      let file = formFiles[i];
      getFileData(file, (fileDetail:FileDetails) => {
        setFiles([
          ...files,
          fileDetail
        ]);
      });
    }
  }
  const handleSave = function () {
    if(!files.length) {
      handleClose();
      return;
    }
    setLoading(true);

    for(let i = 0; i < files.length; i++) {
      let file = files[i];
      uploadOrderImage(file, props.row.orderId, () => {
        let num = numFilesUploaded + 1;
        setNumFilesUploaded(num);

        if(num >= files.length) {
          setSuccessMessage('Files uploaded! Refreshing shipment data now!');
          setReloadShipments((reloadShipments) => !reloadShipments);
          handleClose();
        }
      });
    }
  }

  return (
    <div>
      <Button variant="contained" endIcon={<AddCircleIcon />} onClick={handleClickOpen} size='small'>Add Image</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload Images For Order: {props.row.id}</DialogTitle>
        <DialogContent>
          <RenderImagePreviews files={files} />
          <DragAndDropFiles handleFiles={handleFiles} allowedFileTypes={"image/*"} allowMultipleFiles={true} />
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleSave} loading={loading}>Save</LoadingButton>
          <Button onClick={handleClose} disabled={loading}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

interface ImageFiles {
  files: FileDetails[]
}
function RenderImagePreviews({files}:ImageFiles) {
  if(!files.length) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      {files.map((img, key) => {
        return (
          <Grid key={('img-'+key)} item xs>
            <img src={img.data?.toString()} style={{maxHeight:50}} />
          </Grid>
        )
      })}
    </Grid>
  )
}

function getFileData(file:any, onSuccess:Function) {
  const fileReader = new FileReader();
  fileReader.onload = () => {
    if (fileReader.readyState === 2 && fileReader?.result) {
      var base64FileDetails = fileReader.result.toString();
      let fileDetails:FileDetails = {
        name: file.name,
        size: file.size,
        type: file.type,
        data: base64FileDetails,
      };
      return onSuccess(fileDetails);
    }
  };
  fileReader.readAsDataURL(file);
}