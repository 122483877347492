import { baseApi } from "./baseService";

const API = {
  getUserAccounts: "/api/userAccount/user/",
  getUserAccountsScreen: "/api/userAccount/accountScreen/",
  getUserAccountsIds: "/api/userAccount/user/accountIds",
  getTopAccounts: "/api/userAccount/topAccounts/",
};

export const getUserAccounts = (
  userId: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getUserAccounts, { userId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getUserAccountsScreen = (
  userId: string | Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getUserAccountsScreen, { userId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getUserAccountsIds = (
  userId: string | Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getUserAccountsIds, { userId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getTopAccounts = (
  userId: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getTopAccounts, { userId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};
