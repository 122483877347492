import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import React from "react";
import { Invoice } from "../interfaces/Invoice";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LoadingBlock from "../components/LoadingBlock";

interface Props {
  invoices: Array<Invoice> | null;
}

export const AccountInvoiceCard = ({ invoices }: Props) => {
  const status: any = {
    1: "New",
    2: "Active",
    3: "Needs Attention",
    4: "On Hold",
    5: "Ready To Bill",
    6: "Completed",
    7: "Canceled",
    8: "Ready for Approval",
    9: "Delivered",
  };
  const keys = Object.keys(status);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    key: number
  ) => {
    setAnchorEl(null);
    setKey(key);
    setfilter(status[key]);
  };
  const [key, setKey] = React.useState(2);
  const [filter, setfilter] = React.useState(status[2]);
  if (!invoices) {
    return <LoadingBlock />;
  }

  let activeTotal = invoices
    .filter(
      (inv) => inv.statusId === 1 || inv.statusId === 2 || inv.statusId === 3
    )
    .reduce((a, b) => a + b.totalAmt, 0);
  let unbilledTotal = invoices
    .filter((inv) => inv.statusId === 4 || inv.statusId === 6)
    .reduce((a, b) => a + b.totalAmt, 0);
  let canceledTotal = invoices
    .filter((inv) => inv.statusId === 5)
    .reduce((a, b) => a + b.totalAmt, 0);
  //let active= statuses.filter(stat=> stat=="Active")
  //console.log(invoices)

  return (
    <Card
      variant="outlined"
      sx={{
        height: "250px",
        margin: "12px",
        width: "33%",
      }}
    >
      <CardHeader
        action={
          <IconButton
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        }
        avatar={
          <Avatar
            sx={{
              backgroundColor: "green",
              height: 50,
              width: 50,
            }}
          >
            <RequestQuoteIcon />
          </Avatar>
        }
        title="Invoices"
        titleTypographyProps={{ variant: "h5" }}
        subheader={`Total Amount ${filter}:`}
        subheaderTypographyProps={{ variant: "subtitle1" }}
      ></CardHeader>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {keys.map((key: any, index: number) => {
          return (
            <MenuItem
              key={key}
              onClick={(event) => handleMenuClick(event, key)}
            >
              {status[key]}
            </MenuItem>
          );
        })}
      </Menu>
      <CardContent className="accountsCard">
        <Grid
          container
          spacing={3}
          sx={{ alignItems: "center", justify: "center" }}
        >
          <Grid item>
            <Typography color="textPrimary" variant="h2">
              {invoices.filter((inv: any) => inv.statusId == key).length}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
