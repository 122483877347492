import * as React from 'react';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton, Typography } from '@mui/material';
import { useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { FileDetails } from '../interfaces/FileDetails';

export interface Image {
    profilePic: FileDetails | null,
    firstName: string,
    lastName: string,
    emailAddress: string,
    phoneNumber: string,
}

export interface InputRef {
    current: any | null
}

export interface Props {
    handleFiles: (files: Array<FileDetails>) => void,
    allowedFileTypes: string | null,
    allowMultipleFiles: boolean,
}

export default function DragAndDropFiles(props: Props) {
    //states
    const [loading, setLoading] = React.useState(false);
    const [dragActive, setDragActive] = React.useState(false);

    const allowedFileTypes = !props.allowedFileTypes ? 'image/*' : props.allowedFileTypes;

    //so we can always reference the file upload fiel
    const fileUploadFieldRef: InputRef = React.useRef(null);

    const onButtonClick = () => {
        if (!fileUploadFieldRef || !fileUploadFieldRef.current) {
            return;
        }
        fileUploadFieldRef.current.click();
    };

    const handleDrag = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        handleChange(e);
    };
    const handleChange = function (e: any) {
        if (e?.dataTransfer?.files && e.dataTransfer.files[0]) {
            props.handleFiles(e.dataTransfer.files);
            return;
        }
        if(e?.target?.files != null) {
            props.handleFiles(e.target.files);
            return;
        }
    }

    const formik = useFormik({
        initialValues: {},
        onSubmit: async () => {
            return false;
        },
    });

    return (
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{
                display: 'flex',
                background: dragActive ? "#eee" : "inherit",
                border: "3px dashed #ccc",
                borderRadius: 10,
                alignItems: 'center',
                justifyContent: 'center',
                flexGrow: 1,
                flexDirection: 'column',
                py: 8,
            }}
            onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}
        >
            <Typography  variant='h6' align='center' style={{
                padding: 15
            }}>
                Drag and drop your files here or
            </Typography>
            <LoadingButton
                size='small'
                loading={loading}
                variant='outlined'
                component='label'
                startIcon={<UploadFileIcon fontSize="small" />}
                onClick={onButtonClick}
            >
                Upload files
            </LoadingButton>
            <input
                ref={fileUploadFieldRef}
                multiple={props.allowMultipleFiles}
                accept={allowedFileTypes}
                id='upload-files'
                type='file'
                hidden
                onChange={handleChange}
            />
        </Box>
    );
}