import { baseApi } from "./baseService";

const API = {
  getShipments: "/api/shipment/user",
  updateShipment: "/api/shipment/update/",
  getShipmentsDashboard: "/api/shipment/dashboard",
  getShipmentsAccountScreen: "/api/shipment/accountScreen",
  getShipmentRadius: "/api/shipment/radius",
  getShipmentsRadii: "/api/shipment/radii",
  getTrailerTypes: "/api/shipment/trailerType",
  getShipmentsFiltered: "/api/shipment/filtered",
};

interface ShipmentData {
  shipmentOwnerId?: string;
  carrierId?: string;
  orderId?: string;
  originLocationId?: string;
  destinationLocationId?: string;
  statusId?: string;
  driverName?: string;
  driverPhone?: string;
  isDeleted?: string;
  reasonCodeId?: string;
}

export const getShipments = (
  userId: string | Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getShipments, { userId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getShipmentsDashboard = (
  userId: string | Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getShipmentsDashboard, { userId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getShipmentsAccountScreen = (
  userId: string,
  accountId: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getShipmentsAccountScreen, { userId, accountId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const updateShipment = (
  shipmentId: string,
  shipmentData: ShipmentData,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.updateShipment + shipmentId, { ...shipmentData })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getShipmentsRadii = (
  dateRange: string | null,
  trailerTypes: Array<any> | null,
  olat: string,
  olng: string,
  odist: string,
  dlat: string,
  dlng: string,
  ddist: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getShipmentsRadii, {
        olat,
        olng,
        odist,
        dlat,
        dlng,
        ddist,
        dateRange,
        trailerTypes,
      })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getShipmentRadius = (
  dateRange: string | null,
  trailerTypes: Array<any> | null,
  lat: string,
  lng: string,
  dist: any,
  origin_dest: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getShipmentRadius, {
        lat,
        lng,
        dist,
        origin_dest,
        dateRange,
        trailerTypes,
      })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        //console.log(response);
        onError && onError(response);
      })
  );
};

export const getTrailerTypes = (onSuccess: Function, onError?: Function) => {
  baseApi().then((api) =>
    api
      .post(API.getTrailerTypes)
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};
export const getShipmentsFiltered = (
  userId: string | Array<any>,
  column: string,
  operator: string,
  value: string | Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getShipmentsFiltered, { userId, column, operator, value })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};
