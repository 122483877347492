import { Button, IconButton, Menu, MenuItem, Paper } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import React from "react";
import LoadingBlock from "../../components/LoadingBlock";
import { UserContext } from "../../contexts/UserContext";
import { User } from "../../interfaces/User";
import { getUsers } from "../../services/userService";
import { useNavigate } from "react-router";
import { ToastContext } from "../../contexts/ToastContext";
import { UserAccount } from "../../interfaces/UserAccount";
import Popover from "@mui/material/Popover";
import AddUserForm from "./AddUserForm";

export default function UserAdminScreen() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [userList, setUserList] = React.useState<Array<User>>([]);
  const { user } = React.useContext(UserContext);
  const { setExceptionMessage } = React.useContext(ToastContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState("");
  const [manager, setManager] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/Admin");
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  React.useEffect(() => {
    if (!user?.id) return;

    //console.log(user);

    var accountIds = user.userAccounts.map((acc: UserAccount) => {
      return acc.accountId;
    });
    //console.log(accountIds);
    //get users from api
    getUsers(
      { accountIds: accountIds },
      (data) => {
        setUserList(data);
        setIsLoading(false);
      },
      (ex: any) => {
        setExceptionMessage(ex);
        setIsLoading(false);
      }
    );
  }, [user?.id]);

  if (isLoading) return <LoadingBlock />;

  return (
    <Paper sx={{ m: 4, p: 4 }}>
      <Button
        endIcon={<ArrowBackIcon fontSize="small" />}
        size="small"
        onClick={handleBack}
      >
        Back
      </Button>
      <DataGridPro
        density="compact"
        autoHeight
        columns={columns}
        rows={generateRows(userList)}
      />
      <Button onClick={handleClick}>Add a User</Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ width: "100%" }}
      >
        <AddUserForm
          firstName={firstName}
          lastName={lastName}
          setLastName={setLastName}
          setFirstName={setFirstName}
          email={email}
          setEmail={setEmail}
          role={role}
          setRole={setRole}
          manager={manager}
          setManager={setManager}
        />
      </Popover>
    </Paper>
  );
  /*
  Functionality:
  - Check if user is local admin and block if not
  - Ability to add in users by sending an email 
      - Need an email box
      - email link needs to have accountId as a param so we can associate userAccount 
      - need to have a dropdown for permission level thats UUID can be sent as a param as well
  - Ability to assign heirarchies
      - Edit user button with user dropdown/search to select from
      - Add manager button with search/dropdown 
  - Manual Adds/Edits
      - Manually add/edit account
      - Manually assign owners to shipments/orders/invoices 
  - Ability to delete a User
      - Need to be able to search for a User
  
  */
}

interface UserRow {
  id: number;
  email: string;
  name: string;
  phone: number;
  actions: any;
}
const generateRows = (mapUsers: Array<User>) => {
  //console.log(mapUsers);
  var rows: Array<UserRow> = [];
  var id = 1;
  mapUsers.map((user: User, index: number) => {
    const row: UserRow = {
      id,
      email: user.emailAddress,
      name: user.firstName + " " + user.lastName,
      phone: user.phoneNumber,
      actions: user.id,
    };
    rows.push(row);
    id++;
    return user;
  });
  return rows;
};

const RenderUserActions = (props: GridRenderCellParams<string>) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper>
      <IconButton
        id="user-action-button"
        aria-controls={open ? "user-action-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="user-action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "user-action-button",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/Admin/Users/" + props.value, { replace: true });
          }}
        >
          Edit User
        </MenuItem>
      </Menu>
    </Paper>
  );
};
const columns: GridColDef[] = [
  { field: "email", headerName: "Email", flex: 1 },
  { field: "name", headerName: "Name", flex: 1 },
  { field: "phone", headerName: "Phone Number", flex: 1 },
  {
    field: "actions",
    headerName: "Actions",
    flex: 1,
    renderCell: RenderUserActions,
  },
];
