import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";
import React from "react";
import LoadingBlock from "../../components/LoadingBlock";
import { useParams } from "react-router-dom";
import { User } from "../../interfaces/User";
import { getUser } from "../../services/userService";
import { ToastContext } from "../../contexts/ToastContext";
import { addUserPage, getAvailablePages } from "../../services/adminService";

export default function UserAdminScreen() {
  const { userid } = useParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const [editUser, setEditUser] = React.useState<User | null>(null);
  const { setSuccessMessage, setExceptionMessage } =
    React.useContext(ToastContext);
  const [pages, setPages] = React.useState<any>([]);
  const [selectPages, setSelectPages] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    if (!userid) return;

    //get user from api
    getUser(
      userid,
      (data) => {
        setEditUser(data);
        setIsLoading(false);
      },
      (ex: any) => {
        setExceptionMessage(ex);
        setIsLoading(false);
      }
    );
    //get available pages for user
    getAvailablePages(
      userid,
      (data: any) => {
        //console.log(data);
        setPages(data);
      },
      (err: any) => {
        setExceptionMessage(err);
      }
    );
  }, [userid]);

  const handleCheckBox = (e: any) => {
    if (selectPages.includes(e.target.id)) {
      setSelectPages((prev: any) =>
        prev.filter((id: any) => id !== e.target.id)
      );
    } else {
      setSelectPages((prev: any) => [...prev, e.target.id]);
    }
  };

  const handleSubmitPages = () => {
    addUserPage(
      userid,
      selectPages,
      (data: any) => {
        setSuccessMessage(data);
      },
      (err: any) => {
        setExceptionMessage(err);
      }
    );
  };

  if (isLoading || !editUser?.id) return <LoadingBlock />;

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Paper sx={{ m: 4, p: 4 }}>
          <h3>User Basic Info</h3>
          <List sx={{ width: "100%" }}>
            <ListItem>
              <ListItemText
                primary={editUser.firstName + " " + editUser.lastName}
                secondary="Name"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={editUser.emailAddress} secondary="Email" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={formatAddress(editUser.location)}
                secondary="Location"
              />
            </ListItem>
          </List>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper sx={{ m: 4, p: 4 }}>
          <h3>User Accounts</h3>
          <List sx={{ width: "100%" }}>
            {editUser.userAccounts.map((acc, i) => {
              return (
                <ListItem key={i}>
                  <ListItemText
                    primary={
                      acc.account.name + " | " + acc.account.accountNumber
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper sx={{ m: 4, p: 4 }}>
          <h3>Available Pages</h3>
          <FormControl>
            <FormGroup>
              {pages.map((p: any) => (
                <FormControlLabel
                  control={<Checkbox id={p.id} />}
                  label={p.value}
                  id={p.id}
                  onChange={handleCheckBox}
                />
              ))}
            </FormGroup>
          </FormControl>
          {selectPages.length > 0 && (
            <Button variant="contained" onClick={handleSubmitPages}>
              Submit
            </Button>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

const formatAddress = (location: any) => {
  return (
    <span>
      {location.address}
      <br />
      {location.city} {location.stateShort} {location.zip5}
    </span>
  );
};
