import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { NotificationsIconComponent } from "./NotficationsIcon";
import SignInScreen from "../screens/SignInScreen";
import { getInitials } from "../utils/getInitials";
import { Badge } from "@mui/material";
import { useRecoilState } from "recoil";
import { approvalNotificationAtom, ordersAtom } from "../atoms/atoms";
import { getOrdersApprovalsNotification } from "../services/orderService";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { QueueContext } from "../contexts/QueueContext";
const settings = ["Profile", "Settings"];

interface Props {
  permittedScreens: any;
}

const ResponsiveAppBar = (props: Props) => {
  const { permittedScreens } = props;
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [approval, setApproval] = React.useState(false);
  const { user, logout } = React.useContext(UserContext);
  const [orders, setOrders] = useRecoilState(ordersAtom);
  const { permissions, getPermissions } = React.useContext(PermissionsContext);
  const { closeEverything } = React.useContext(QueueContext);

  React.useEffect(() => {
    if (!user) return;
    getOrdersApprovalsNotification(
      !permissions?.hierarchy ? user.id : [...permissions?.hierarchy, user.id],
      (data: any) => {
        setApproval(data);
        //console.log(data);
      },
      (ex: any) => {
        setApproval(false);
        console.log(ex);
      }
    );
  }, [setOrders, orders]);

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  if (!permittedScreens) return <SignInScreen />;

  return (
    <AppBar position="static" sx={{ background: "#15157E" }}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Typography variant="h6" noWrap component="a" href="/Home">
            <img
              src="images/Swifttrax-White-Pixellogic-Finalized-01.png"
              className="navLogo"
              alt="Logo"
            ></img>
          </Typography>
          {user && (
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {/* here we can add links to the NavBar (hamburger menu) */}
                {permittedScreens.map((page: any, i: any) => (
                  <MenuItem key={i} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">
                      <Link
                        to={`/${typeof page !== "object" ? page : page.path}`}
                      >
                        {typeof page !== "object" ? page : page.name}
                      </Link>
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/Home"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            SwiftTrax
          </Typography>

          {user && (
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {/* here we can add links to the NavBar */}
              {permittedScreens.map((page: any, i: any) => (
                <>
                  <Button
                    key={i}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {page == "Approvals" && approval ? (
                      <Badge
                        color="error"
                        badgeContent={1}
                        max={999}
                        variant={"dot"}
                      >
                        <Link
                          style={{ textDecoration: "none", color: "white" }}
                          to={`/${typeof page !== "object" ? page : page.path}`}
                        >
                          {typeof page !== "object" ? page : page.name}
                        </Link>
                      </Badge>
                    ) : (
                      <Link
                        style={{ textDecoration: "none", color: "white" }}
                        to={`/${typeof page !== "object" ? page : page.path}`}
                      >
                        {typeof page !== "object" ? page : page.name}
                      </Link>
                    )}
                  </Button>
                </>
              ))}
            </Box>
          )}
          <Box sx={{ flexGrow: 0.02, display: { xs: "none", md: "flex" } }}>
            <NotificationsIconComponent />
          </Box>
          {user && (
            <Box sx={{ flexGrow: 0, justifyContent: "center" }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={`${user?.firstName}`} src="">
                    {getInitials(`${user.firstName} ${user.lastName}`)}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/${setting}`}
                      >
                        {setting}
                      </Link>
                    </Typography>
                  </MenuItem>
                ))}
                <MenuItem>
                  <Typography textAlign="center">
                    <Link
                      style={{ textDecoration: "none", color: "black" }}
                      to={`/`}
                      onClick={() => {
                        closeEverything().then(() => {
                          setAnchorElUser(null);
                          logout();
                        });
                      }}
                    >
                      Logout
                    </Link>
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
