import { Button, CardHeader } from "@mui/material";
import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid-pro";
//import ShipmentImagePopover from "../components/ShipmentImagePopover";
import {
  deleteImageFile,
  //deleteOrderFile,
  getUnmatchedFiles,
} from "../services/orderFileService";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import LoadingBlock from "../components/LoadingBlock";
// import ReassignImage from "../components/ReassignImage";
// import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import UnmatchedImagesPopover from "../components/UnmatchedImagesPopover";

export default function UnmatchedScreen() {
  const [deleteWarning, setDeleteWarning] = React.useState(true);
  const [files, setFiles] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    getUnmatchedFiles(
      (data: any) => {
       // console.log(data);
        setFiles(data);
        setIsLoading(false);
      },
      (err: any) => {
        console.log(err);
      }
    );
  }, []);

  if (isLoading) return <LoadingBlock />;

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  function handleDeleteUnmatched(row: any) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Delete Image",
      confirmButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        //handle deleting the image here, and removing the image from state upon successfully deleting

        deleteImageFile(row.id, (data: any) => {
          console.log(data);
          const newFiles = files.filter((file: any) => file.fileId != row.id);
          setFiles(newFiles);
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "The image has been deleted.",
            "success"
          );
        });
      }
    });
  }


  function handleViewImage(row: any) {
    console.log(row);
    Swal.fire({
      imageUrl: row.image,
      imageWidth: "95%",
      imageHeight: "95%",
      imageAlt: "Unmatched BOL",
    });
  }

  //defining field names, and headers of columns. will be passed into datagrid as argument to render the columns. renderCell can render something in every cell of the column. takes row as argument. {row} is an object of all the data in the row provided by generateRows()
  const columns: GridColDef[] = [
    { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
    { field: "date", headerName: "Date Recieved", flex: 1 },
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      renderCell: ({ row }: any) => (
        // <ShipmentImagePopover file={[row.image]} />,
        <Button onClick={() => handleViewImage(row)}>View Image</Button>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: ({ row }: any) => (
        <Typography display={"flex"}>
          <UnmatchedImagesPopover files={files} setFiles={setFiles} row={row} />
          <Button
            sx={{ m: 1 }}
            color={"error"}
            onClick={() => handleDeleteUnmatched(row)}
            variant="contained"
          >
            Delete
            <CloseIcon />
          </Button>
        </Typography>
      ),
    },
  ];

  interface UnmatchedRow {
    id: any;
    date: any;
    image: any;
    phoneNumber: any;
  }

  //filling the rows with data and creating the {row} object passed into renderCell
  const generateRows = (images: any) => {
    let rows: Array<UnmatchedRow> = [];
    files.map((image: any, index: any) => {
      const row: UnmatchedRow = {
        id: image.fileId,
        date: new Date(image.createdAt).toLocaleDateString("en-us"),
        phoneNumber: image.fromNumber,
        image: image.file.file,
      };
      rows.push(row);
      return image;
    });
    return rows;
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <CardHeader
            title="Unmatched Images"
            titleTypographyProps={{
              fontSize: 40,
              variant: "overline",
              textAlign: "center",
              fontStyle: "underline",
            }}
          />
          <DataGrid columns={columns} rows={generateRows(files)} />
        </div>
      </div>
    </div>
  );
}
