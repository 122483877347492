import { AMQPWebSocketClient } from "@cloudamqp/amqp-client";
import { Permissions } from "../interfaces/Permissions";
import { QueueMessage } from "../interfaces/QueueMessage";
import { User } from "../interfaces/User";

interface Props {
  channel: string;
  user: User | null;
  onMessageReceived: (queueItem: QueueMessage) => void;
  permissions?: Permissions | null;
  msg: any;
  selector: number;
}

export function useQueueListener() {
  const ListenForQueueMessages = async ({
    channel,
    user,
    onMessageReceived,
    permissions,
    msg,
    selector,
  }: Props) => {
    // console.log('listen');

    if (!user) return;

    console.log("Listen for queue messages");

    // const conn = await amqp.connect();
    // let ch = await conn.channel();
    // let q = await ch.queue(channel);

    //console.log('sub to '+channel);
    // const consumer = await q.subscribe(
    //   { noAck: false, tag: user.emailAddress },
    //   (msg: any) => {
    // console.log('message received');
    let queueItem = msg.bodyToString() ?? "";

    var queueObject: QueueMessage = JSON.parse(queueItem);
    //console.log(queueItem);

    if (selector == 1) {
      setTimeout(() => {
        msg.ack();
      }, 5000);

      return onMessageReceived(queueObject);
    }

    if (queueObject.owner === user.id) {
      // console.log('owner matches!');

      //acknowledge the message after 5 seconds
      setTimeout(() => {
        msg.ack();
      }, 5000);

      return onMessageReceived(queueObject);
    } else if (queueObject.owner == "NONE") {
      if (
        permissions?.primaryAccount == queueObject.accountId &&
        permissions?.pages?.includes("Unmatched")
      ) {
        return onMessageReceived(queueObject);
      } else {
        setTimeout(() => {
          msg.nack(true);
        }, 5000);
        return;
      }
    } else {
      setTimeout(() => {
        msg.nack(true);
      }, 5000);
      return;
    }
  };
  // );
  // await consumer.wait();
  //};
  return { ListenForQueueMessages };
}
