import React from "react";
import { Shipment } from "../interfaces/Shipment";
import { ShipmentContextState } from "../interfaces/ShipmentContextState";
import axios from "axios";
import { UserContext } from "./UserContext";
import { useRecoilState } from "recoil";
import { shipmentsAtom } from "../atoms/atoms";
import { getShipments } from "../services/shipmentService";

export const ShipmentContext = React.createContext<ShipmentContextState>({
  shipments: null,
  loadShipments: () => {},
});

interface Props {
  children?: React.ReactNode;
}
export const ShipmentProvider = ({ children }: Props) => {
  //const [shipments, setShipments] = React.useState<Array<Shipment> | null>(null);
  const [shipments, setShipments] = useRecoilState(shipmentsAtom);
  const { getUser } = React.useContext(UserContext);

  const loadShipments = async () => {
    let shipments: Array<Shipment> | null = null;
    var user = getUser();

    if (!user) return;

    try {
      getShipments(
        user.id,
        (data: any) => {
          setShipments(data);
        },
        (ex: any) => {
          console.log(
            "Something bad happened while trying to get the Shipments from the api."
          );
          console.log(ex);
        }
      );
    } catch (error) {
      console.log(
        "Something bad happened while trying to get the Shipments from the api."
      );
      console.log(error);
    }
  };

  return (
    <ShipmentContext.Provider value={{ shipments, loadShipments }}>
      {children}
    </ShipmentContext.Provider>
  );
};
