import React from "react";
import { UserContext } from "./UserContext";
import { useRecoilState } from "recoil";
import { billsAtom, invoicesAtom } from "../atoms/atoms";
import { getBills } from "../services/billService";
import { BillContextState } from "../interfaces/BillContextState";

export const BillContext = React.createContext<BillContextState>({
  bills: null,
  loadBills: () => {},
});

interface Props {
  children?: React.ReactNode;
}
export const BillProvider = ({ children }: Props) => {
  const [bills, setBills] = useRecoilState(billsAtom);
  const { getUser } = React.useContext(UserContext);

  const loadBills = async () => {
    //let shipments: Array<Shipment> | null = null;
    var user = getUser();

    if (!user) return;

    try {
      getBills(
        user.id,
        (data: any) => {
          setBills(data);
        },
        (ex: any) => {
          console.log(
            "Something bad happened while trying to get the bills from the api."
          );
          console.log(ex);
        }
      );
    } catch (error) {
      console.log(
        "Something bad happened while trying to get the invoices from the api."
      );
      console.log(error);
    }
  };

  return (
    <BillContext.Provider value={{ bills, loadBills }}>
      {children}
    </BillContext.Provider>
  );
};
