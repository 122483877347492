import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { UserContext } from "../contexts/UserContext";
import { accountsAtom, billsAtom } from "../atoms/atoms";
import { useRecoilState } from "recoil";
import {
  DataGridPro,
  DataGridProProps,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import { GridColDef } from "@mui/x-data-grid-pro";
import { SeverityPill } from "../components/SeverityPill";
import { ColorOptions } from "../interfaces/ColorOptions";
import { ToastContext } from "../contexts/ToastContext";
import { Bill } from "../interfaces/Bill";
import LoadingBlock from "../components/LoadingBlock";
import { getBillPage } from "../services/billService";
import { Paper, Grid, Stack, Typography, CardHeader } from "@mui/material";
import { BillLine } from "../interfaces/BillLine";
import { PermissionsContext } from "../contexts/PermissionsContext";
//import BillDescriptionPopover from "../components/BillDescriptionPopover";
import { QueueContext } from "../contexts/QueueContext";

const statusDict: any = {
  1: "New",
  2: "Active",
  3: "Needs Attention",
  4: "On Hold",
  5: "Ready To Bill",
  6: "Completed",
  7: "Canceled",
};

const reasonDict: any = {
  1: "Missing BOL",
  2: "Illegible BOL",
  3: "Claim",
};

export default function BillsScreen() {
  const { user } = React.useContext(UserContext);
  const [bills, setBills] = useRecoilState(billsAtom);
  const toastCtx = React.useContext(ToastContext);
  const { setExceptionMessage } = React.useContext(ToastContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const { permissions } = React.useContext(PermissionsContext);
  const userId = user?.id || "";
  const { queueMessages } = React.useContext(QueueContext);

  const getDetailPanelContent: DataGridProProps["getDetailPanelContent"] =
    React.useCallback(({ row }: any) => <DetailPanelContent row={row} />, []);

  React.useEffect(() => {
    if (!user) return;
    try {
      getBillPage(
        !permissions?.hierarchy
          ? user.id
          : [...permissions?.hierarchy, user.id],
        (data: any) => {
          setBills(data);
          setIsLoading(false);
        },
        (ex: any) => {
          setExceptionMessage(ex);
          setIsLoading(false);
        }
      );
      //console.log(response.data);
    } catch (ex: any) {
      toastCtx.setExceptionMessage(ex);
      setIsLoading(false);
    }
  }, [queueMessages.BillMessage]);

  if (isLoading || !user) return <LoadingBlock />;

  return (
    <Box sx={{ width: 1, height: 400 }}>
      <CardHeader
        title="Bills"
        titleTypographyProps={{
          fontSize: 40,
          variant: "overline",
          textAlign: "center",
          fontStyle: "underline",
        }}
        subheader="Accounts Payable"
        subheaderTypographyProps={{
          variant: "overline",
          textAlign: "center",
          fontStyle: "underline",
        }}
      />
      <DataGridPro
        density="compact"
        autoHeight
        columns={columns}
        rows={generateBillRows(bills, user?.id)}
        getDetailPanelContent={DetailPanelContent}
        components={{ Toolbar: GridToolbar }}
      />
    </Box>
  );
}

function DetailPanelContent({ row }: any) {
  switch (row.status) {
    case "1":
      row.status = "New";
      break;
    case "2":
      row.status = "Active";
      break;
    case "3":
      row.status = "Needs Attention";
      break;
    case "4":
      row.status = "On Hold";
      break;
    case "5":
      row.status = "Ready To Bill";
      break;
    case "6":
      row.status = "Completed";
      break;
    case "7":
      row.status = "Canceled";
      break;
  }

  return (
    <Box>
      <Stack
        sx={{ py: 2, height: 1, boxSizing: "border-box" }}
        direction="column"
      >
        <Paper sx={{ flex: 1, mx: "auto", width: "98%", p: 1 }}>
          <Stack direction="column" spacing={1} sx={{ height: 1 }}>
            <Typography variant="h6">{`Bill #${row.billNumber}`}</Typography>

            <DataGridPro
              density="compact"
              columns={popDownColumns}
              rows={row.billLine}
              sx={{ flex: 1 }}
              hideFooter
              autoHeight
            />
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
}

interface PopDownColumns extends Partial<renderCell> {
  headerName: string;
  field: string;
  flex: number;
}

const popDownColumns: PopDownColumns[] = [
  { field: "billLineNumber", headerName: "Bill Line Number", flex: 1 },
  { field: "amount", headerName: "Amount", flex: 1 },

  { field: "description", headerName: "Description", flex: 1 },
  {
    field: "button",
    headerName: "",
    flex: 1,
    renderCell: ({ row }: any) => {}, //<BillDescriptionPopover row={row} />,
  },

  { field: "createdAt", headerName: "Created Date", flex: 1 },
  { field: "updatedAt", headerName: "Last Updated", flex: 1 },
];

interface renderCell {
  renderCell: any;
}

DetailPanelContent.propTypes = {
  row: PropTypes.object.isRequired,
};

// const getSeverityColor = (status: string): ColorOptions => {
//   let color: ColorOptions;
//   status = status; //.toLowerCase();
//   switch (status) {
//     case "new":
//       color = "primary";
//       break;
//     case "active":
//       color = "success";
//       break;
//     case "on hold":
//       color = "warning";
//       break;
//     default:
//       color = "error";
//       break;
//   }
//   return color;
// };

const columns: GridColDef[] = [
  { field: "billNumber", headerName: "Bill #", flex: 1 },
  { field: "account", headerName: "Account", flex: 1 },
  { field: "currencyAmt", headerName: "Bill Amount", flex: 1 },
  // {
  //   field: 'statusId', headerName: 'Status', flex: 1,
  //   renderCell: ({ row }) => (<SeverityPill color={getSeverityColor(row.name)}>{row.name}</SeverityPill>)
  // },
  { field: "owner", headerName: "Bill Owner", flex: 1 },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: ({ row }) => (
      <SeverityPill status={row.status}>{row.status}</SeverityPill>
    ),
  },
];

interface BillRow extends Bill {
  status: any;
  reason: any;
  owner: any;
  account: any;
  currencyAmt: any;
  loop: number;
  isDeleted: boolean;
  billLine: Array<BillLine>;
}

const generateBillRows = (bill: Array<Bill>, userId: any) => {
  //will need a secondary generate rows per object
  var rows: Array<BillRow> = [];
  var loop = 1;
  bill.map((b: any, index: number) => {
    const row: BillRow = {
      loop: loop,
      id: b.id,
      billNumber: b.billNumber,
      APAccountId: b.accountId,
      billOwnerId: b.billOwnerId,
      shipmentId: b.shipmentId,
      currencyId: b.currencyId,
      totalAmt: b.totalAmt,
      statusId: b.statusId,
      createdAt: b.createdAt,
      updatedAt: b.updatedAt,
      reasonCode: b.reasonCode,
      status: statusDict[b.statusId],
      reason: reasonDict[b.reasonCode],
      owner: `${b.user.firstName}, ${b.user.lastName}`,
      account: b.account.name,
      currencyAmt: `$${b.totalAmt}`,
      isDeleted: false,
      billLine: b.billLines,
    };
    rows.push(row);
    loop++;
    //console.log(b);
    return b;
  });
  //console.log(bill);
  return rows;
};
