import React from "react";
import { Shipment } from "../interfaces/Shipment";

import { UserContext } from "./UserContext";
import { useRecoilState } from "recoil";
import { invoicesAtom } from "../atoms/atoms";
import { getShipments } from "../services/shipmentService";
import { InvoiceContextState } from "../interfaces/InvoiceContextState";
import { getInvoices } from "../services/invoiceService";

export const InvoiceContext = React.createContext<InvoiceContextState>({
  invoices: null,
  loadInvoices: () => {},
});

interface Props {
  children?: React.ReactNode;
}
export const InvoiceProvider = ({ children }: Props) => {
  const [invoices, setInvoices] = useRecoilState(invoicesAtom);
  const { getUser } = React.useContext(UserContext);

  const loadInvoices = async () => {
    //let shipments: Array<Shipment> | null = null;
    var user = getUser();

    if (!user) return;

    try {
      getInvoices(
        user.id,
        (data: any) => {
          setInvoices(data);
        },
        (ex: any) => {
          console.log(
            "Something bad happened while trying to get the invoices from the api."
          );
          console.log(ex);
        }
      );
    } catch (error) {
      console.log(
        "Something bad happened while trying to get the invoices from the api."
      );
      console.log(error);
    }
  };

  return (
    <InvoiceContext.Provider value={{ invoices, loadInvoices }}>
      {children}
    </InvoiceContext.Provider>
  );
};
