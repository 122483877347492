import React from "react";
import { Route, Routes } from "react-router";
import "./App.css";
import ResponsiveAppBar from "./components/navBar";
import SignInScreen from "./screens/SignInScreen";
import SignUpScreen from "./screens/SignUpScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import { UserContext } from "./contexts/UserContext";
import NotFoundScreen from "./screens/NotFoundScreen";
import SplashScreen from "./screens/SplashScreen";
import { ScreenDict } from "./utils/screens.module";
import { PermissionsContext } from "./contexts/PermissionsContext";
import Settings from "./screens/Settings";
import SignUpWithToken from "./screens/SignUpWithToken";
import ShipmentsScreen from "./screens/ShipmentsScreen";
import { useRecoilState } from "recoil";
import { notificationsAtom, queueMessagesAtom } from "./atoms/atoms";
//import { ListenForQueueMessages } from "./services/QueueService";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ProfileScreen from "./screens/ProfileScreen";
import SignOutScreen from "./screens/SignOutScreen";
import UnmatchedScreen from "./screens/UnmatchedImagesScreen";
import ApprovalsScreen2 from "./screens/ApprovalsScreen2";

function App() {
  const userCtx = React.useContext(UserContext);
  const { loading, user } = userCtx;
  const { permissions } = React.useContext(PermissionsContext);
  const screens = ScreenDict.filter((dict) =>
    permissions?.pages?.includes(dict.name)
  );
  //console.log(screens)

  if (loading) {
    return <SplashScreen />;
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="app">
        {(() => {
          if (!user || !screens || !permissions) {
            return (
              <Routes>
                <Route
                  path="/ForgotPassword"
                  element={<ForgotPasswordScreen />}
                />
                <Route path="/SignIn" element={<SignInScreen />} />
                <Route path="/" element={<SignInScreen />} />
                <Route path="/SignUp" element={<SignUpScreen />} />
                <Route
                  path="/ResetPassword/:token"
                  element={<ResetPasswordScreen />}
                />
                <Route
                  path="/SignUp/:token/:id"
                  element={<SignUpWithToken />}
                />
                <Route path="/SignOut" element={<SignOutScreen />} />
                <Route path="*" element={<NotFoundScreen />} />
              </Routes>
            );
          } else {
            return (
              <>
                <ResponsiveAppBar permittedScreens={permissions?.pages} />
                <Routes>
                  {screens.map((screen, index) => (
                    <Route
                      key={index}
                      path={screen.path}
                      element={screen.pageRef}
                    />
                  ))}
                  <Route
                    path="/Shipments/:params"
                    element={<ShipmentsScreen />}
                  />
                  {/* <Route path="/approvals2" element={<ApprovalsScreen2 />}/> */}
                  <Route path="/Settings" element={<Settings />} />
                  <Route path="/Profile" element={<ProfileScreen />} />
                  <Route path="/SignOut" element={<SignOutScreen />} />
                </Routes>
              </>
            );
          }
        })()}
        {/* <Admin user={user} /> */}
      </div>
    </LocalizationProvider>
  );
}

export default App;
