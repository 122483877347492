import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';


export default function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="www.swifttrax.io/">
        SwiftTrax
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
      <Typography variant="body2" color="text.secondary" align="center">
      600 52nd St. S.
      Birmingham, AL 35212
    </Typography>
    </Typography>
    
  );
}
