import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { UserContext } from "../contexts/UserContext";
import { accountsAtom } from "../atoms/atoms";
import { useRecoilState } from "recoil";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Shipment } from "../interfaces/Shipment";
import { SeverityPill } from "../components/SeverityPill";
import { ToastContext } from "../contexts/ToastContext";
import { Account } from "../interfaces/Account";
import { Invoice } from "../interfaces/Invoice";
import { Order } from "../interfaces/Order";
import { AccountOrdersCard } from "../components/AccountOrdersCard";
import { AccountShipmentsCard } from "../components/AccountShipmentsCard";
import { AccountInvoiceCard } from "../components/AccountInvoiceCard";
import { getUserAccountsScreen } from "../services/userAccountService";
import LoadingBlock from "../components/LoadingBlock";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { AccountTerms } from "../components/AccountTerms";
import { Bill } from "../interfaces/Bill";
import { AccountBillCard } from "../components/AccountBillCard";
import CarrierRating from "../components/CarrierRating";
import { Tab, Tabs } from "@mui/material";

export default function AccountsScreen() {
  const { user } = React.useContext(UserContext);
  const [accounts, setAccounts] = useRecoilState(accountsAtom);
  const toastCtx = React.useContext(ToastContext);
  const { setExceptionMessage } = React.useContext(ToastContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const { permissions } = React.useContext(PermissionsContext);
  const userId = user?.id || "";
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const getDetailPanelContent: DataGridProProps["getDetailPanelContent"] =
    React.useCallback(({ row }: any) => <DetailPanelContent row={row} />, []);

  React.useEffect(() => {
    if (!user) return;
    try {
      getUserAccountsScreen(
        !permissions?.hierarchy
          ? user.id
          : [...permissions?.hierarchy, user.id],
        (data: any) => {
          let acctsArray = data.map((acct: any) => acct.account);
          setAccounts(acctsArray);
          setIsLoading(false);
        },
        (ex: any) => {
          setExceptionMessage(ex);
          setIsLoading(false);
        }
      );
    } catch (ex: any) {
      toastCtx.setExceptionMessage(ex);
      setIsLoading(false);
    }
  }, []);

  if (isLoading || !user) return <LoadingBlock />;

  return (
    <Box sx={{ width: 1, height: 400 }}>
      <Tabs value={selectedTabIndex} onChange={handleTabChange} centered>
        <Tab label="Carrier" />
        <Tab label="Shipper" />
      </Tabs>
      {selectedTabIndex === 0 ? (
        <DataGridPro
          density="compact"
          autoHeight
          columns={columns}
          rows={generateAccountRows(
            accounts.filter((account) => (account.modelType as any).name === "Carrier"),
            userId
          )}
          getDetailPanelContent={DetailPanelContent}
        />
      ) : (
        <DataGridPro
          density="compact"
          autoHeight
          columns={columns}
          rows={generateAccountRows(
            accounts.filter((account) => (account.modelType as any).name === "Shipper"),
            userId
          )}
          getDetailPanelContent={DetailPanelContent}
        />
      )}
    </Box>
  );
}

function DetailPanelContent({ row }: any) {

  return (
    <Box>
      {row.accountType == "Carrier" ? (
        <Stack direction={"row"}>
          <AccountShipmentsCard userId={row.userId} accountId={row.accountId} />
          <AccountBillCard bills={row.bills} />
          <CarrierRating carrierId={row.accountId} />
        </Stack>
      ) : (
        <Stack direction={"row"}>
          <AccountOrdersCard userId={row.userId} accountId={row.accountId} />
          <AccountShipmentsCard userId={row.userId} accountId={row.accountId} />
          <AccountInvoiceCard invoices={row.invoices} />
        </Stack>
      )}

      <AccountTerms accountId={row.accountId} />
    </Box>
  );
}

DetailPanelContent.propTypes = {
  row: PropTypes.object.isRequired,
};


const columns: GridColDef[] = [
  { field: "accountNumber", headerName: "Account ID", flex: 1 },
  { field: "address", headerName: "Address", flex: 1 },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: ({ row }) => (
      <SeverityPill status={row.accountType == "Carrier" ? "ready to bill" : "new"}>
        {row.name}
      </SeverityPill>
    ),
  },
  { field: "total", headerName: "Total Invoiced", flex: 1 },
  { field: "accountType", headerName: "Account Type", flex: 1 },
];

interface AccountRows {
  id: any;
  accountId: any;
  accountNumber: any;
  address?: any;
  name: any;
  total: any;
  accountType?: string;
  shipments: Array<Shipment>;
  invoices?: Array<Invoice>;
  bills?: Array<Bill>;
  orders: Array<Order>;
  userId: string;
}

const generateAccountRows = (account: Array<Account>, userId: any) => {
  //will need a secondary generate rows per object
  var rows: Array<AccountRows> = [];
  var id = 1;
  account.map((ua: any, index: number) => {
    let invoices = ua.invoices.reduce((acc: any, curr: any) => {
      return acc + curr.totalAmt;
    }, 0);
    const row: AccountRows = {
      id,
      accountNumber: ua.accountNumber,
      accountId: ua.id,
      address: ua.locations[0] ? ua.locations[0].address : null,
      name: ua.name,
      total: `$${invoices.toFixed(2)}`,
      accountType: ua.modelType.name,
      shipments: ua.shipments,
      invoices: ua.invoices,
      orders: ua.orders,
      userId: userId,
      bills: ua.bills,
    };
    rows.push(row);
    id++;
    return ua;
  });
  return rows;
};
