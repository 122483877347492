import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import Copyright from '../components/Copyright';
import LoadingButton from '@mui/lab/LoadingButton';
import { ToastContext } from '../contexts/ToastContext';
import { useFormik } from 'formik';
import * as yup from 'yup';
import AutocompleteAddresses from '../components/AutocompleteAddresses';
import { useLoadScript } from '@react-google-maps/api';
import PhoneNumberInput from '../components/PhoneNumberInput';
import { getTokenUser, getUser, registerUser } from '../services/userService';
import { Paper } from '@mui/material';

const theme = createTheme();
const GOOGLE_API_KEY = !!process.env.REACT_APP_GOOGLE_MAPS_API ? process.env.REACT_APP_GOOGLE_MAPS_API : '';
const libraries: any = ["places"];

export default function SignUpWithToken() {
    const { token, id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const toastCtx = React.useContext(ToastContext);
    const [phoneNumber, setPhoneNumber] = React.useState('')
    const [signupAddress, setSignupAddress] = React.useState<{ address: any, city: any, stateShort: any, stateLong: any, zip: any, latitude: any, longitude: any }>({
        address: '',
        city: '',
        stateShort: '',
        stateLong: '',
        zip: 0,
        latitude: 0,
        longitude: 0
    })
    console.log(signupAddress)
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyD4N9AVtvwT47X_Y-8TkVtYVArAsexlOp8",
        libraries,
    });

    React.useEffect(() => {
        if (!id || !token) return;
        const tokenUser = getTokenUser(id, token, (data: any) => {
            console.log(data)
            formik.values.firstName = data.firstName
            formik.values.lastName = data.lastName
            formik.values.email = data.emailAddress
        })
    }, [])


    let formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            phone: '17777777777'
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            console.log(values)
            console.log(signupAddress)
            try {
                registerUser({
                    userId: id, 
                    token,
                    emailAddress: values.email,
                    password: values.password,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phoneNumber: phoneNumber,
                    address: signupAddress
                  }, (data: any) => {
                    console.log(data)
                    toastCtx.setSuccessMessage('User created! Please log in.');
                    navigate('/')
                  })
            } catch (ex: any) {
                toastCtx.setExceptionMessage(ex);
            };
            setLoading(false);
        },
    });



    if (!isLoaded) return <Paper>Loading...</Paper>
    return (
        <ThemeProvider theme={theme}>
            <Typography>Token Sign In</Typography>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src='images/Swifttrax-Pixellogic-Finalized.jpg' className='logo' alt='Logo'></img>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="firstName"
                                    autoComplete="given-name"
                                    name="firstName"
                                    label="First Name"
                                    fullWidth
                                    autoFocus
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="family-name"
                                    fullWidth
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutocompleteAddresses setSignupAddress={setSignupAddress} formik={formik} />
                            </Grid>
                            <Grid item xs={12}>
                                <PhoneNumberInput setPhoneNumber={setPhoneNumber} name='phone' value={
                                    formik.values?.phone &&
                                    formik.values?.phone?.toString()
                                } />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    fullWidth
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                                    label="I want to receive marketing promotions and updates via email."
                                />
                            </Grid>
                        </Grid>
                        <LoadingButton
                            type="submit"
                            fullWidth
                            loading={loading}
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Up
                        </LoadingButton>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href="/SignIn" variant="body2">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>
    );
}

const validationSchema = yup.object({
    email: yup
        .string()
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string()
        .min(7, 'Password should be of minimum 7 characters length')
        .required('Password is required'),
    firstName: yup
        .string()
        .required('First Name is required'),
    lastName: yup
        .string()
        .required('Last Name is required'),
    phone: yup
        .string()
        .required('Valid Phone Number Required')
});