import * as React from "react";
import { createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { UserContext } from "../contexts/UserContext";
import LoadingBlock from "../components/LoadingBlock";

const theme = createTheme();

export default function SignOutScreen() {
  const { user, logout } = React.useContext(UserContext);
  const navigate = useNavigate();
  React.useEffect(() => {
    logout();
    navigate('/');
  }, []);

  return <LoadingBlock />;
}