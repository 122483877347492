import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  getOutstandingInvoices,
  getBlockedShipments,
  getOrdersMissingDocs,
  getUserIssues,
  getCompletedDocs,
} from "../services/reportsService";
import fileDownload from "js-file-download";
import { ToastContext } from "../contexts/ToastContext";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { UserContext } from "../contexts/UserContext";
import DateRangePickerCustom from "./DateRangePickerCustom";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";

interface FilterOptions {
  Account?: string;
  User?: string;
  Status?: string;
  ReportType?: string;
  Date?: [Date, Date];
}

export default function CardFilterPopover({
  accountIds,
  users,
  status,
  type,
  target,
}: any) {
  const theme = useTheme();
  const { user } = React.useContext(UserContext);
  const { permissions } = React.useContext(PermissionsContext);
  const { setExceptionMessage } = React.useContext(ToastContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [acctFilter, setAcctFilter] = React.useState("");
  const [userFilter, setUserFilter] = React.useState("");
  const [statusFilter, setStatusFilter] = React.useState("");
  const [typeFilter, setTypeFilter] = React.useState("");
  const [dateFilter, setDateFilter] = React.useState([
    new Date(new Date().getFullYear(), 0, 1),
    new Date(new Date().getFullYear(), 11, 31),
  ]);
  const [fromDate, setFromDate] = React.useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [toDate, setToDate] = React.useState(
    new Date(new Date().getFullYear(), 11, 31)
  );
  //const [filterOptions, setFilterOptions] = React.useState<FilterOptions>();

  const handleAcctDropdown = (event: SelectChangeEvent) => {
    setAcctFilter(event.target.value);
  };
  const handleUserDropdown = (event: SelectChangeEvent) => {
    setUserFilter(event.target.value);
  };
  const handleStatusDropdown = (event: SelectChangeEvent) => {
    setStatusFilter(event.target.value);
  };
  const handleTypeDropdown = (event: SelectChangeEvent) => {
    console.log(event.target.value);
    setTypeFilter(event.target.value);
    //console.log(typeFilter);
  };

  const handleFilteredDownload = async (target: any) => {
    if (!user) return;
    const filterOptions = {
      Account: acctFilter,
      User: userFilter,
      Status: statusFilter,
      ReportType: typeFilter,
      Date: [fromDate, toDate],
    };
    // console.log(filterOptions);
    const users = !permissions?.hierarchy
      ? user.id
      : [...permissions?.hierarchy, user.id];
    //console.log(filterOptions);
    switch (target) {
      case 1:
        setIsLoading(true);
        getOutstandingInvoices(
          users,
          (data: any) => {
            //console.log(data)
            fileDownload(data, `Invoice_Report_${new Date().getTime()}.xlsx`);
            setIsLoading(false);
          },
          filterOptions,
          (ex: any) => {
            setExceptionMessage(ex);
            setIsLoading(false);
          }
        );
        break;
      case 2:
        setIsLoading(true);
        getBlockedShipments(
          users,
          (data: any) => {
            //console.log(data)
            fileDownload(data, `Shipment_Report_${new Date().getTime()}.xlsx`);
            setIsLoading(false);
          },
          filterOptions,
          (ex: any) => {
            setExceptionMessage(ex);
            setIsLoading(false);
          }
        );
        break;
      case 3:
        setIsLoading(true);
        getOrdersMissingDocs(
          users,
          (data: any) => {
            //console.log(data)
            fileDownload(
              data,
              `Missing_Docs_Report_${new Date().getTime()}.xlsx`
            );
            setIsLoading(false);
          },
          filterOptions,
          (ex: any) => {
            setExceptionMessage(ex);
            setIsLoading(false);
          }
        );
        break;
      case 4:
        //if (filter == "") setFilter(user.id);
        setIsLoading(true);
        getUserIssues(
          undefined,
          (data: any) => {
            //console.log(data)
            fileDownload(
              data,
              `User_Issues_Report_${new Date().getTime()}.xlsx`
            );
            setIsLoading(false);
          },
          filterOptions,
          (ex: any) => {
            setExceptionMessage(ex);
            setIsLoading(false);
          }
        );
        break;
      case 5:
        // if (filter == "") setFilter("invoice");
        if (!fromDate) setFromDate(new Date(new Date().getFullYear(), 0, 1));
        if (!toDate) setToDate(new Date(new Date().getFullYear(), 11, 31));

        setIsLoading(true);
        getCompletedDocs(
          undefined,
          fromDate,
          toDate,
          (data: any) => {
            //console.log(data)
            fileDownload(
              data,
              `Completed_${
                filterOptions?.ReportType
              }_Report_${new Date().getTime()}.xlsx`
            );
            setIsLoading(false);
          },
          filterOptions,
          (ex: any) => {
            setExceptionMessage(ex);
            setIsLoading(false);
          }
        );
        break;
    }
  };

  //console.log(status);
  return (
    <Paper sx={{ justifyContent: "center" }}>
      <Box justifyContent={"center"}>
        <Card variant="outlined">
          <CardHeader
            title="Filter Options"
            component="div"
            align="center"
          ></CardHeader>
          <Divider />
          <CardContent>
            {target == 3 ? (
              <Typography component={"div"} variant="h6">
                Select Shipper
                <Divider />
              </Typography>
            ) : (
              <Typography component={"div"} variant="h6">
                Select Account
                <Divider />
              </Typography>
            )}

            <FormControl sx={{ m: 2, minWidth: 120 }}>
              <InputLabel variant="outlined" id="account-select">
                Accounts
              </InputLabel>
              <Select
                labelId="account-select"
                id="account-select-helper"
                value={acctFilter}
                label="Accounts"
                onChange={handleAcctDropdown}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {accountIds.map((dd: any) => (
                  <MenuItem value={dd.id}>{dd.value}</MenuItem>
                ))}
              </Select>
              <FormHelperText></FormHelperText>
            </FormControl>

            <Typography component={"div"} variant="h6">
              Select User Owner
              <Divider />
            </Typography>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="user-select">Users</InputLabel>
              <Select
                labelId="user-select"
                id="user-select-helper"
                value={userFilter}
                label="Users"
                onChange={handleUserDropdown}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {users.map((dd: any) => (
                  <MenuItem value={dd.id}>{dd.value}</MenuItem>
                ))}
              </Select>
              <FormHelperText></FormHelperText>
            </FormControl>

            <Typography component={"div"} variant="h6">
              Select Status
              <Divider />
            </Typography>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="status-select">Status</InputLabel>
              <Select
                labelId="status-select"
                id="status-select-helper"
                value={statusFilter}
                label="Status"
                onChange={handleStatusDropdown}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {target == 1 || target == 2 || target == 4
                  ? status
                      .filter((s: any) => s.id == 3 || s.id == 4)
                      .map((dd: any) => (
                        <MenuItem value={dd.id}>{dd.value}</MenuItem>
                      ))
                  : target == 3
                  ? status
                      .filter(
                        (s: any) =>
                          s.id == 3 || s.id == 4 || s.id == 5 || s.id == 6
                      )
                      .map((dd: any) => (
                        <MenuItem value={dd.id}>{dd.value}</MenuItem>
                      ))
                  : target == 5
                  ? typeFilter == "shipment"
                    ? status
                        .filter((s: any) => s.id == 9 || s.id == 7)
                        .map((dd: any) => (
                          <MenuItem value={dd.id}>{dd.value}</MenuItem>
                        ))
                    : status
                        .filter((s: any) => s.id == 6 || s.id == 7)
                        .map((dd: any) => (
                          <MenuItem value={dd.id}>{dd.value}</MenuItem>
                        ))
                  : status.map((dd: any) => (
                      <MenuItem value={dd.id}>{dd.value}</MenuItem>
                    ))}
              </Select>
              <FormHelperText></FormHelperText>
            </FormControl>

            {target == 5 && (
              <>
                <Typography component={"div"} variant="h6">
                  Select Report Type
                  <Divider />
                </Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="status-select">Report</InputLabel>
                  <Select
                    labelId="report-select"
                    id="report-select-helper"
                    value={typeFilter}
                    label="Report"
                    onChange={handleTypeDropdown}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {type.map((dd: any) => (
                      <MenuItem value={dd.id}>{dd.value}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </>
            )}
            <Typography component={"div"} variant="h6">
              Select Last Updated Date (Range)
              <Divider />
            </Typography>
            <DateRangePickerCustom
              setFromDate={setFromDate}
              setToDate={setToDate}
            />
          </CardContent>
          <Typography
            component={"div"}
            gutterBottom
            align="justify"
            sx={{ justifyContent: "center", alignContent: "center" }}
          >
            <Button
              aria-describedby={target}
              variant="contained"
              onClick={() => handleFilteredDownload(target)}
            >
              Download
            </Button>
          </Typography>
        </Card>
      </Box>
    </Paper>
  );
}
