import React from "react";
import AccountsScreen from "../screens/AccountsScreen";
import UserAdminScreen from "../screens/Admin/UserAdminScreen";
import UserEditScreen from "../screens/Admin/UserEditScreen";
import Dashboard from "../screens/Dashboard";
import DataVisualizationMap from "../screens/DataVisualizationMap";
//import OrdersScreen from "../screens/OrdersScreen";
import ShipmentsScreen from "../screens/ShipmentsScreen";
import Profile2 from "../components/Profile2";
import Reports from "../screens/ReportsScreen";
import { Screens } from "../interfaces/Screen";
import AdminScreen from "../screens/AdminScreen";
import AccountPreferencesScreen from "../screens/Admin/AccountPreferences";
import InvoicesScreen from "../screens/InvoicesScreen";
//import ApprovalsScreen from "../screens/ApprovalsScreen";
import CustomerTerms from "../screens/Admin/CustomerTerms";
import UnmatchedScreen from "../screens/UnmatchedImagesScreen";
import BillsScreen from "../screens/BillsScreen";
import ApprovalsScreen2 from "../screens/ApprovalsScreen2";

export const ScreenDict: Array<Screens> = [
  { name: "Home", path: "/Home", pageRef: <Dashboard /> },
  { name: "Home", path: "/", pageRef: <Dashboard /> },
  { name: "Accounts", path: "/Accounts", pageRef: <AccountsScreen /> },
  { name: "Map", path: "/Map", pageRef: <DataVisualizationMap /> },
  { name: "Profile", path: "/Profile", pageRef: <Profile2 /> },
  { name: "Approvals", path: "/Approvals", pageRef: <ApprovalsScreen2 /> },
  { name: "Unmatched", path: "/Unmatched", pageRef: <UnmatchedScreen /> },
  { name: "Shipments", path: "/Shipments", pageRef: <ShipmentsScreen /> },
  { name: "Invoices", path: "/Invoices", pageRef: <InvoicesScreen /> },
  { name: "Bills", path: "/Bills", pageRef: <BillsScreen /> },
  { name: "Reports", path: "/Reports", pageRef: <Reports /> },
  { name: "Admin", path: "/Admin", pageRef: <AdminScreen /> },
  { name: "Admin", path: "/Admin/Users", pageRef: <UserAdminScreen /> },
  { name: "Admin", path: "/Admin/Users/:userid", pageRef: <UserEditScreen /> },
  {
    name: "Admin",
    path: "/Admin/Account",
    pageRef: <AccountPreferencesScreen />,
  },
  {
    name: "Admin",
    path: "/Admin/Terms",
    pageRef: <CustomerTerms />,
  },
];
