import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Paper,
  TextField,
  Box,
  Typography,
  Stack,
  Divider,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@mui/material";
import React from "react";
import { UserContext } from "../../contexts/UserContext";
import { ToastContext } from "../../contexts/ToastContext";
import { PermissionsContext } from "../../contexts/PermissionsContext";
import {
  addSettings,
  getSettings,
  updateSetting,
} from "../../services/adminService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Popover from "@mui/material/Popover";
import { getAccountsByParentAccount } from "../../services/accountService";
import {
  createNewTerm,
  editTerms,
  getAccountTerms,
  getTermsOptions,
} from "../../services/termsService";
import { Data } from "@react-google-maps/api";

interface CustomerTerms {
  id?: string;
  type?: string;
  name?: string;
  value?: string;
}
export default function CustomerTerms() {
  const { permissions } = React.useContext(PermissionsContext);
  const [loading, setLoading] = React.useState(false);
  const [accountsList, setAccountsList] = React.useState([]);
  const [accountSelect, setAccountSelect] = React.useState([""]);
  const [selectedAccountId, setSelectedAccountId] = React.useState("");
  const [availableTerms, setAvailableTerms] = React.useState<any>();
  const [account, setAccount] = React.useState("");
  const [modelTypeVis, setModelTypeVis] = React.useState(false);
  const [modelTypeSelect, setModelTypeSelect] = React.useState("");
  const [modelType, setModelType] = React.useState("");
  const [accountTerms, setAccountTerms] = React.useState<any>([]);
  const [editTerm, setEditTerm] = React.useState("");
  const [termDays, setTermDays] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [newTermVis, setNewTermVis] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [reload, setReload] = React.useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const paymentTerms = [
    "NET",
    "PIA",
    "EOM",
    "MFI",
    "POD",
    "CIA",
    "Contra",
    "Stage",
    "CND",
  ];
  const specialInstructionOptions = ["Note", "Payment", "Invoicing"];
  React.useEffect(() => {
    if (!permissions) return;
    setLoading(true);
    getAccountsByParentAccount(
      permissions.primaryAccount[0],
      (data: any) => {
        //console.log(data);
        setLoading(false);
        setAccountsList(data[0]);
        setAccountSelect(data[0].map((d: any) => d.name));
      },
      (err: any) => {
        console.log(err);
        setLoading(false);
      }
    );

    getTermsOptions(
      (data: any) => {
        //console.log(data);
        setAvailableTerms(data);
      },
      (err: any) => {
        console.log(err);
      }
    );
  }, [reload]);

  const handleSelectAccount = (e: any) => {
    //console.log(e.target.value);
    setAccount(e.target.value);
    setAccountTerms([]);
    let id = accountsList
      .filter((al: any) => al.name == e.target.value)
      .map((a: any) => a.id)[0];

    setSelectedAccountId(id);
    if (e.target.value) {
      setModelTypeVis(true);
    } else {
      setModelTypeVis(false);
    }
    setModelTypeSelect(availableTerms[0].name);
  };

  const handleSelectTermType = (e: any) => {
    setModelTypeSelect(e.target.value);
    const modelTypeId = availableTerms
      .filter((at: any) => at.name == e.target.value)
      .map((t: any) => t.id)[0];

    setModelType(modelTypeId);
    setDescription("");
    setLoading(true);
    getAccountTerms(
      selectedAccountId,
      modelTypeId,
      (data: any) => {
        console.log(data);
        setAccountTerms(data);
        setLoading(false);
      },
      (err: any) => {
        console.log(err);
        setLoading(false);
      }
    );
  };

  const handleEditTerm = (e: any) => {
    //.log(e.target.value);
    setEditTerm(e.target.value);
    setTermDays("");
  };

  const handleSetTermDays = (e: any) => {
    console.log(e.target.value);
    setTermDays(e.target.value);
  };

  const handleSetDescription = (e: any) => {
    // console.log(e.target.value);
    setDescription(e.target.value);
  };

  const handleSubmitChanges = () => {
    //do stuff
    setLoading(true);
    editTerms(
      selectedAccountId,
      modelType,
      editTerm,
      termDays,
      description,
      (data: any) => {
        console.log(data);
        setLoading(false);
        alert(data);
      },
      (err: any) => {
        console.log(err);
        setLoading(false);
        alert(err);
      }
    );
    //setSelectedAccountId("");
    setModelType("");
    setEditTerm("");
    setTermDays("");
    setDescription("");
    setReload(!reload);
  };

  const handleAddNewTerm = (e: any) => {
    // console.log(modelType);
    // console.log(selectedAccountId);
    // console.log(editTerm);
    setAnchorEl(e.target);
    setNewTermVis(true);
  };
  const handlSubmitNewTerm = (e: any) => {
    setLoading(true);
    if (modelTypeSelect == "Special Instructions") {
      createNewTerm(
        selectedAccountId,
        modelType,
        editTerm,
        "",
        description,
        (data: any) => {
          setLoading(false);
          alert(data);
          console.log(data);
        },
        (err: any) => {
          console.log(err);
          setLoading(false);
          alert(err);
        }
      );
    } else {
      createNewTerm(
        selectedAccountId,
        modelType,
        editTerm,
        termDays,
        description,
        (data: any) => {
          console.log(data);
          setLoading(false);
          alert(data);
        },
        (err: any) => {
          console.log(err);
          setLoading(false);
          alert(err);
        }
      );
    }
    setReload(!reload);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //console.log(modelTypeSelect);
  //console.log(modelTypeSelect);
  return (
    <Paper>
      <Card>
        <FormControl sx={{ m: 1, minWidth: "75%" }}>
          <InputLabel id="demo-simple-select-autowidth-label">
            Select Account to Edit Terms
          </InputLabel>
          <Select
            labelId="account-select"
            id="account-select"
            value={account}
            onChange={handleSelectAccount}
            autoWidth
            label="Account"
            sx={{ backgroundColor: "whitesmoke" }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {accountSelect.map((al: any, index: any) => (
              <MenuItem key={index} value={al}>
                {al}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {modelTypeVis && (
          <FormControl sx={{ m: 1, minWidth: "75%" }}>
            <FormLabel id="modelType select">Term Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="radio-button-group-modelType"
              name="model-type-radio-group"
              onChange={handleSelectTermType}
            >
              {availableTerms.map((at: any) => (
                <FormControlLabel
                  value={at.name}
                  control={<Radio />}
                  label={at.name}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
        {loading && <CircularProgress />}
        {modelTypeVis && accountTerms.length < 1 ? (
          <Button variant="contained" onClick={handleAddNewTerm}>
            Add Term
          </Button>
        ) : (
          //accountTerms &&
          accountTerms.map((at: any, index: any) => (
            <Card
              sx={{ minWidth: `${100 / accountTerms.length}%` }}
              variant="outlined"
            >
              <CardHeader
                title={`${at.name} ${at.value}`}
                subheader={at.description}
              />
              <CardContent>
                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Select New Term Type
                  </InputLabel>
                  {modelTypeSelect != "Special Instructions" ? (
                    <Select
                      labelId="account-select"
                      id="account-select"
                      value={editTerm}
                      onChange={handleEditTerm}
                      autoWidth
                      label="Account"
                      sx={{ backgroundColor: "whitesmoke" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {paymentTerms.map((t: any, index: any) => (
                        <MenuItem key={index} value={t}>
                          {t}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      labelId="account-select"
                      id="account-select"
                      value={editTerm}
                      onChange={handleEditTerm}
                      autoWidth
                      label="Account"
                      sx={{ backgroundColor: "whitesmoke" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {specialInstructionOptions.map((t: any, index: any) => (
                        <MenuItem key={index} value={t}>
                          {t}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
                {(editTerm == "NET" || editTerm == "MFI") && (
                  <>
                    <Typography>Set Number of Days:</Typography>
                    <TextField
                      label="Days"
                      name="Days"
                      onChange={handleSetTermDays}
                      required
                      value={termDays}
                      variant="outlined"
                    />
                  </>
                )}
                {editTerm && (
                  <>
                    <Typography>Add Description</Typography>
                    <TextField
                      label="Description"
                      name="Description"
                      onChange={handleSetDescription}
                      required
                      value={description}
                      variant="outlined"
                    />
                  </>
                )}
              </CardContent>
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={handleSubmitChanges}
              >
                Submit Changes
              </Button>
            </Card>
          ))
        )}
      </Card>
      {newTermVis && modelTypeSelect != "Special Instructions" ? (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{ width: "auto" }}
        >
          <Card>
            <CardHeader title={`Rule for: ${modelTypeSelect}`} />
            <CardContent>
              <FormControl sx={{ m: 1, minWidth: "75%" }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Select New Term Type
                </InputLabel>
                <Select
                  labelId="account-select"
                  id="account-select"
                  value={editTerm}
                  onChange={handleEditTerm}
                  autoWidth
                  label="Account"
                  sx={{ backgroundColor: "whitesmoke" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {paymentTerms.map((t: any, index: any) => (
                    <MenuItem key={index} value={t}>
                      {t}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {(editTerm == "NET" || editTerm == "MFI") && (
                <>
                  <Typography>Set Number of Days:</Typography>
                  <TextField
                    label="Days"
                    name="Days"
                    onChange={handleSetTermDays}
                    required
                    value={termDays}
                    variant="outlined"
                  />
                </>
              )}
              {editTerm && (
                <>
                  <Typography>Add Description</Typography>
                  <TextField
                    label="Description"
                    name="Description"
                    onChange={handleSetDescription}
                    required
                    value={description}
                    variant="outlined"
                  />
                </>
              )}
            </CardContent>
          </Card>
          <Button
            variant="contained"
            sx={{ m: 1 }}
            onClick={handlSubmitNewTerm}
          >
            Set New Rule
          </Button>
        </Popover>
      ) : (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{ width: "auto" }}
        >
          <Card sx={{ minWidth: "33%", padding: 2 }}>
            <CardHeader title="Add Special Instructions" />
            <FormControl sx={{ minWidth: "100%" }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                Select Special Instruction Type
              </InputLabel>
              <Select
                labelId="si-select"
                id="si-select"
                value={editTerm}
                onChange={handleEditTerm}
                autoWidth
                label="Account"
                sx={{ backgroundColor: "whitesmoke" }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {specialInstructionOptions.map((t: any, index: any) => (
                  <MenuItem key={index} value={t}>
                    {t}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Special Instruction"
              name="Special Instruction"
              onChange={handleSetDescription}
              required
              value={description}
              variant="outlined"
            />
          </Card>
          <Button
            variant="contained"
            sx={{ m: 1 }}
            onClick={handlSubmitNewTerm}
          >
            Set New Rule
          </Button>
        </Popover>
      )}
    </Paper>
  );
}
