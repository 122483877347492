import React from "react";
import { Order } from "../interfaces/Order";
import { OrderContextState } from "../interfaces/OrderContextState";
import { UserContext } from "./UserContext";
import {getOrders} from '../services/orderService'
import { ToastContext } from "../contexts/ToastContext";

export const OrderContext = React.createContext<OrderContextState>({
  orders: null,
  loadOrders: () => { }
});

interface Props {
  children?: React.ReactNode,
}
export const OrderProvider = ({ children }: Props) => {
  const [orders, setOrders] = React.useState<Array<Order> | null>(null);
  const { getUser } = React.useContext(UserContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const { setExceptionMessage } = React.useContext(ToastContext);

  const loadOrders = async () => {
    let orders: Array<Order> | null = null;
    var user = getUser();
   

    if (!user) return;

    try {
        getOrders(user.id, (data:any)=>{
        setOrders(data);
      },(ex:any) => {
        setExceptionMessage(ex);
        setIsLoading(false);
      })
      
    } catch (error) {
      console.log('Something bad happened while trying to get the Orders from the api.');
      console.log(error);
    }
    
  }

  return (
    <OrderContext.Provider value={{ orders, loadOrders }}>
      {children}
    </OrderContext.Provider>
  );
}

