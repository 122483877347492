import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import InsertChartIcon from "@mui/icons-material/InsertChartOutlined";
import React from "react";
import { Order } from "../interfaces/Order";
import { styled } from "@mui/material/styles";
import { circularProgressClasses } from "@mui/material/CircularProgress";
import { useNavigate } from "react-router";

interface Props {
  props: Array<Order> | null;
}

export const PercentageNeedingAttention = ({ props }: Props) => {
  const navigate = useNavigate();
  const handleClick = (e: any) => {
    navigate(`/Shipments/${e.target.textContent}`);
  };
  let percentage: number;

  if (!props) {
    percentage = 0.0;
  } else {
    const totalCount = props.length;
    const needsAttention = props.filter(
      (ord) => ord.statusId == 3 || ord.statusId == 4
    ).length;
    percentage = needsAttention / totalCount;
  }

  const progress = Math.round(percentage * 100);
  //console.log(progress)

  return (
    <Card
      sx={{
        height: "300px",
        margin: "10px",
        width: "25%",
        display: "flex",
        flexDirection: "column",
      }}
      {...props}
    >
      {/* <CardActionArea onClick={(e) => handleClick(e)}> */}
        <CardHeader
          avatar={
            <Avatar
              sx={{
                backgroundColor: "warning.main",
                height: 56,
                width: 56,
              }}
            >
              <InsertChartIcon />
            </Avatar>
          }
          title="Percent of Orders Needing Attention"
          titleTypographyProps={{ variant: "overline" }}
        />
      {/* </CardActionArea> */}
      <CardContent
        sx={{
          position: "static",
          display: "inline-flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            justifyItems: "center",
          }}
        >
          <CircularProgress
            variant="determinate"
            sx={{
              color: (theme) =>
                theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
            }}
            size={150}
            thickness={4}
            {...props}
            value={100}
          />
          <CircularProgress
            variant="determinate"
            //disableShrink
            sx={{
              color: (theme) =>
                theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
              animationDuration: "550ms",
              position: "absolute",
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: "round",
              },
            }}
            size={150}
            thickness={4}
            value={progress}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              color="text.secondary"
            >{`${progress}%`}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
