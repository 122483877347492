import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
  Stack,
} from "@mui/material";
import { report } from "process";
import React from "react";
import { ReportsList } from "../../utils/reports.module";

interface ReportSelector {
  id: number;
  value: string;
}
export default function ReportsSelector({
  reports,
  selectReports,
  setSelectReports,
}: any) {
  const [reportSelector, setReportSelector] = React.useState([""]);
  const handleCheckBox = (e: any) => {
    if (selectReports.includes(e.target.id)) {
      setSelectReports((prev: any) =>
        prev.filter((id: any) => id !== e.target.id)
      );
    } else {
      setSelectReports((prev: any) => [...prev, e.target.id]);
    }
  };

  React.useEffect(() => {
    const reportDict = ReportsList.sort((a, b) => a.id - b.id);
    //console.log(reportDict);
    const reportDict2 = reportDict.map((rl: any) => {
      return rl.name;
    });
    //console.log(reportDict2);
    setReportSelector(() => reportDict2);
  }, []);

  //console.log(reports);
  return (
    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
      <FormLabel component={"legend"}>
        Select Available Reports for User
      </FormLabel>
      <FormGroup>
        <Stack flexDirection={"row"}>
          {reports.map((r: any) => (
            <FormControlLabel
              control={<Checkbox id={r.id} />}
              label={reportSelector[r.value - 1]}
              id={r.id}
              onChange={handleCheckBox}
            />
          ))}
        </Stack>
      </FormGroup>
    </FormControl>
  );
}
