import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CardActions,
  Collapse,
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  Popover,
  Backdrop,
} from "@mui/material";
import { IconButtonProps } from "@mui/material/IconButton";
import { SelectChangeEvent } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/Download";
import LoadingBlock from "../components/LoadingBlock";
import { ToastContext } from "../contexts/ToastContext";
import {
  getOutstandingInvoices,
  getBlockedShipments,
  getOrdersMissingDocs,
  getUserIssues,
  getCompletedDocs,
  getAgingInvoices,
} from "../services/reportsService";
import { UserContext } from "../contexts/UserContext";
import fileDownload from "js-file-download";
import { getUserAccountsIds } from "../services/userAccountService";
import { Reports } from "../interfaces/Report";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { getUserList } from "../services/userService";
import { newDate } from "../utils/utils";
import CardFilterPopover from "./ReportCardFilters";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

interface Dropdown {
  type: string;
  id: string;
  value: string | any;
}

const statuses = {
  1: "New",
  2: "Active",
  3: "Needs Attention",
  4: "On Hold",
  5: "Ready To Bill",
  6: "Completed",
  7: "Canceled",
  8: "Ready for Approval",
  9: "Delivered",
};

export const ReportCard = ({ id, name, description, icon }: Reports) => {
  const { user } = React.useContext(UserContext);
  const [expanded, setExpanded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const { setExceptionMessage } = React.useContext(ToastContext);
  const { permissions } = React.useContext(PermissionsContext);
  const userList = permissions?.hierarchy?.flatMap((h) => h);
  const [accountIds, setAccountIds] = React.useState([{}]);
  const [status, setStatus] = React.useState([{}]);
  const [users, setUsers] = React.useState([{}]);
  const [type, setType] = React.useState([{}]);

  const [fromDate, setFromDate] = React.useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [toDate, setToDate] = React.useState(
    new Date(new Date().getFullYear(), 11, 31)
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleDownload = async (target: any) => {
    if (!user) return;
    const users = !permissions?.hierarchy
      ? user.id
      : [...permissions?.hierarchy, user.id];
    console.log(target);
    switch (target) {
      case "download-1":
        setIsLoading(true);
        getOutstandingInvoices(
          users,
          (data: any) => {
            //console.log(data)
            fileDownload(data, `Invoice_Report_${new Date().getTime()}.xlsx`);
            setIsLoading(false);
          },
          undefined,
          (ex: any) => {
            console.log(ex);
            setExceptionMessage(ex);
            setIsLoading(false);
          }
        );
        break;
      case "download-2":
        setIsLoading(true);
        getBlockedShipments(
          users,
          (data: any) => {
            //console.log(data)
            fileDownload(data, `Shipment_Report_${new Date().getTime()}.xlsx`);
            setIsLoading(false);
          },
          undefined,
          (ex: any) => {
            setExceptionMessage(ex);
            setIsLoading(false);
          }
        );
        break;
      case "download-3":
        setIsLoading(true);
        getOrdersMissingDocs(
          users,
          (data: any) => {
            //console.log(data)
            fileDownload(
              data,
              `Missing_Docs_Report_${new Date().getTime()}.xlsx`
            );
            setIsLoading(false);
          },
          undefined,
          (ex: any) => {
            setExceptionMessage(ex);
            setIsLoading(false);
          }
        );
        break;
      case "download-4":
        setIsLoading(true);
        getUserIssues(
          userList,
          (data: any) => {
            //console.log(data)
            fileDownload(
              data,
              `User_Issues_Report_${new Date().getTime()}.xlsx`
            );
            setIsLoading(false);
          },
          (ex: any) => {
            setExceptionMessage(ex);
            setIsLoading(false);
          }
        );
        break;
      case "download-5":
        setIsLoading(true);
        getCompletedDocs(
          "invoice",
          fromDate,
          toDate,
          (data: any) => {
            //console.log(data)
            fileDownload(
              data,
              `Completed_Invoices_Report_${new Date().getTime()}.xlsx`
            );
            setIsLoading(false);
          },
          (ex: any) => {
            setIsLoading(false);
            setExceptionMessage(ex);
          }
        );
        break;
      case "download-6":
        setIsLoading(true);
        getAgingInvoices(
          users,
          (data: any) => {
            fileDownload(
              data,
              `Invoice_Aging_Report_${new Date().getTime()}.xlsx`
            );
            setIsLoading(false);
          },
          (ex: any) => {
            setExceptionMessage(ex);
            setIsLoading(false);
          }
        );
        break;
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setBackdropOpen(!backdropOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setBackdropOpen(false);
  };
  const open = Boolean(anchorEl);
  const id2 = open ? "simple-popover" : undefined;

  React.useEffect(() => {
    if (!user) return;
    const users = !permissions?.hierarchy
      ? user.id
      : [...permissions?.hierarchy, user.id];
    //console.log(users);

    getUserAccountsIds(
      users,
      (data: any) => {
        let accountIds = data.map((a: any) => a.accountId);
        let accountIdsFlat = [...new Set(accountIds.flat(1))];
        let accountNames = data.map((n: any) => n.account.name);
        let accountNamesFlat = [...new Set(accountNames.flat(1))];
        //console.log(accountNamesFlat);

        let dropdown = accountIdsFlat.map((datum: any, index: number) => {
          var acctObj = {
            //type: "Account",
            id: datum,
            value: accountNamesFlat[index],
          };
          return acctObj;
        });
        return (
          setExpanded(!expanded),
          //setDropdown(dropdown),
          setIsLoading(false),
          setAccountIds(dropdown)
        );
      },
      (ex: any) => {
        setExceptionMessage(ex);
        setIsLoading(false);
      }
    );

    let statusDropdown = []; //new Array<Dropdown>();
    for (let i = 0; i < Object.keys(statuses).length; i++) {
      statusDropdown.push({
        // type: "Status",
        id: Object.keys(statuses)[i],
        value: Object.values(statuses)[i],
      });
    }
    //console.log(statusDropdown)
    //setDropdown(statusDropdown);
    setStatus(statusDropdown);
    setIsLoading(false);

    if (!permissions?.hierarchy) {
      let dropdown1: any = {
        //type: "User",
        id: user.id,
        value: `${user.lastName}, ${user.firstName}`,
      };
      return (
        setExpanded(!expanded),
        //setDropdown([dropdown1]),
        setUsers([dropdown1]),
        setIsLoading(false)
      );
    }

    getUserList(
      userList,
      (data: any) => {
        let dropdown = data.map((datum: any) => {
          var userObj = {
            //type: "User",
            id: datum.id,
            value: `${datum.lastName}, ${datum.firstName}`,
          };
          return userObj;
        });
        //console.log(dropdown)
        return (
          setExpanded(!expanded),
          // setDropdown(dropdown),
          setUsers(dropdown),
          setIsLoading(false)
        );
      },
      (ex: any) => {
        setExceptionMessage(ex);
        setIsLoading(false);
      }
    );

    // console.log(Object.keys(status).length)
    let typeDropdown = []; //new Array<Dropdown>();
    typeDropdown.push(
      {
        //type: "Report",
        id: "invoice",
        value: "Invoice",
      },
      {
        //type: "Report",
        id: "order",
        value: "Order",
      },
      {
        //type: "Report",
        id: "shipment",
        value: "Shipment",
      }
    );
    //console.log(statusDropdown)
    //setDropdown(typeDropdown);
    setType(typeDropdown);
    setIsLoading(false);
  }, []);

  if (isLoading) return <LoadingBlock />;
  //console.log(Object.values(reports))

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <CardHeader
      // action={
      //   <IconButton aria-label="settings">
      //     <MoreVertIcon />
      //   </IconButton>
      // }
      />
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pb: 3,
          }}
        >
          <Avatar
            alt="Product"
            variant="rounded"
            sx={{ height: 100, width: 100 }}
          >
            {icon}
          </Avatar>
        </Box>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          {name}
        </Typography>
        <Typography align="center" color="textPrimary" variant="body1">
          {description}
        </Typography>
      </CardContent>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <CardActions id={`card-${id}`}>
        <IconButton
          aria-label={`download`}
          id={`download-${id}`}
          onClick={(e) => handleDownload(e.currentTarget.id)}
        >
          <DownloadIcon sx={{ color: "primary.main" }} />
        </IconButton>
        {id != 1 && id != 4 && id != 6 ? (
          <>
            <Typography align="center">
              <Button
                aria-describedby={id2}
                variant="outlined"
                onClick={handleClick}
              >
                Filter
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={backdropOpen}
                  onClick={handleClose}
                />
              </Button>
            </Typography>
            <Popover
              id={id2}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              anchorReference={"anchorEl"}
            >
              <CardFilterPopover
                users={users}
                accountIds={accountIds}
                status={status}
                type={type}
                target={id}
              />
            </Popover>
          </>
        ) : (
          <></>
        )}
      </CardActions>
    </Card>
  );
};
