import { FileDetails } from "../interfaces/FileDetails";
import { User } from "../interfaces/User";
import { baseApi, baseApiNoApiKey } from "./baseService";

const API = {
  getUsers: "/api/user/all/",
  getUser: "/api/user/",
  updateUser: "/api/user/update/",
  registerUser: "/register",
  deleteUser: "/api/user/delete/",
  getUserList: "/api/user/userList",
  getDashboardHierarchy: "/api/user/dashboardHierarchy",
  uploadImage: "/api/user/uploadImage",
  login: "/login",
  getTokenUser: "/register/info",
  createSystemUser: "/api/user/create",
};

interface UserData {
  userId?: string;
  token?: string;
  managerId?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  locationId?: string;
  password?: string;
  isDeleted?: boolean;
  imageUrl?: string;
  userData?: {
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    phoneNumber?: string;
  };
  address?: {
    address: any;
    city: any;
    stateShort: any;
    stateLong: any;
    zip: any;
    latitude: any;
    longitude: any;
  };
}
export const login = async (
  emailAddress: string,
  password: string,
  onSuccess: (data: any) => void,
  onError?: Function
) => {
  return baseApiNoApiKey().then((api) =>
    api
      .post(API.login, { emailAddress, password })
      .then(({ data }) => {
        onSuccess(data);
        return data;
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getUser = (
  id: string,
  onSuccess: (data: User) => void,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .get<User>(API.getUser + id)
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getUsers = (
  filter: object,
  onSuccess: (data: Array<User>) => void,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post<Array<User>>(API.getUsers, { filter: filter })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const updateUser = (
  userId: string,
  userData: UserData,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.updateUser + userId, { ...userData })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};
export const updateUserPassword = (
  userId: string,
  password: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.updateUser + userId, { password })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};
export const registerUser = (
  userData: UserData,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.registerUser, { ...userData })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};
export const createSystemUser = (
  userData: UserData,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.createSystemUser, { ...userData })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const deleteUser = (
  userId: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .delete(API.deleteUser + userId)
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getUserList = (
  userList: Array<string> | undefined,
  onSuccess: (data: User) => void,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getUserList, { userList })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getDashboardHierarchy = (
  hierarchy: Array<string> | undefined,
  onSuccess: (data: User) => void,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getDashboardHierarchy, { hierarchy })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const uploadImage = (
  fileDetails: FileDetails,
  userId: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) => {
    console.log(fileDetails);
    var payload = {
      fileDetails,
      userId,
    };
    console.log(payload);
    api
      .post(API.uploadImage, payload)
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      });
  });
};

export const getTokenUser = (
  id: any,
  token: any,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getTokenUser, { id, token })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};
