import React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  IconButton,
  Badge,
  Tooltip,
  Box,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { notificationsAtom, queueMessagesAtom } from "../atoms/atoms";
import { QueueContext } from "../contexts/QueueContext";
interface Props {}

export const NotificationsIconComponent = ({ props }: any) => {
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  // const [queueMessages, setQueueMessages] = useRecoilState(queueMessagesAtom);
  const { queueMessages } = React.useContext(QueueContext);
  const [notificationCount, setNotificationCount] = React.useState(
    notifications.length
  );
  const [anchor, setAnchor] = React.useState(null);
  const [popDown, setPopDown] = React.useState(false);

  const handleOpenNotifications = (event: any) => {
    setAnchor(event.currentTarget);
    setPopDown(!popDown);
    setNotificationCount(0);
    //setNotifications([]);
  };
  const open = Boolean(anchor);
  const id = open ? "simple-popover" : undefined;

  //console.log(notifications)
  const handleRemoveNotification = (event: any) => {
    const newNotifications = notifications.filter(
      (note) => note.message != event.target.textContent
    );
    setNotifications(newNotifications);
  };

  React.useEffect(() => {
    setNotifications(notifications);
    setNotificationCount(notifications.length);
  }, [queueMessages]);

  //I figured we could use this to reflect stuff coming through the queue
  return (
    <IconButton onClick={handleOpenNotifications}>
      <Badge color="error" badgeContent={`${notificationCount}`} max={999}>
        <Tooltip title="See Notifications">
          <NotificationsIcon sx={{ color: "white", fontSize: 30 }} />
        </Tooltip>

        {notifications.length > 0
          ? popDown && (
              <Box sx={{ flexGrow: 0 }}>
                <Menu
                  sx={{ mt: "45px" }}
                  id={"menu-appbar"}
                  anchorEl={anchor}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                >
                  {notifications.map((note: any, index) => (
                    <MenuItem key={index}>
                      <Typography
                        textAlign="center"
                        onClick={(e) => handleRemoveNotification(e)}
                      >
                        {note.message}
                        {/* will add link later */}
                        {/* <Link style={{ textDecoration: 'none', color: "black" }} to={`/Shipments`}>{note.notification}</Link> */}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )
          : null}
      </Badge>
    </IconButton>
  );
};
