import * as React from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import {
  Button,
  Card,
  FormControl,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { getRating, submitRating } from "../services/noteService";

interface RatingNote {
  rating: number;
  note: string;
  date: string;
}

export default function CarrierRating(carrierId: any) {
  const [rating, setRating] = React.useState<number | null>(0);
  const [editRating, setEditRating] = React.useState(false);
  const [notes, setNotes] = React.useState<Array<RatingNote>>();
  const [newRating, setNewRating] = React.useState<number | null>(0);
  const [newNote, setNewNote] = React.useState("");
  const [refresh, setRefresh] = React.useState(false);

  React.useEffect(() => {
    getRating(carrierId.carrierId, (data: any) => {
      let ratingMap = data.map((r: any) => r.rating);
      let noteMap = data.map((d: any) => {
        let newObj: RatingNote = {
          rating: d.rating,
          note: d.note,
          date: new Date(d.createdAt).toLocaleDateString(),
        };
        return newObj;
      });
      let ratingAvg = ratingMap.reduce((a: any, b: any) => a + b, 0) / ratingMap.length;
      setRating(ratingAvg);
      setNotes(noteMap);
    });
  }, [refresh]);

  function rateCarrier() {
    setEditRating(!editRating);
    if (!newRating) return;
    submitRating(
      carrierId.carrierId,
      newRating,
      () => { },
      newNote,
      () => { }
    );
    setNewNote('')
    setRefresh(!refresh);
  }

  return (
    <Box
      sx={{
        "& > legend": { mt: 2 },
        width: "80%"
      }}
    >
      {editRating ? (
        <>
          <Typography component="legend">Carrier Rating</Typography>
          <Rating
            name="simple-controlled"
            value={newRating}
            onChange={(event, newValue) => {
              setNewRating(newValue);
            }}
          />
          <Card sx={{ padding: 1, boxSizing: "border-box", width: "100%", }}>
            <TextField
              id="outlined-multiline-static"
              label="Add Note"
              multiline
              rows={10}
              value={newNote}
              onChange={(newValue) => {
                setNewNote(newValue.target.value);
              }}
              sx={{ margin: 1, width: "98%" }}
            />
          </Card>
          <Button onClick={rateCarrier}>Save</Button>
          <Button
            onClick={() => {
              setEditRating(false);
              setRating((prev) => prev);
              
            }}
          >
            Cancel
          </Button>
        </>
      ) : (
        <>
          <Typography component="legend">Carrier Rating</Typography>
          <Rating name="read-only" value={rating} readOnly />
          <Button onClick={() => setEditRating(true)}>Add Rating</Button>
          <Typography component="legend">Carrier Notes:</Typography>
          <TableContainer sx={{ width: '100%' }}>
            <TableHead>
              <TableRow className="ratingTable">
                <TableCell component={"th"} scope="row">
                  Rating
                </TableCell>
                <TableCell component={"th"} scope="row">
                  Notes
                </TableCell>
                <TableCell component={"th"} scope="row">
                  Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notes ? (
                notes.map((n: any, index: any) => (
                  <TableRow
                    className={index % 2 === 0 ? "EvenRow" : ""}
                    sx={{ "& > *": { borderBottom: "unset", } }}
                  >
                    <TableCell>{n.rating}</TableCell>
                    <TableCell sx={{width:'100%'}}>{n.note}</TableCell>
                    <TableCell>{n.date}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <em>No notes available</em>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </TableContainer>
        </>
      )}
    </Box>
  );
}
