import {
  Alert,
  AlertTitle,
  Button,
  Card,
  FormControl,
  Input,
  InputLabel,
  Paper,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import React from "react";
import { updateInvoiceLine } from "../services/invoiceLineService";

interface Props {
  row: any;
}

export default function InvoiceDescriptionPopover(props: Props) {
  const { row } = props;
  //console.log(row);
  const [description, setDescription] = React.useState(row.description);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [anchorAlert, setAlert] = React.useState(false);
  const [successAlert, setSuccessAlert] = React.useState(false);
  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const openAlert = Boolean(anchorAlert);
  const alertId = openAlert ? "simple-popover" : undefined;

  function handleUpdateDescription() {
    updateInvoiceLine(
      row.id,
      description,
      (data: any) => {
        console.log(data);
        setAlert(true);
        setSuccessAlert(true);
        setTimeout(() => setAlert(false), 3000);
      },
      (ex: any) => {
        console.log(ex);
        setAlert(true);
        setSuccessAlert(false);
        setTimeout(() => setAlert(false), 3000);
      }
    );

    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        anchorReference={"none"}
      >
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          Successfully Updated
        </Alert>
      </Popover>
    );
  }

  return (
    <Paper>
      <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Edit Description
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card sx={{ py: 1, height: 1, boxSizing: "border-box", width: "100%" }}>
          <TextField
            id="outlined-multiline-static"
            label="Description"
            multiline
            rows={10}
            value={description}
            onChange={handleChangeDescription}
            sx={{ margin: 1, width: "97.5%" }}
          />
          <Typography>
            <Button
              aria-describedby={id}
              variant="contained"
              sx={{ margin: 1 }}
              onClick={() => {
                handleUpdateDescription();
                handleClose();
              }}
            >
              Update Description
            </Button>

            <Button
              aria-describedby={id}
              variant="contained"
              sx={{ margin: 1 }}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
          </Typography>
        </Card>
      </Popover>
      {successAlert ? (
        <Popover
          id={alertId}
          open={openAlert}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          anchorReference={"none"}
        >
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            Description Successfully Updated
          </Alert>
        </Popover>
      ) : (
        <Popover
          id={alertId}
          open={openAlert}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          anchorReference={"none"}
        >
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <strong>Description Failed to Update!</strong>
          </Alert>
        </Popover>
      )}
    </Paper>
  );
}
