import { Button } from "@mui/material";
import axios from "axios";
import React from "react";
import { generateInvoicePDF } from "../services/invoiceService";
import { baseApi } from "../services/baseService";
import { UserContext } from "../contexts/UserContext";

export const GenerateInvoicePDF = ({ row }: any) => {
  const [loading, setLoading] = React.useState(false);
  const userCtx = React.useContext(UserContext);

  const handleDownload = async () => {
    setLoading(true);
    try {
      const images = Array.isArray(row.image) ? row.image : [row.image];
      const imageUrls = images.map((obj: any) => obj.file);

      generateInvoicePDF(
        row.id,
        row.orderNumber,
        imageUrls,
        row.shipmentNumber,
        row.customer,
        (data: any) => {
          const blob = new Blob([data], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${row.customer}_invoice_${new Date().toDateString()}.pdf`
          );
          document.body.appendChild(link);
          link.click();
        },
        (err: any) => {
          console.log(err);
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button onClick={handleDownload} disabled={loading}>
      {loading ? "Loading..." : "Generate Invoice"}
    </Button>
  );
};
