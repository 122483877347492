import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  CardHeader,
} from "@mui/material";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useRecoilState } from "recoil";
import { ordersAtom } from "../atoms/atoms";
import { getOrdersAccountScreen } from "../services/orderService";
import { ToastContext } from "../contexts/ToastContext";
import LoadingBlock from "../components/LoadingBlock";
import { PermissionsContext } from "../contexts/PermissionsContext";

// interface Props {
//     orders: Array<Order> | null
// }

export const AccountOrdersCard = ({ accountId, userId }: any) => {
  const [orders, setOrders] = useRecoilState(ordersAtom);
  const toastCtx = React.useContext(ToastContext);
  const { setExceptionMessage } = React.useContext(ToastContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const { permissions } = React.useContext(PermissionsContext);
  React.useEffect(() => {
    getOrdersAccountScreen(
      !permissions?.hierarchy ? userId : [...permissions?.hierarchy, userId],
      accountId,
      (data: any) => {
        setOrders(data);
        setIsLoading(false);
      },
      (ex: any) => {
        setExceptionMessage(ex);
        setIsLoading(false);
      }
    );
  }, []);
  const status: any = {
    1: "New",
    2: "Active",
    3: "Needs Attention",
    4: "On Hold",
    5: "Ready To Bill",
    6: "Completed",
    7: "Canceled",
  };
  const keys = Object.keys(status);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    key: number
  ) => {
    setAnchorEl(null);
    setKey(key);
    setfilter(status[key]);
  };
  const [key, setKey] = React.useState(2);
  const [filter, setfilter] = React.useState(status[2]);
  if (isLoading || !orders) {
    return <LoadingBlock />;
  }

  return (
    <Card
      variant="outlined"
      sx={{
        height: "250px",
        margin: "12px",
        width: "33%",
      }}
    >
      <CardHeader
        action={
          <IconButton
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        }
        avatar={
          <Avatar
            sx={{
              backgroundColor: "blue",
              height: 50,
              width: 50,
            }}
          >
            <ReceiptLongIcon />
          </Avatar>
        }
        title="Orders"
        titleTypographyProps={{ variant: "h5" }}
        subheader={`Total Number ${filter}:`}
        subheaderTypographyProps={{ variant: "subtitle1" }}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {keys.map((key: any, index: number) => {
          return (
            <MenuItem
              key={key}
              onClick={(event) => handleMenuClick(event, key)}
            >
              {status[key]}
            </MenuItem>
          );
        })}
      </Menu>
      <CardContent className="accountsCard">
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Typography color="textPrimary" variant="h2" align="center">
              {orders.filter((ord: any) => ord.statusId == key).length}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
