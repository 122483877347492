import * as React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Collapse,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { getInitials } from "../utils/getInitials";
import LoadingBlock from "../components/LoadingBlock";
import { ToastContext } from "../contexts/ToastContext";
import { UserContext } from "../contexts/UserContext";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { getDashboardHierarchy } from "../services/userService";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface HierarchyData {
  userId: string;
  userFirstName: string;
  userLastName: string;
  profilePic: string;
  activeOrders: number;
  activeShipments: number;
  activeInvoices: number;
}

const ExpandMore = styled((props: any) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const UsersTable = () => {
  const { user } = React.useContext(UserContext);
  const { permissions } = React.useContext(PermissionsContext);
  const [hierarchyData, setHierarchyData] = React.useState(
    Array<HierarchyData>
  );
  const toastCtx = React.useContext(ToastContext);
  const { setExceptionMessage } = React.useContext(ToastContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const userId = user?.id || "";
  const hierarchy = permissions?.hierarchy || [userId];

  React.useEffect(() => {
    try {
      getDashboardHierarchy(
        hierarchy,
        (data: any) => {
          setHierarchyData(data);
          setIsLoading(false);
        },
        (ex: any) => {
          setExceptionMessage(ex);
          setIsLoading(false);
        }
      );
      //console.log(response.data);
    } catch (ex: any) {
      toastCtx.setExceptionMessage(ex);
      setIsLoading(false);
    }
  }, []);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  if (isLoading || !user) return <LoadingBlock />;

  //console.log(hierarchyData)
  const avgInvoices =
    hierarchyData.reduce((a: any, b: any) => a + b.activeInvoices, 0) /
    hierarchyData.length;
  const avgOrders =
    hierarchyData.reduce((a: any, b: any) => a + b.activeOrders, 0) /
    hierarchyData.length;
  const avgShipments =
    hierarchyData.reduce((a: any, b: any) => a + b.activeShipments, 0) /
    hierarchyData.length;
  //console.log(avgShipments)

  if (!hierarchyData) return <Paper />;

  return (
    <fieldset>
      <legend className="legend">
        My Team
        <ExpandMore
          expand={expanded}
          onClick={handleExpand}
          aria-expanded={expanded}
          aria-label="show more"
          sx={{ fontFamily: "unset", fontSize: "medium" }}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </legend>

      <Paper>
        <Stack direction={"row"}>
          <Alert severity="error"> None Active </Alert>
          <Alert severity="warning">Above Average</Alert>
          <Alert severity="success"> Within Average</Alert>
        </Stack>
      </Paper>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Paper variant="elevation">
          <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Active Orders</TableCell>
                    <TableCell>Active Shipments</TableCell>
                    <TableCell>Active Invoices</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hierarchyData.map((hd: any, index: number) => (
                    <TableRow hover key={index}>
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <Avatar src={hd.profilePic} sx={{ mr: 2 }}>
                            {getInitials(
                              `${hd.userFirstName} ${hd.userLastName}`
                            )}
                          </Avatar>
                          <Typography color="textPrimary" variant="body1">
                            {`${hd.userFirstName} ${hd.userLastName}`}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {hd.activeOrders > avgOrders ? (
                          <Alert severity="warning">
                            <AlertTitle>{hd.activeOrders}</AlertTitle>
                          </Alert>
                        ) : hd.activeOrders == 0 ? (
                          <Alert severity="error">
                            <AlertTitle>{hd.activeOrders}</AlertTitle>
                          </Alert>
                        ) : (
                          <Alert severity="success">
                            <AlertTitle>{hd.activeOrders}</AlertTitle>
                          </Alert>
                        )}
                      </TableCell>
                      <TableCell>
                        {hd.activeShipments > avgShipments ? (
                          <Alert severity="warning">
                            <AlertTitle>{hd.activeShipments}</AlertTitle>
                          </Alert>
                        ) : hd.activeShipments == 0 ? (
                          <Alert severity="error">
                            <AlertTitle>{hd.activeShipments}</AlertTitle>
                          </Alert>
                        ) : (
                          <Alert severity="success">
                            <AlertTitle>{hd.activeShipments}</AlertTitle>
                          </Alert>
                        )}
                      </TableCell>
                      <TableCell>
                        {hd.activeInvoices > avgInvoices ? (
                          <Alert severity="warning">
                            <AlertTitle>{hd.activeInvoices}</AlertTitle>
                          </Alert>
                        ) : hd.activeInvoices == 0 ? (
                          <Alert severity="error">
                            <AlertTitle>{hd.activeInvoices}</AlertTitle>
                          </Alert>
                        ) : (
                          <Alert severity="success">
                            <AlertTitle>{hd.activeInvoices}</AlertTitle>
                          </Alert>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
        </Paper>
      </Collapse>
    </fieldset>
  );
};
