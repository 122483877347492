import React, { useEffect, useRef, useState } from "react";
import { Bar, getElementsAtEvent } from "react-chartjs-2";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import SquareIcon from "@mui/icons-material/Square";
import { getShipments } from "../services/shipmentService";
import { ToastContext } from "../contexts/ToastContext";
import LoadingBlock from "../components/LoadingBlock";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { QueueContext } from "../contexts/QueueContext";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function ShipmentChart({
  shipments,
  setShipments,
  user,
  isLoading,
  setIsLoading,
  params,
}) {
  const theme = useTheme();
  const chartRef = useRef();
  const { setExceptionMessage } = React.useContext(ToastContext);
  const [toggle, setToggle] = useState(false);
  const { permissions, getPermissions } = React.useContext(PermissionsContext);
  const { queueMessages } = React.useContext(QueueContext);
  //console.log(shipments);
  useEffect(() => {
    //console.log('hit')
    setIsLoading(true);
    setTimeout(() => {
      if (params) {
        if (params == "Percent of Orders Needing Attention") {
          //console.log(params);
          //console.log(sorted);
          // let barIndex = 2;
          let filterIds = sorted.filter((s) => s.statusId == 3);
          //console.log(filterIds[0]);
          let newDataset = shipments.filter((entry) => {
            for (let i = 0; i < filterIds[0].ids.length; i++) {
              //console.log(filterIds[0].ids);
              if (entry.id == filterIds[0].ids[i]) {
                return true;
              }
            }
          });
          setToggle(true);
          return setShipments(newDataset);
        } else {
          let newDataset = shipments.filter((entry) => entry.id == params);
          setToggle(true);
          return setShipments(newDataset);
        }
      }
    }, 0);
    setIsLoading(false);
  }, [queueMessages.ShipmentMessage]);

  //Event handlers
  const barHandler = (event) => {
    let barEvent = getElementsAtEvent(chartRef.current, event);
    let barIndex = barEvent[0].index;
    let filterIds = sorted[barIndex].ids;
    let newDataset = shipments.filter((entry) => {
      for (let i = 0; i < filterIds.length; i++) {
        if (entry.id == filterIds[i]) {
          return true;
        }
      }
    });
    //console.log(barIndex);
    setToggle(true);
    return setShipments(newDataset);
  };

  const refreshWindow = () => {
    if (!user) return;

    getShipments(
      !permissions.hierarchy ? user.id : [...permissions.hierarchy, user.id],
      (data) => {
        setShipments(data);
        setIsLoading(false);
        setToggle(false);
      },
      (ex) => {
        setExceptionMessage(ex);
        setIsLoading(false);
      }
    );
  };
  if (isLoading || !user.id) return <LoadingBlock />;
  //Dynamically set colors in graph
  const colorSet = (bar) => {
    // console.log(bar);
    switch (bar.statusId) {
      case "1":
        return "#4996AD";
      case "2":
        return "#44AD4E";
      case "3":
        return "#8b0000";
      case "4":
        return "#FFAF42";
      case "5":
        return "darkgreen";
      case "6":
        return "#023020";
      case "7":
        return "grey";
      case "8":
        return "purple";
      case "9":
        return "#023020";
    }
  };
  //--Data wrangling--//
  let shipmentCount = [];
  let dataSchema = {
    //schema that charts.js wants data to be in with initial set values
    labels: [],
    datasets: [
      {
        label: "Status",
        data: [],
        backgroundColor: [],
        barThickness: 50,
        borderRadius: 4,
        maxBarThickness: 100,
      },
    ],
  };

  let legend = [
    //schema that charts.js wants the legend to be in
  ];

  shipments.forEach((shipment) => {
    //New variable where we can get counts and relevant fields cleanly
    shipmentCount.push({
      id: shipment.id,
      statusId: shipment.statusId,
      statusDesc: shipment.status.description,
      count: 1,
    });
  });

  //Aggregate data into arrays grouped by status
  let shipmentsAgg = shipmentCount.reduce((acc, curr) => {
    const index = acc.findIndex((item) => item.statusId === curr.statusId);
    index > -1
      ? (acc[index].count += curr.count)
      : acc.push({
          ids: [],
          statusId: curr.statusId,
          statusDesc: curr.statusDesc,
          count: curr.count,
        });
    return acc;
  }, []);

  //Add the IDs for each grouping to the ID array
  shipmentsAgg.forEach((element) => {
    let b = 0;
    for (let i = 0; i < shipmentCount.length; i++) {
      if (shipmentCount[i].statusId === element.statusId) {
        element.ids[b] = shipmentCount[i].id;
        b++;
      }
    }
  });

  //Sorting the array so that they display in order in the graph
  let sorted = shipmentsAgg.sort((c1, c2) => {
    if (c1.statusId > c2.statusId) {
      return 1;
    } else {
      return -1;
    }
  });

  //Using the sorted version to populate the data schema
  for (let i = 0; i < sorted.length; i++) {
    dataSchema.datasets[0].data[i] = sorted[i].count;
    dataSchema.datasets[0].backgroundColor[i] = colorSet(sorted[i]);
    //dataSchema.datasets[0].labels[i] = sorted[i].statusDesc;
  }
  //Add labels for chart values
  sorted.forEach((entry, index) => {
    dataSchema.labels.push(entry.statusDesc);
  });

  //create separate dataset for the legend
  sorted.forEach((entry) => {
    legend.push({
      title: entry.statusDesc,
      value: entry.count,
      color: colorSet(entry),
    });
  });

  //Chart options
  const options = {
    animation: true,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    xAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0,
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider,
        },
      },
    ],
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
    //had to do this to override the default legend...
    plugins: {
      legend: { display: false },
    },
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement[0]
        ? "pointer"
        : "default";
    },
  };

  return (
    <Card {...dataSchema} sx={{ flexGrow: 1 }}>
      <CardHeader
        action={
          toggle && (
            <Button
              endIcon={<RefreshIcon fontSize="small" />}
              size="small"
              onClick={refreshWindow}
            >
              Back
            </Button>
          )
        }
      />
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 2,
        }}
      >
        {legend.map(({ color, title, value }) => (
          <Box
            key={title}
            sx={{
              p: 1,
              textAlign: "center",
            }}
          >
            <Typography color="textPrimary" variant="body1">
              {title}
            </Typography>
            <SquareIcon style={{ color }}></SquareIcon>
          </Box>
        ))}
      </Box>
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: "relative",
          }}
        >
          <Bar
            ref={chartRef}
            data={dataSchema}
            options={options}
            onClick={barHandler}
          />
        </Box>
      </CardContent>
      <Divider />
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      ></Box> */}
    </Card>
  );
}
