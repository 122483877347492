import {
  Box,
  Button,
  Paper,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { SeverityPill } from "./SeverityPill";
import { updateShipment } from "../services/shipmentService";
import Swal from "sweetalert2";

interface Props {
  row: any;
  setShipmentStatus?: any;
}

export default function UpdateShipmentStatus(props: Props) {
  const { row } = props;
  const [renderOptions, setRenderOptions] = React.useState(false);
  const [status, setStatus] = React.useState(row.status);
  const [addReason, setAddReason] = React.useState(false);
  const [reason, setReason] = React.useState(row.reason);

  const statusConvert: any = {
    1: "New",
    2: "Active",
    3: "Needs Attention",
    4: "On Hold",
    6: "Completed",
    7: "Canceled",
    9: "Delivered",
  };

  useEffect(() => {
    if (status === "On Hold" || status === "Needs Attention") {
      setAddReason(true);
    } else {
      setAddReason(false);
    }
  }, [status]);

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };
  const handleChangeReason = (event: SelectChangeEvent) => {
    setReason(event.target.value);
  };

  async function saveUpdatedStatus() {
    let requestUpdate;
    switch (status) {
      case "New":
        requestUpdate = "1";
        break;
      case "Active":
        requestUpdate = "2";
        break;
      case "Needs Attention":
        requestUpdate = "3";
        break;
      case "On Hold":
        requestUpdate = "4";
        break;
      case "Completed":
        requestUpdate = "6";
        break;
      case "Canceled":
        requestUpdate = "7";
        break;
      case "Delivered":
        requestUpdate = "9";
        break;
    }
    let reasonCode;
    switch (reason) {
      case "N/A":
        reasonCode = "1";
        break;
      case "Missing BOL":
        reasonCode = "2";
        break;
      case "Illegible BOL":
        reasonCode = "3";
        break;
      case "Claim":
        reasonCode = "4";
        break;
    }
    setRenderOptions(!renderOptions);
    try {
      // if((status === 'Completed' && row.orders[0].files.length === 0) || (status === 'Ready To Bill' && row.orders[0].files.length === 0)){
      //   return alert('Must have Image Attached')
      // }
      if (
        (status === "Needs Attention" && reasonCode === "1") ||
        (status === "On Hold" && reasonCode === "1")
      ) {
        Swal.fire(`Must Select Reason for ${status}`)
      } else {
        updateShipment(
          row.id,
          { statusId: requestUpdate, reasonCodeId: reasonCode },
          (data: any) => {
            setStatus(statusConvert[data.statusId]);
            row.status = statusConvert[data.statusId];
            row.reason = reason;
          },
          (ex: any) => {
            console.log(ex);
          }
        );
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <Box>
      <Typography>
        <SeverityPill status={status}>{status}</SeverityPill>
      </Typography>
      {renderOptions ? (
        <>
          <div>
            <FormControl sx={{ m: 0.5, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-helper-label">
                Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value={"New"}>New</MenuItem>
                <MenuItem value={"Active"}>Active</MenuItem>
                <MenuItem value={"Needs Attention"}>Needs Attention</MenuItem>
                <MenuItem value={"On Hold"}>On Hold</MenuItem>
                {/* <MenuItem value={"Ready To Bill"}>Ready To Bill</MenuItem> */}
                <MenuItem value={"Completed"}>Completed</MenuItem>
                <MenuItem value={"Delivered"}>Delivered</MenuItem>
                <MenuItem value={"Canceled"}>Canceled</MenuItem>
              </Select>
            </FormControl>
          </div>
          {status === "On Hold" || status === "Needs Attention" ? (
            <>
              <div>{`Please Select ${status} reason`}</div>
              <div>
                <FormControl sx={{ m: 0.5, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Reason
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={reason}
                    label="Reason"
                    onChange={handleChangeReason}
                  >
                    <MenuItem value={"N/A"}></MenuItem>
                    <MenuItem value={"Claim"}>Claim</MenuItem>
                    <MenuItem value={"Illegible BOL"}>Illegible BOL</MenuItem>
                    <MenuItem value={"Missing BOL"}>Missing BOL</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </>
          ) : (
            <></>
          )}
          <Button onClick={saveUpdatedStatus}>Save</Button>
          <Button onClick={() => setRenderOptions(false)}>
            Cancel
          </Button>
        </>
      ) : (
        <Button onClick={() => setRenderOptions(!renderOptions)}>
          Update Status
        </Button>
      )}
    </Box>
  );
}
