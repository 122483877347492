import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Paper,
  TextField,
  Box,
  Typography,
  Stack,
  Divider,
} from "@mui/material";
import React from "react";
import { UserContext } from "../../contexts/UserContext";
import { ToastContext } from "../../contexts/ToastContext";
import { PermissionsContext } from "../../contexts/PermissionsContext";
import {
  addSettings,
  getSettings,
  updateSetting,
} from "../../services/adminService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Popover from "@mui/material/Popover";

interface AdminSettings {
  id?: string;
  rule?: string;
  value?: string;
}
export default function AccountPreferencesScreen() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [adminSettings, setAdminSettings] = React.useState(
    Array<AdminSettings>
  );

  const [addSetting, setAddSetting] = React.useState("");
  const [addSettingValue, setAddSettingValue] = React.useState("");
  const [visible, setVisible] = React.useState(false);
  const { permissions } = React.useContext(PermissionsContext);
  const { user } = React.useContext(UserContext);
  const { setExceptionMessage } = React.useContext(ToastContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const availableSettings = ["Order Expiration", "Unbilled Days Goal"];
  const rules = adminSettings.map((a) => a.rule);
  const selectSetting = availableSettings.filter((x) => !rules.includes(x));
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChange = (event: any) => {
    const newState = adminSettings.map((a) => {
      if (a.rule == event.target.name) {
        return { id: a.id, rule: a.rule, value: event.target.value };
      }
      return a;
    });
    setAdminSettings(newState);
  };
  const handleBack = () => {
    navigate("/Admin");
  };
  const handleUpdateAdmin = () => {
    try {
      adminSettings.forEach((a) => {
        updateSetting(
          a.id,
          a.value,
          (data: any) => {
            console.log(data);
          },
          (err: any) => {
            console.log(err);
          }
        );
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAddSettingValue("");
  };
  const handleSelectSetting = (event: any) => {
    console.log(event?.currentTarget);
    setAddSetting(event.target.value);
    setAnchorEl(event.target);
    setVisible(!visible);
  };

  const handleAddSetting = () => {
    let account =
      permissions?.adminAccounts !== undefined
        ? permissions.adminAccounts[0]
        : "";

    addSettings(
      account,
      addSetting,
      addSettingValue,
      (data: any) => {
        console.log(data);
      },
      (err: any) => {
        console.log(err);
      }
    );
    setVisible(false);
    setAnchorEl(null);
  };
  React.useEffect(() => {
    if (!permissions?.adminAccounts) return;

    getSettings(
      permissions.adminAccounts[0],
      (data: any) => {
        setAdminSettings(data);
        setIsLoading(false);
      },
      (ex: any) => {
        setExceptionMessage(ex);
        setIsLoading(false);
      }
    );
  }, [visible]);
  //console.log(permissions?.adminAccounts);

  return (
    <>
      <Paper>
        <Button
          endIcon={<ArrowBackIcon fontSize="small" />}
          size="small"
          onClick={handleBack}
        >
          Back
        </Button>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 175 }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              Add New Rule
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              aria-describedby="addSetting"
              aria-haspopup="true"
              value={""}
              onChange={handleSelectSetting}
              autoWidth
              label="Add Rule"
              sx={{ backgroundColor: "whitesmoke" }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {/* once we have names to filter through, this should work */}
              {selectSetting.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{ width: "auto" }}
          >
            <Card>
              <CardHeader title={`Rule: ${addSetting}`} />
              <CardContent>
                <TextField
                  label="Days"
                  name={addSetting}
                  onChange={(e) => setAddSettingValue(e.target.value)}
                  required
                  value={addSettingValue}
                  variant="outlined"
                />
              </CardContent>
            </Card>
            <Button
              variant="contained"
              sx={{ m: 1 }}
              onClick={handleAddSetting}
            >
              Set New Rule
            </Button>
          </Popover>
        </Box>

        <Paper>
          <fieldset>
            <legend> Edit Current Rules</legend>
            <Stack direction={"row"}>
              {adminSettings.map((a, index) => {
                return (
                  <Card key={index}>
                    <CardHeader title={a.rule} />
                    <CardContent>
                      <TextField
                        label="Days"
                        name={a.rule}
                        onChange={handleChange}
                        value={a.value}
                        variant="outlined"
                      />
                    </CardContent>
                  </Card>
                );
              })}
            </Stack>
            <Typography pt={1} />
            <Button
              color="primary"
              variant="contained"
              onClick={handleUpdateAdmin}
              sx={{ pt: 2 }}
            >
              Save Changes
            </Button>
          </fieldset>
        </Paper>
      </Paper>
    </>
  );
}
