import axios from "axios";

const baseUrl = "https://test.swifttrax.io/";
//const baseUrl = "http://44.212.170.175/";
//const baseUrl = "http://localhost:3000"

export const baseApi = async () => {
  var userApiKey = "";
  var userSessionId = "";
  var user = null;
  try {
    let userFromStorage = localStorage.getItem("user");
    user = userFromStorage && JSON.parse(userFromStorage);
    userApiKey = user.apiKeys[0].apiKey;
    userSessionId = user.sessionId;
  } catch (error) {
    console.log(
      "Something bad happened while trying to get the user api key from local storage."
    );
    console.log(error);
  }

  const instance = axios.create({
    baseURL: baseUrl,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      apikey: userApiKey,
      sessionId: userSessionId,
    },
  });

  instance.interceptors.response.use(
    (resp: any) => {
      return resp;
    },
    (error: any) => {
      if (error.response.status === 401) {
        window.location.href = "/SignOut";
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const baseApiNoApiKey = async () => {
  const instance = axios.create({
    baseURL: baseUrl,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return instance;
};
