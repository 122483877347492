import { baseApi } from "./baseService";

const API = {
  getOutstandingInvoices: "/api/reports/outstandingInvoices",
  getBlockedShipments: "/api/reports/blockedShipments",
  getOrdersMissingDocs: "/api/reports/ordersMissingDocs",
  getUserIssues: "/api/reports/userIssues",
  getCompletedDocs: "/api/reports/completedDocs",
  getAgingInvoices: "api/reports/aging",
};

export const getOutstandingInvoices = (
  userId: string | Array<any>,
  onSuccess: Function,
  filterOptions?: string | object,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(
        API.getOutstandingInvoices,
        { userId, filterOptions },
        { responseType: "blob" }
      )
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getBlockedShipments = (
  userId: string | Array<any>,
  onSuccess: Function,
  filterOptions?: string | object,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(
        API.getBlockedShipments,
        { userId, filterOptions },
        { responseType: "blob" }
      )
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getOrdersMissingDocs = (
  userId: string | Array<any>,
  onSuccess: Function,
  filterOptions?: string | object,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(
        API.getOrdersMissingDocs,
        { userId, filterOptions },
        { responseType: "blob" }
      )
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getUserIssues = (
  userHierarchy: Array<string> | undefined,
  onSuccess: Function,
  filterOptions?: object,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(
        API.getUserIssues,
        { userHierarchy, filterOptions },
        { responseType: "blob" }
      )
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getCompletedDocs = (
  object: string | Array<any> | undefined,
  startDate: any,
  endDate: any,
  onSuccess: Function,
  filterOptions?: object,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(
        API.getCompletedDocs,
        { object, startDate, endDate, filterOptions },
        { responseType: "blob" }
      )
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getAgingInvoices = (
  userId: string | Array<any>,
  onSuccess: Function,
  //filterOptions?: string | object,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getAgingInvoices, { userId }, { responseType: "blob" })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};
