import * as React from "react";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ShipmentImageUpload from "./ShipmentImageUpload";

export interface Props {
  file: any,
  row?: any
}

export default function ShipmentImagePopover(props: Props) {
  const [index, setIndex] = React.useState(0); // initialize index state variable
  const [isOpen, setIsOpen] = React.useState(false); // initialize isOpen state variable
  const [isLoading, setIsLoading] = React.useState(false);
  const [imageFile, setImageFile] = React.useState<any>([]);
  const [matchScore, setMatchScore] = React.useState<any>([]);
  const handleClick = () => {
    setIsOpen(!isOpen); // toggle isOpen state variable
  };
  //console.log(imageFile);
  React.useEffect(() => {
    setIsLoading(true);
    let files: any = [];
    let matchScores: any = [];
    props.file.forEach((f: any) => {
      files.push(f.file);
      matchScores.push(f.matchScore);
    });

    setImageFile(files);
    setMatchScore(matchScores);
    setIsLoading(false);
  }, [props.file]);
  React.useEffect(() => {
    if (isOpen && imageFile.length > 0) {
      // show SweetAlert popup if isOpen state variable is true
      //const background = matchScore[index] > 0.75 ? "darkgreen" : "#FFAF42"; // set background color based on matchScore
      Swal.fire({
        imageUrl: imageFile[index],
        imageHeight: "95%",
        imageWidth: "95%",
        showConfirmButton: props.file.length > 1 && index < props.file.length - 1,
        showCancelButton: true,
        showDenyButton: index > 0,
        denyButtonText: "Back",
        confirmButtonText: "Next",
        cancelButtonText: "Close",
        //background: background,
      }).then((result) => {
        if (result.isConfirmed) {
          if (index + 1 < props.file.length) {
            // increment index state variable to show next image
            setIndex(index + 1);
            setIsOpen(true); // set isOpen to true to show the next image
          }
        }
        if (result.isDenied) {
          setIndex(index - 1);
          setIsOpen(true); // set isOpen to true to show the previous image
        }
        if (result.isDismissed) {
          setIsOpen(false); // set isOpen to false to close the popup
        }
      });
    }
  }, [index, isOpen, imageFile, matchScore]); // re-run this effect whenever index or isOpen state variables change

  return (
    <div>
      {(props.file.length < 1) ? (
        <div>No Image Uploaded</div>
      ) : (
        <Button variant="contained" onClick={handleClick} size="small">
          View {props.file.length} Image(s)
        </Button>
      )}
    </div>
  );
}
