import React from "react";
import { Permissions } from "../interfaces/Permissions";
import {PermissionsContextState} from '../interfaces/PermissionsContextState'

export const PermissionsContext = React.createContext<PermissionsContextState>({
  loading: true,
  permissions: null,
  getPermissions: () => { return null },
  savePermissions: (permissions: Permissions) => { },
  savePermissionsState: (permissions: Permissions) => { },
  logout: () => { },
});

interface Props {
  children?: React.ReactNode,
}
export const PermissionsProvider = ({ children }: Props) => {
  const [permissions, setPermissions] = React.useState<Permissions | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const foundPermissions = getPermissions();
    setLoading(false);
    
    if (foundPermissions) {
      savePermissionsState(foundPermissions);
    }
  }, []);

  const getPermissions = () => {
    let permissions: Permissions | null = null;

    try {
      let permissionsFromStorage = localStorage.getItem("permissions");
      permissions = permissionsFromStorage && JSON.parse(permissionsFromStorage);
    } catch (error) {
      console.log('Something bad happened while trying to get the permissions from local storage.');
      console.log(error);
    }

    return permissions;
  }

  const savePermissions = (permissions: Permissions) => {
    localStorage.setItem("permissions", JSON.stringify(permissions));
    setPermissions(permissions);
  }

  const savePermissionsState = (permissions: Permissions) => {
    setPermissions(permissions);
  }

  const logout = () => {
    localStorage.clear();
    setPermissions(null);
  }


  return (
    <PermissionsContext.Provider value={{loading, permissions, savePermissionsState, getPermissions, savePermissions, logout }}>
      {children}
    </PermissionsContext.Provider>
  );
}

