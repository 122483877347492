import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { ToastContext } from "../contexts/ToastContext";
import Copyright from "../components/Copyright";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router";
import LoadingButton from "@mui/lab/LoadingButton";
import { forgotPassword } from "../services/emailService";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const theme = createTheme();

export default function ForgotPasswordScreen() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const toastCtx = React.useContext(ToastContext);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      forgotPassword(
        values.email,
        (data: any) => {
          setLoading(false);
          toastCtx.setSuccessMessage(
            "A password reset link has been sent to your email."
          );
          navigate("/");
        },
        (err: any) => {
          setLoading(false);
          toastCtx.setExceptionMessage(err);
        }
      );
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Enter Recovery Email
          </Typography>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              id="email"
              name="email"
              label="Email Address"
              placeholder="you@newco.com"
              margin="normal"
              fullWidth
              autoFocus
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <LoadingButton
              type="submit"
              fullWidth
              loading={loading}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send
            </LoadingButton>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
