import React from "react";
import { QueueMessage } from "../interfaces/QueueMessage";
import { OrderContext } from "./OrderContext";
import { ToastContext } from "./ToastContext";
import { UserContext } from "./UserContext";
import { useQueueListener } from "../services/QueueService";
import { notificationsAtom, queueMessagesAtom } from "../atoms/atoms";
import { useRecoilState } from "recoil";
import { ShipmentContext } from "./ShipmentContext";
import { InvoiceContext } from "./InvoiceContext";
import { PermissionsContext } from "./PermissionsContext";
import { BillContext } from "./BillContext";
import { AMQPWebSocketClient } from "@cloudamqp/amqp-client";

interface QueueContextState {
  queueMessages: QueueMessageTypes;
  closeEverything(): Promise<void>;
}
interface QueueMessageTypes {
  OrderMessage: string;
  ShipmentMessage: string;
  InvoiceMessage: string;
  OrderFileMessage: string;
  AccountFileMessage: string;
  BillMessage: string;
}

export const QueueContext = React.createContext<QueueContextState>({
  queueMessages: {
    OrderMessage: "",
    ShipmentMessage: "",
    InvoiceMessage: "",
    OrderFileMessage: "",
    AccountFileMessage: "",
    BillMessage: "",
  },
  async closeEverything() {},
});

interface Props {
  children?: React.ReactNode;
}
export const QueueProvider = ({ children }: Props) => {
  const [queueMessages, setQueueMessages] = React.useState<QueueMessageTypes>({
    OrderMessage: "",
    ShipmentMessage: "",
    InvoiceMessage: "",
    OrderFileMessage: "",
    AccountFileMessage: "",
    BillMessage: "",
  });
  const userCtx = React.useRef(React.useContext(UserContext));
  const toastCtx = React.useRef(React.useContext(ToastContext));
  const orderCtx = React.useRef(React.useContext(OrderContext));
  const shipmentCtx = React.useRef(React.useContext(ShipmentContext));
  const invoiceCtx = React.useRef(React.useContext(InvoiceContext));
  const billCtx = React.useRef(React.useContext(BillContext));
  const permissionsCtx = React.useRef(React.useContext(PermissionsContext));
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [conn, setConn] = React.useState<any>(null);
  const [ch, setCh] = React.useState<any>(null);
  const [oQ, setOQ] = React.useState<any>(null);
  const [oFQ, setOFQ] = React.useState<any>(null);
  const [sQ, setSQ] = React.useState<any>(null);
  const [iQ, setIQ] = React.useState<any>(null);
  const [bQ, setBQ] = React.useState<any>(null);
  const [aFQ, setAFQ] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const url = "wss://shark.rmq.cloudamqp.com/ws/amqp"; //`${tls ? "wss" : "ws"}://${window.location.host}`
  const amqp = new AMQPWebSocketClient(
    url,
    "rfixtzyl",
    "rfixtzyl",
    "9ZUYOJWPBmMD7tl46Flx7aWtPE1-oiST"
  );
  const { ListenForQueueMessages } = useQueueListener();
  const perms = permissionsCtx.current.getPermissions();
  //console.log(perms);

  const getConnections = async () => {
    if (conn || ch || isLoading) return;
    if (!perms) return;
    var user = userCtx.current.getUser();
    var primaryAccount = perms?.primaryAccount[0];
    if (!user) return;
    setIsLoading(true);
    await amqp.connect().then(async (cn) => {
      setConn(() => cn);

      if (!ch) {
        await cn.channel().then(async (chan) => {
          setCh(() => chan);
          let selector = 0;
          if (perms?.hierarchy) {
            selector = 1;
          }

          if (!oQ) {
            if (perms?.hierarchy) {
              var orderExch = await (
                await chan.queue("")
              ).bind(`Order-exchange-${primaryAccount}`);
            } else {
              var orderExch = await (
                await chan.queue("OrderFile")
              ).bind(`Order-exchange-${primaryAccount}`);
            }
            const ordQ = await orderExch.subscribe({ noAck: false }, (msg) => {
              ListenForQueueMessages({
                channel: "Order",
                user: user,
                msg: msg,
                selector: selector,
                onMessageReceived(queueItem) {
                  console.log("order queue synced");
                  setNotifications((state) => [
                    ...state,
                    {
                      message: `Order# ${queueItem.id} has been ${queueItem.method}d`,
                    },
                  ]);
                  setQueueMessages((state) => {
                    var newState = Object.assign({}, queueMessages, {
                      OrderMessage: [...state.OrderMessage, queueItem.id],
                    });
                    return newState;
                  });
                },
              });
            });
            setOQ(ordQ);
            ordQ.wait(2);
          }
          if (!oFQ) {
            if (perms?.hierarchy) {
              var orderFileExch = await (
                await chan.queue("")
              ).bind(`OrderFile-exchange-${primaryAccount}`);
            } else {
              var orderFileExch = await (
                await chan.queue("Order")
              ).bind(`OrderFile-exchange-${primaryAccount}`);
            }
            const ordFileQ = await orderFileExch.subscribe(
              { noAck: false },
              (msg) => {
                ListenForQueueMessages({
                  channel: "OrderFile",
                  user: user,
                  msg: msg,
                  selector: selector,
                  onMessageReceived(queueItem) {
                    console.log("orderFile queue synced");
                    setNotifications((state) => [
                      ...state,
                      {
                        message: `Order# ${queueItem.orderId} has had an image attached to it. Please verify that the image is correct.`,
                      },
                    ]);
                    setQueueMessages((state) => {
                      var newState = Object.assign({}, queueMessages, {
                        OrderFileMessage: [
                          ...state.OrderFileMessage,
                          queueItem.id,
                        ],
                      });
                      return newState;
                    });
                  },
                });
              }
            );
            setOFQ(ordFileQ);
            ordFileQ.wait(2);
          }
          if (!sQ) {
            if (perms?.hierarchy) {
              var shipExch = await (
                await chan.queue("")
              ).bind(`Shipment-exchange-${primaryAccount}`);
            } else {
              var shipExch = await (
                await chan.queue("Shipment")
              ).bind(`Shipment-exchange-${primaryAccount}`);
            }
            const shipQ = await shipExch.subscribe({ noAck: false }, (msg) => {
              ListenForQueueMessages({
                channel: "Shipment",
                user: user,
                msg: msg,
                selector: selector,
                onMessageReceived(queueItem) {
                  console.log("shipment queue synced");
                  setNotifications((state) => [
                    ...state,
                    {
                      message: `Shipment# ${queueItem.id} has been ${queueItem.method}d`,
                    },
                  ]);
                  setQueueMessages((state) => {
                    var newState = Object.assign({}, queueMessages, {
                      ShipmentMessage: [...state.ShipmentMessage, queueItem.id],
                    });
                    return newState;
                  });
                },
              });
            });
            setSQ(shipQ);
            shipQ.wait(2);
          }
          if (!iQ) {
            if (perms?.hierarchy) {
              var invExch = await (
                await chan.queue("")
              ).bind(`Invoice-exchange-${primaryAccount}`);
            } else {
              var invExch = await (
                await chan.queue("Invoice")
              ).bind(`Invoice-exchange-${primaryAccount}`);
            }
            const invQ = await invExch.subscribe({ noAck: false }, (msg) => {
              ListenForQueueMessages({
                channel: "Invoice",
                user: user,
                msg: msg,
                selector: selector,
                onMessageReceived(queueItem) {
                  console.log("invoice queue synced");
                  setNotifications((state) => [
                    ...state,
                    {
                      message: `Invoice# ${queueItem.id} has been ${queueItem.method}d`,
                    },
                  ]);
                  setQueueMessages((state) => {
                    var newState = Object.assign({}, queueMessages, {
                      InvoiceMessage: [...state.InvoiceMessage, queueItem.id],
                    });
                    return newState;
                  });
                },
              });
            });
            setIQ(invQ);
            invQ.wait(2);
          }
          if (!bQ) {
            if (perms?.hierarchy) {
              var billExch = await (
                await chan.queue("")
              ).bind(`Bill-exchange-${primaryAccount}`);
            } else {
              var billExch = await (
                await chan.queue("Bill")
              ).bind(`Bill-exchange-${primaryAccount}`);
            }
            const billQ = await billExch.subscribe({ noAck: false }, (msg) => {
              ListenForQueueMessages({
                channel: "Bill",
                user: user,
                msg: msg,
                selector: selector,
                onMessageReceived(queueItem) {
                  console.log("bill queue synced");
                  setNotifications((state) => [
                    ...state,
                    {
                      message: `Bill# ${queueItem.id} has been ${queueItem.method}d`,
                    },
                  ]);
                  setQueueMessages((state) => {
                    var newState = Object.assign({}, queueMessages, {
                      BillMessage: [...state.BillMessage, queueItem.id],
                    });
                    return newState;
                  });
                },
              });
            });
            setBQ(billQ);
            billQ.wait(2);
          }
          if (!aFQ) {
            if (perms?.hierarchy) {
              var aFileExch = await (
                await chan.queue("")
              ).bind(`AccountFile-exchange-${primaryAccount}`);
            } else {
              var aFileExch = await (
                await chan.queue("AccountFile")
              ).bind(`AccountFile-exchange-${primaryAccount}`);
            }
            const aFileQ = await aFileExch.subscribe(
              { noAck: false },
              (msg) => {
                ListenForQueueMessages({
                  channel: "AccountFile",
                  user: user,
                  msg: msg,
                  permissions: perms,
                  selector: selector,
                  onMessageReceived(queueItem) {
                    console.log("accountFile queue synced");
                    setNotifications((state) => [
                      ...state,
                      {
                        message: `An unknown file has been ${queueItem.method}d. Please review in the Unmatched tab.`,
                      },
                    ]);
                    setQueueMessages((state) => {
                      var newState = Object.assign({}, queueMessages, {
                        AccountFileMessage: [
                          ...state.AccountFileMessage,
                          queueItem.id,
                        ],
                      });
                      return newState;
                    });
                  },
                });
              }
            );
            setAFQ(aFileQ);
            aFileQ.wait(2);
          }
        });
      }
    });
    setIsLoading(false);
  };

  const closeEverything = async () => {
    if (conn) {
      conn.close();
      setAFQ(null);
      setBQ(null);
      setCh(null);
      setConn(null);
      setIQ(null);
      setOFQ(null);
      setOQ(null);
      setSQ(null);
      console.log("Connections closed");
    } else {
      console.log("no conn");
    }
  };

  //Trying to get it to load on login
  const userCtx2 = React.useContext(UserContext);
  React.useEffect(() => {
    var user = userCtx2.getUser();
    if (!user) return;
    getConnections();
  }, [userCtx2.user]);
  //so it doesn't load twice on refresh
  React.useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
        getConnections();
      }, 10000);
    }
  }, [isLoading]);

  //whenever the queuemessage changes
  React.useEffect(() => {
    if (
      !queueMessages.OrderMessage ||
      //Same as above
      !queueMessages.ShipmentMessage ||
      !queueMessages.InvoiceMessage ||
      !queueMessages.OrderFileMessage ||
      !queueMessages.AccountFileMessage ||
      !queueMessages.BillMessage
    )
      return;

    //will be able to add loadShipment/loadInvoice here. Maybe with switch, maybe all at once- will have to test and see
    orderCtx.current.loadOrders();
    var queueObject: QueueMessage = JSON.parse(queueMessages.OrderMessage);
    toastCtx.current.setSuccessMessage(`Order updated: ${queueObject.id}`);
    //then can add them below
    shipmentCtx.current.loadShipments();
    var queueObject: QueueMessage = JSON.parse(queueMessages.ShipmentMessage);
    toastCtx.current.setSuccessMessage(`Shipment updated: ${queueObject.id}`);

    orderCtx.current.loadOrders();
    var queueObject: QueueMessage = JSON.parse(queueMessages.OrderFileMessage);
    toastCtx.current.setSuccessMessage(`OrderFile updated: ${queueObject.id}`);

    invoiceCtx.current.loadInvoices();
    var queueObject: QueueMessage = JSON.parse(queueMessages.InvoiceMessage);
    toastCtx.current.setSuccessMessage(`Invoice updated: ${queueObject.id}`);

    billCtx.current.loadBills();
    var queueObject: QueueMessage = JSON.parse(queueMessages.BillMessage);
    toastCtx.current.setSuccessMessage(`Bill updated: ${queueObject.id}`);
  }, [queueMessages]);

  return (
    <QueueContext.Provider value={{ queueMessages, closeEverything }}>
      {children}
    </QueueContext.Provider>
  );
};
