import { Button } from "@mui/material";
import React from "react";
import Swal from "sweetalert2";
import { ToastContext } from "../contexts/ToastContext";
import { updateOrder } from "../services/orderService";



export default function MarkAsReadyToBill({ row, loadOrders }: any) {
  const { setAlertMessage, setExceptionMessage, setSuccessMessage } =
    React.useContext(ToastContext);

    const handleClick = () => {
      const id= row.id
      Swal.fire({
        title: "Are You Sure You Want To Aprove?",
        confirmButtonText: "Yes",
        showDenyButton: true,
        // showCancelButton: true,
        // cancelButtonText: "No",
        denyButtonColor: "#D32F2f",
        confirmButtonColor: "#2E7D32",
      }).then((result) => {
        if (result.isConfirmed) {
          updateOrder(
            id,
            (data: any) => {
              setSuccessMessage(
                `Order ${row.orderNumber} is now ready to bill.`
              );
              loadOrders();
            },
            undefined,
            undefined,
            undefined,
            8,
            undefined,
            undefined,
            undefined,
            (ex: any) => {
              setExceptionMessage(ex);
            }
          );
        } 
      })
    }
      
  return (
    <Button variant='outlined' onClick={handleClick}>Mark As Ready To Bill</Button>
  );
}
