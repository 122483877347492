import { baseApi } from "./baseService";

const API = {
  getInvoices: "/api/invoice/user",
  getInvoicePage: "/api/invoice/page",
  updateInvoice: "api/invoice/update",
  generateInvoicePDF: "api/invoice/generatePDF",
};

export const getInvoices = (
  userId: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getInvoices, { userId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getInvoicePage = (
  userId: string | Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getInvoicePage, { userId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const updateInvoice = (
  invoiceId: string,
  //Add whatever needs to be updated here,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.updateInvoice, { invoiceId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const generateInvoicePDF = (
  orderId: string,
  orderNumber: any,
  images: Array<any>,
  shipmentNumber: Array<any>,
  customer: string,
  onSuccess: Function,
  onError?: Function
) => {
  //console.log(images)

  baseApi()
    .then((api) =>
      api.post(
        API.generateInvoicePDF,
        {
          orderId,
          orderNumber,
          images: images,
          shipmentNumber,
          customer,
        },
        { responseType: "blob" }
      )
    )
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      console.log(error);
      onError && onError(error);
    });
};

