import { Alert, AlertTitle, Snackbar, SnackbarOrigin } from "@mui/material";
import React, { useState } from "react";
import AlertMessage from "../interfaces/AlertMessage";
import { ToastContextState } from "../interfaces/ToastContextState";

export const ToastContext = React.createContext<ToastContextState>({
  setAlertMessage: (alert: AlertMessage) => { },
  setSuccessMessage: (msg: string) => { },
  setExceptionMessage: (ex: any) => { },
  closeAlert: () => {},
});

interface Props {
  children?: React.ReactNode,
}
interface ToastProps {
  alert: AlertMessage | null
}
export const ToastProvider = ({ children }: Props) => {
  const [alert, setAlert] = useState<AlertMessage | null>(null);

  const setAlertMessage = (alert: AlertMessage) => {
    alert.open = alert.open == null ? true : alert.open;
    setAlert(alert)
  }
  const setSuccessMessage = (msg: string) => {
    setAlertMessage({
      severity: 'success',
      message: msg,
    })
  }
  const setExceptionMessage = (ex: any) => {
    console.log(ex);
    var message = '';
    if(!!ex.message) {
      message = ex.message;
    }
    if(!!ex.response?.data?.message) {
      message = ex.response.data.message;
    }
    if(!!ex.data?.message) {
      message = ex.data.message;
    }
    setAlertMessage({
      severity: 'error',
      title: 'Error',
      message: message,
    })
  }
  const closeAlert = () => {
    setAlert({open: false, severity: 'success', message: ''});
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    closeAlert();
  };

  const Toast = (props: ToastProps) => {
    const alert = props.alert;

    if (alert == null) return null;

    const anchor: SnackbarOrigin = !!alert.props?.anchorOrigin ? alert.props.anchorOrigin : { vertical: 'top', horizontal: 'center' };
    const autoHide: number = !!alert.props?.autoHideDuration ? alert.props.autoHideDuration : 6000;

    return (
      <Snackbar open={alert.open} anchorOrigin={anchor} autoHideDuration={autoHide} onClose={handleClose} {...alert.props}>
        <Alert severity={alert.severity} sx={{ width: '100%' }}>
          {!!alert.title && (<AlertTitle>{alert.title}</AlertTitle>)}
          {alert.message}
        </Alert>
      </Snackbar>
    );
  }

  return (
    <ToastContext.Provider value={{ setAlertMessage, setSuccessMessage, setExceptionMessage, closeAlert }}>
      {children}
      <Toast alert={alert} />
    </ToastContext.Provider>
  );
}

