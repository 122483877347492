import { TotalOrdersCard } from "../components/TotalOrdersCard";
import { TotalUnbilled } from "../components/TotalUnbilledCard";
import React from "react";
import { AverageUnbilledAge } from "../components/AverageUnbilledAgeCard";
import { PercentageNeedingAttention } from "../components/PercentageNeedingAttention";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { UserContext } from "../contexts/UserContext";
import ShipmentChartDonut from "../components/ShipmentChartDonut.js";
import { useNavigate } from "react-router";
import { ToastContext } from "../contexts/ToastContext";
import LoadingBlock from "../components/LoadingBlock";
import { getOrdersDashboard } from "../services/orderService";
import { getShipmentsDashboard } from "../services/shipmentService";
import { useRecoilState } from "recoil";
import { ordersAtom, shipmentsAtom } from "../atoms/atoms";
import DashboardLineChart from "../components/DashboardLineChart";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { UsersTable } from "../components/UserTable";
import Copyright from "../components/Copyright";
import { Typography } from "@mui/material";
import { QueueContext } from "../contexts/QueueContext";

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = React.useContext(UserContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const { setExceptionMessage } = React.useContext(ToastContext);
  const [orders, setOrders] = useRecoilState(ordersAtom);
  const [shipments, setShipments] = useRecoilState(shipmentsAtom);
  const { permissions } = React.useContext(PermissionsContext);
  const { queueMessages } = React.useContext(QueueContext);
  // console.log(shipments);

  React.useEffect(() => {
    if (!user) return;

    getOrdersDashboard(
      !permissions?.hierarchy ? user.id : [...permissions?.hierarchy, user.id],
      (data: any) => {
        setOrders(data);
        setIsLoading(false);
      },
      (ex: any) => {
        setExceptionMessage(ex);
        setIsLoading(false);
      }
    );
    getShipmentsDashboard(
      !permissions?.hierarchy ? user.id : [...permissions?.hierarchy, user.id],

      (data: any) => {
        setShipments(data);
        setIsLoading(false);
      },
      (ex: any) => {
        setExceptionMessage(ex);
        setIsLoading(false);
      }
    );
  }, [queueMessages]);
  //console.log(queueMessages);
  if (isLoading || !user?.id) return <LoadingBlock />;

  return (
    <>
      <Box>
        <Stack direction={"row"}>
          <TotalOrdersCard orders={orders} />
          <TotalUnbilled orders={orders} />
          <PercentageNeedingAttention props={orders} />
          <AverageUnbilledAge orders={orders} />
        </Stack>
      </Box>
      <Stack direction={"row"} spacing={2}>
        <ShipmentChartDonut
          ship={shipments}
          onClick={() => {
            navigate("/Shipments");
          }}
        />
        <DashboardLineChart
        //userId={user.id}
        // onClick={() => {
        //   navigate("/Orders");
        // }}
        />
      </Stack>
      {permissions?.hierarchy ? (
        <Stack paddingTop={2}>
          <UsersTable />
        </Stack>
      ) : null}

      <Typography sx={{ padding: 2 }}>
        <Copyright />
      </Typography>
    </>
  );
};
export default Dashboard;
