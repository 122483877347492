import { baseApi } from "./baseService";

const API = {
  getOrders: "/api/order/user",
  getOrdersDashboard: "/api/order/dashboard",
  getOrdersAccountScreen: "/api/order/accountScreen",
  getLineChartOrders: "/api/order/lineChart",
  getUnbilledCard: "/api/order/unbilledCard",
  getOrdersApprovalsPage: "/api/order/approvalsPage",
  getOrdersApprovalsNotification: "/api/order/approvalsNotification",
  updateOrder: "api/order/update",
  getOrderIds: "api/order/idList",
  approveAllOrders: "api/order/approveAll"
};

export const getOrders = (
  userId: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getOrders, { userId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getOrderIds = (
  parentAccount: any,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getOrderIds, { parentAccount })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getOrdersApprovalsPage = (
  userId: string | Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getOrdersApprovalsPage, { userId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};
export const getOrdersApprovalsNotification = (
  userId: string | Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getOrdersApprovalsNotification, { userId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getOrdersDashboard = (
  userId: string | Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getOrdersDashboard, { userId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getOrdersAccountScreen = (
  userId: string,
  accountId: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getOrdersAccountScreen, { userId, accountId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getLineChartOrders = (
  userId: string | Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getLineChartOrders, { userId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getUnbilledCard = (
  primaryAccountId: string | undefined,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getUnbilledCard, { primaryAccountId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const updateOrder = (
  id: string | undefined,
  onSuccess: Function,
  orderOwnerId?: string,
  shipperId?: string,
  parentAccountId?: string,
  statusId?: number,
  reasonCodeId?: number,
  notes?: any,
  isDeleted?: boolean,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.updateOrder, {
        id,
        orderOwnerId,
        shipperId,
        parentAccountId,
        statusId,
        reasonCodeId,
        notes,
        isDeleted,
      })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};
export const approveAllOrders = (
  ids: string | Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.approveAllOrders, { ids })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};