import { baseApi } from "./baseService";

const API = {
  getAccount: "/api/account/",
  getAccountsByParentAccount: "/api/account/getAccountsByParentAccount",
};

export const getAccount = (
  id: string | Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getAccount, { id })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};

export const getAccountsByParentAccount = (
  parentAccountId: string | Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getAccountsByParentAccount, { parentAccountId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.log(response);
        onError && onError(response);
      })
  );
};
