import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import React from "react";
import { Invoice } from "../interfaces/Invoice";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LoadingBlock from "./LoadingBlock";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
} from "@mui/x-data-grid-pro";
import { getAccountTerms } from "../services/termsService";

interface Props {
  invoices: Array<Invoice> | null;
}

export const AccountTerms = ({ accountId }: any) => {
  const [terms, setTerms] = React.useState<any>([]);
  //console.log(terms);
  React.useEffect(() => {
    getAccountTerms(
      accountId,
      null,
      (data: any) => {
        //console.log(data);
        setTerms(data);
      },
      (err: any) => {
        console.log(err);
      }
    );
  }, []);

  const columns: GridColDef[] = [
    { field: "termType", headerName: "Type", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "value", headerName: "Value", flex: 1 },
    // {
    //   field: 'statusId', headerName: 'Status', flex: 1,
    //   renderCell: ({ row }) => (<SeverityPill color={getSeverityColor(row.name)}>{row.name}</SeverityPill>)
    // },
    { field: "description", headerName: "Description", flex: 1 },
  ];

  interface TermRows {
    id: string;
    accountId: string;
    termTypeId: string;
    termType: string;
    name: string;
    value: string;
    description: string;
  }

  const generateRows = (terms: Array<any>) => {
    var rows: Array<TermRows> = [];
    terms.map((t: any) => {
      const row: TermRows = {
        id: t.id,
        accountId: t.accountId,
        termTypeId: t.termTypeId,
        termType: t.modelType.name,
        name: t.name,
        value: t.value ? `${t.value} (days)` : "",
        description: t.description,
      };
      rows.push(row);
      return t;
    });
    return rows;
  };

  return (
    <Paper>
      <Typography>Current Terms</Typography>
      <DataGridPro
        density="compact"
        autoHeight
        columns={columns}
        rows={generateRows(terms)}
      />
    </Paper>
  );
};
