import { baseApi } from "./baseService";

const API = {
  textDriver: "/api/text/driver",
  textDriverCustom: "/api/text/driver/custom",
};

export const textDriver = (
  driverName: string,
  driverPhone: string,
  orderId: string,
  shipmentAddress: string,
  accountName: string,
  fromNumber: string,
  reason: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.textDriver, {
        driverName,
        driverPhone,
        orderId,
        shipmentAddress,
        accountName,
        fromNumber,
        reason,
      })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        // console.log(response);
        onError && onError(response);
      })
  );
};

export const textDriverCustom = (
  driverPhone: string,
  fromNumber: string,
  customText: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.textDriverCustom, {
        driverPhone,
        fromNumber,
        customText,
      })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        // console.log(response);
        onError && onError(response);
      })
  );
};
