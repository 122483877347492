import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Container, Box, CircularProgress } from '@mui/material';

const theme = createTheme();

export default function SplashScreen() {

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            marginTop: 8,
            flexDirection: 'column',
            alignItems:'center',
            justifyContent:'center'
          }}
        >
          <CircularProgress sx={{ mt: 3, mb: 2 }} />
        </Box>
      </Container>
    </ThemeProvider>
  );
}