import { baseApi } from "./baseService";

const API = {
  getSettings: "/api/admin/adminSettings",
  adminAddUser: "/api/user/adminAddUser",
  addSettings: "/api/admin/addSetting",
  updateSettings: "/api/admin/updateSetting",
  getAvailableUserSettings: "/api/admin/getAvailable",
  getAvailablePages: "/api/admin/availablePages",
  addUserPage: "/api/admin/addUserPage",
};

export const getSettings = (
  accountId: string | undefined,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getSettings, { accountId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        // console.log(response);
        onError && onError(response);
      })
  );
};

export const adminAddUser = (
  email: string,
  role: string,
  manager: string | null,
  firstName: string,
  lastName: string,
  accountId: string,
  pages: Array<any>,
  reports: Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.adminAddUser, {
        email,
        role,
        manager,
        firstName,
        lastName,
        accountId,
        pages,
        reports,
      })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        //console.log(response);
        onError && onError(response);
      })
  );
};

export const addSettings = (
  accountId: string,
  rule: string,
  value: string,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.addSettings, { accountId, rule, value })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        //console.log(response);
        onError && onError(response);
      })
  );
};

export const updateSetting = (
  settingId: string | undefined,
  value: string | undefined,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.updateSettings, { settingId, value })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        //console.log(response);
        onError && onError(response);
      })
  );
};

export const getAvailableUserSettings = (
  accountId: string | undefined,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getAvailableUserSettings, { accountId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        // console.log(response);
        onError && onError(response);
      })
  );
};
export const getAvailablePages = (
  userId: string | undefined,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.getAvailablePages, { userId })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        // console.log(response);
        onError && onError(response);
      })
  );
};

export const addUserPage = (
  userId: string | undefined,
  page: string | Array<any>,
  onSuccess: Function,
  onError?: Function
) => {
  baseApi().then((api) =>
    api
      .post(API.addUserPage, { userId, page })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(({ response }) => {
        // console.log(response);
        onError && onError(response);
      })
  );
};
